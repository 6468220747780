import { SIGN_IN_USER, SIGN_IN_USER_EXITO, SIGN_IN_USER_ERROR, LIMPIAR_ESTADOS_SIGN_IN } from "../../types";
import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";
const cookie = new universlaCookie();

export function signUpUser(emailEncriptado, usernameEncriptado, rolEncriptado) {
	const nuevoUsuario = {
		email: emailEncriptado,
		username: usernameEncriptado,
		role: rolEncriptado,
	};

	return async (dispatch) => {
		dispatch(signUp());
		try {
			const respuesta = await clienteAxios({
				method: "POST",
				url: "user",
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: nuevoUsuario,
			});
			dispatch(signUpExito(respuesta.data));
		} catch (error) {
			dispatch(signUpError(error));
		}
	};
}

const signUp = () => ({
	type: SIGN_IN_USER,
});
const signUpExito = (respuesta) => ({
	type: SIGN_IN_USER_EXITO,
	payload: respuesta,
});
const signUpError = (respuesta) => ({
	type: SIGN_IN_USER_ERROR,
	payload: respuesta,
});

export function limpiarEstadosSignUpUser() {
	return async (dispatch) => {
		dispatch(limpiarSignUp());
	};
}
const limpiarSignUp = () => ({
	type: LIMPIAR_ESTADOS_SIGN_IN,
});
