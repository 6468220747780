import {
	LOG_IN_USER_EXITO,
	LOG_IN_USER_ERROR,
	LOG_IN_USER,
	LOG_OUT_USER_EXITO,
	LOG_OUT_USER_ERROR,
	LOG_OUT_USER,
	RECUPERAR_CONTRASEÑIA_USER,
	RECUPERAR_CONTRASEÑIA_USER_EXITO,
	RECUPERAR_CONTRASEÑIA_USER_ERROR,
	MODIFICAR_CREAR_CLAVE_CUENTA,
	MODIFICAR_CREAR_CLAVE_CUENTA_EXITO,
	MODIFICAR_CREAR_CLAVE_CUENTA_ERROR,
	GET_DATOS_USER,
	GET_DATOS_USER_EXITO,
	GET_DATOS_USER_ERROR,
	ELIMINAR_MENSAJE_OLVIDAR_CONTRASENIA,
	CONTRASENIA_PROVISORIA,
	BORRAR_CONTRASENIA_PROVISORIA,
	CERRAR_MODAL_CONTRASENIA,
	ABRIR_MODAL_CONTRASENIA,
	RESETEAR_ESTADO_GLOBAL_ERROR,
	RESETEAR_CLAVE_PROVISORIA,
} from "../../types";

const initialState = {
	login: null,
	token: null,
	msj: null,
	user: {},
	email: null,
	claveProvisoria: false,
	modalCambiarContrasenia: false,
	loading: false,
	error: false,
};
export default function reducerLogIn(state = initialState, action) {
	switch (action.type) {
		case GET_DATOS_USER:
		case LOG_IN_USER:
		case LOG_OUT_USER:
		case RECUPERAR_CONTRASEÑIA_USER:
		case ELIMINAR_MENSAJE_OLVIDAR_CONTRASENIA:
		case MODIFICAR_CREAR_CLAVE_CUENTA:
			return {
				...state,
				loading: true,
				error: false,
				msj: "",
			};
		case ABRIR_MODAL_CONTRASENIA:
			return {
				...state,
				modalCambiarContrasenia: true,
			};
		case CERRAR_MODAL_CONTRASENIA:
			return {
				...state,
				modalCambiarContrasenia: false,
			};
		case RECUPERAR_CONTRASEÑIA_USER_ERROR:
			return {
				...state,
				loading: false,
				error: true,
				msj: "E-mail incorrecto",
			};
		case CONTRASENIA_PROVISORIA:
			return {
				...state,
				claveProvisoria: true,
				loading: false,
				error: false,
			};
		case BORRAR_CONTRASENIA_PROVISORIA:
			return {
				...state,
				claveProvisoria: false,
				loading: false,
				error: false,
			};
		case GET_DATOS_USER_ERROR:
		case MODIFICAR_CREAR_CLAVE_CUENTA_ERROR:
		case LOG_IN_USER_ERROR:
		case LOG_OUT_USER_ERROR:
			return {
				...state,
				loading: false,
				token: null,
				error: true,
			};
		case LOG_IN_USER_EXITO:
			return {
				...state,
				user: action.payload.user,
				token: action.payload.token,
				login: action.payload.login,
				msj: action.payload.msg,
				loading: false,
				error: false,
			};
		case LOG_OUT_USER_EXITO:
			return {
				...state,
				msj: action.payload.msj,
				login: false,
				error: false,
			};
		case RECUPERAR_CONTRASEÑIA_USER_EXITO:
			return {
				...state,
				msj: action.payload.message,
				email: action.payload.email,
				loading: false,
				error: false,
			};
		case MODIFICAR_CREAR_CLAVE_CUENTA_EXITO:
			return {
				...state,
				token: action.payload.token,
				msj: action.payload.msj,
				loading: false,
				error: false,
			};
		case GET_DATOS_USER_EXITO:
			return {
				...state,
				user: action.payload.user,
				msj: action.payload.msj,
				loading: false,
				error: false,
			};
		case RESETEAR_ESTADO_GLOBAL_ERROR:
			return {
				...state,
				error: false,
				loading: false,
			};
		case RESETEAR_CLAVE_PROVISORIA:
			return {
				...state,
				claveProvisoria: false,
			};
		default:
			return state;
	}
}
