import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { deleteDashboard } from "../../redux/actions/dashboard/dashboardAction";

import options from "../../assets/svg/functionalities-icons/tres-puntos-porcentaje-lista.svg";

export default function CardDashboard({ dashboard, handlerSelect, children, setDashboardToEdit }) {
	const dispatch = useDispatch();

	const [openDashboard, setOpenDashboard] = useState("");

	const title = dashboard.data.data === "price" ? "PRECIO" : "STOCK";

	const handleDeleteDashboard = (dashboardId) => {
		dispatch(deleteDashboard(dashboardId));
	};

	const handleEditDashboard = (dashboard) => {
		setDashboardToEdit(dashboard);
	};

	const handleOpenOptions = (dashboard) => {
		setOpenDashboard(dashboard._id);
	};

	const handleLeaveOptions = (className) => {
		if (className !== "cardDashboard-options-button") setOpenDashboard("");
	};

	return (
		<div className="home-contenedor-card" onClick={(e) => handleLeaveOptions(e.target.className)}>
			<div className="home-row">
				<section>
					<h3>
						{title} - {dashboard.product.name.toUpperCase()}
					</h3>
					{dashboard.searchText && <h3>| Buscando: {dashboard.searchText.toUpperCase()}</h3>}
				</section>

				<img
					type="button"
					src={options}
					alt="options"
					onClick={() => handleOpenOptions(dashboard)}
					className="cardDashboard-options-button"
				/>
				{dashboard._id === openDashboard && (
					<div className="cardDashboard-options">
						<button className="cuentas-comercial-button_edit" onClick={() => handleEditDashboard(dashboard)}>
							Editar
						</button>
						<button className="cardDashboard-options-expand" onClick={() => handlerSelect(children)}>
							Expandir
						</button>
						<button className="cuentas-comercial-button_delete" onClick={() => handleDeleteDashboard(dashboard._id)}>
							Eliminar
						</button>
					</div>
				)}
			</div>
			<hr />
			<div className="charts-div">{children}</div>
		</div>
	);
}
