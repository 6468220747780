import {
	ACTION_PRODUCTS_ERROR,
	ACTION_PRODUCTS_START,
	GET_PRODUCTS_SUCCESS,
	GET_FILTERS_SUCCESS,
	TOGGLE_CURRENCY,
} from "../../types/index";

import clienteAxios from "../../../Axios";

export function getProducts(indexID, { isSupplier, inStock, page, minPrice, maxPrice, textSearch, filterSearch, isUpdated, hasPrice }) {
	return async (dispatch) => {
		dispatch(actionProductsStart());
		try {
			const invalidCharacters = new RegExp("[{}()\\[\\]\<\>]");
			if (!invalidCharacters.test(textSearch)) {
			const response = await clienteAxios.get(`products?indexID=${indexID}&textSearch=${textSearch}&filterSearch=${filterSearch.join(" ")}&isSupplier=${isSupplier}&inStock=${inStock}&page=${page}&minPrice=${minPrice}&maxPrice=${maxPrice}&isUpdated=${isUpdated}&hasPrice=${hasPrice}`);
			dispatch(getProductsSuccess({ resp: response.data.response, isSupplier, inStock, minPrice, maxPrice, textSearch, filterSearch, isUpdated, hasPrice }));
			} else {
				throw new Error('Busqueda con caracteres inválidos');
			}
		} catch (e) {
			dispatch(actionProductsError());
		}
	};
}

export function toggleCurrency(newCurrency) {
	return async (dispatch) => {
		dispatch(actionToggleCurrency(newCurrency));
	};
}

export function getProductsFilters(index) {
	return async (dispatch) => {
		dispatch(actionProductsStart());
		try {
			const respuesta = await clienteAxios.get(`filter?productType=${index}`);
			dispatch(getFiltersSuccess(respuesta.data.response)); // TODO -< check error code para ver si exito o succes, no???
		} catch (e) {
			dispatch(actionProductsError());
		}
	};
}

const actionProductsError = () => ({
	type: ACTION_PRODUCTS_ERROR,
});

const actionProductsStart = () => ({
	type: ACTION_PRODUCTS_START,
});

const getProductsSuccess = (payload) => ({
	type: GET_PRODUCTS_SUCCESS,
	payload: payload,
});

const getFiltersSuccess = (payload) => ({
	type: GET_FILTERS_SUCCESS,
	payload: payload,
});


const actionToggleCurrency = (payload) => ({
	type: TOGGLE_CURRENCY,
	payload: payload
});
