import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { modificarMiCuenta, resetearEstadosMiCuenta } from "../../redux/actions/cuentas/miCuentaUserAction";
import { getDatosUsers } from "../../redux/actions/registrosUsuarios/logInUserAction";

import MuiAlert from "@mui/material/Alert";

import iconoEditar from "../../assets/svg/functionalities-icons/lapiz-editar.svg";
import iconArrow_gray from "../../assets/svg/functionalities-icons/arrow_gray.svg";

import ModalCambioPass from "./modales/ModalCambioPass";

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const InformacionCuenta = ({ setOpen, setMensaje, setTipoMensaje }) => {
	const dispatch = useDispatch();

	const cuenta = useSelector((state) => state.miCuenta);
	const user = useSelector((state) => state.users);

	const [closeSection, setCloseSection] = useState(false)

	const [mostrarModalCambioContrasenia, setMostrarModalCambioContrasenia] = useState(false);
	const [modificarDatosUsername, setModificarDatosUsername] = useState(false);
	const [newUsername, setNewUsername] = useState(user.user.username ? user.user.username : "");
	const [mostrarAlerta, setMostrarAlerta] = useState(false);

	useEffect(() => {
		if (user.user) {
			localStorage.setItem("user", user.user.username);
			setNewUsername(user.user.username);
		}

		if (cuenta.msj && cuenta.msj === "Usuario existente") {
			setMostrarAlerta(true);
			setNewUsername(user.user.username);
			setTimeout(() => {
				setMostrarAlerta(false);
			}, 2500);
		}
	}, [user.user]);

	useEffect(() => {
		if (cuenta.msj === "Cuenta modificada") {
			setMensaje("Cuenta modificada");
			setTipoMensaje("success");
			setOpen(true);
			setTimeout(() => {
				dispatch(getDatosUsers());
				dispatch(resetearEstadosMiCuenta());
			}, 1000);
		} else if (cuenta.msj === "Usuario existente") {
			setOpen(true);
			setMensaje("Usuario existente");
			setTipoMensaje("error");
			setTimeout(() => {
				dispatch(getDatosUsers());
				dispatch(resetearEstadosMiCuenta());
			}, 500);
		} else if (cuenta.msj === "username requerido") {
			setOpen(true);
			setMensaje("Username y nombre son requerido");
			setTipoMensaje("error");
			setTimeout(() => {
				dispatch(getDatosUsers());
				dispatch(resetearEstadosMiCuenta());
			}, 500);
		} else if (cuenta.msj === "Actualizacion correcta") {
			setOpen(true);
			setMensaje(cuenta.msj);
			setTipoMensaje("success");
			setTimeout(() => {
				dispatch(getDatosUsers());
			}, 500);
		}
		setModificarDatosUsername(false);
	}, [cuenta, cuenta.msj]);

	const handleSubmitSignUp = (e) => {
		e.preventDefault();
		dispatch(modificarMiCuenta(user.user.id, user.user.username, newUsername.trim()));
		dispatch(getDatosUsers());
	};

	function cambiarModificarDatosUsername() {
		setModificarDatosUsername(!modificarDatosUsername);
	}

	const handleUsernameChange = (e) => {
		if (e.nativeEvent.data === " ") {
			if (newUsername !== "" && newUsername[newUsername.length - 1] !== " ") {
				setNewUsername(e.target.value);
			}
		} else {
			setNewUsername(e.target.value);
		}
	};

	function restartEditInputs(className) {
		if (!className.includes("click-valido")) {
			setNewUsername(user.user.username);
			setModificarDatosUsername(false);
		}
	}

	return (
		<div
			className="account-container-info"
			onClick={(e) => {
				restartEditInputs(e.target.className);
			}}
		>
			<div className="cuentas-info_cuentas-row" onClick={() => { setCloseSection(!closeSection) }} style={{ width: "auto" }}>
				<h2>Información de cuenta</h2>
				<img alt="arrow-icon" src={iconArrow_gray} style={!closeSection ? { rotate: "180deg" } : {}} />
			</div>
			<hr className="cuentas-info_cuentas-hr" />
			<div className={closeSection ? "d-none" : undefined}>
				<div>
					<div>
						<h4>Rol:</h4>
					</div>
					<div>
						<h5>{user.user.role && user.user.role.value}</h5>
					</div>
				</div>
				<div>
					<div>
						<h4>Usuario:</h4>
					</div>
					<div>
						{!modificarDatosUsername ? (
							<div className="cuentas-info_cuentas-input_row">
								<h5>{newUsername}</h5>
								<img className="click-valido" onClick={cambiarModificarDatosUsername} src={iconoEditar} type="button" alt="edit" />
							</div>
						) : (
							<input className="click-valido" value={newUsername} onChange={(e) => handleUsernameChange(e)} />
						)}
					</div>
				</div>
				<div>
					<div>
						<h4>Correo electrónico:</h4>
					</div>
					<div>
						<h5>{user.user.email}</h5>
					</div>
				</div>
				<div>
					<div>
						<button onClick={() => setMostrarModalCambioContrasenia(true)} className="cuentas-info_cuentas-btn_contras">
							Cambiar tu contraseña
						</button>
					</div>
					<div>
						<button
							className="cuentas-info_cuentas-btn_editar click-valido"
							disabled={!modificarDatosUsername || !(newUsername !== "" && newUsername !== user.user.username)}
							onClick={(e) => handleSubmitSignUp(e)}
						>
							Aceptar cambios
						</button>
						{mostrarModalCambioContrasenia && (
							<ModalCambioPass
								setMostrarModalCambioContrasenia={setMostrarModalCambioContrasenia}
								setOpen={setOpen}
								setMensaje={setMensaje}
							/>
						)}
					</div>
				</div>
			</div>
			{mostrarAlerta && cuenta.msj && cuenta.msj === "Usuario existente" && <Alert severity="error">{cuenta.msj}</Alert>}
		</div>
	);
};

export default InformacionCuenta;
