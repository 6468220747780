import iconAirplanePhone from "../../assets/svg/categories-icons/airplane-blue.svg"
import iconAirplaneDesktop from "../../assets/svg/categories-icons/airplane-white.svg"

import iconBasketPhone from "../../assets/svg/categories-icons/basket-blue.svg"
import iconBasketDesktop from "../../assets/svg/categories-icons/basket-white.svg"

import iconBateryPhone from "../../assets/svg/categories-icons/batery-blue.svg"
import iconBateryDesktop from "../../assets/svg/categories-icons/batery-white.svg"

import iconBedPhone from "../../assets/svg/categories-icons/bed-blue.svg"
import iconBedDesktop from "../../assets/svg/categories-icons/bed-white.svg"

import iconBooksPhone from "../../assets/svg/categories-icons/books-blue.svg"
import iconBooksDesktop from "../../assets/svg/categories-icons/books-white.svg"

import iconBottlePhone from "../../assets/svg/categories-icons/bottle-blue.svg"
import iconBottleDesktop from "../../assets/svg/categories-icons/bottle-white.svg"

import iconCarPhone from "../../assets/svg/categories-icons/car-blue.svg"
import iconCarDesktop from "../../assets/svg/categories-icons/car-white.svg"

import iconConferencePhone from "../../assets/svg/categories-icons/conference-blue.svg"
import iconConferenceDesktop from "../../assets/svg/categories-icons/conference-white.svg"

import iconDogFootprintPhone from "../../assets/svg/categories-icons/dog-footprint-blue.svg"
import iconDogFootprintDesktop from "../../assets/svg/categories-icons/dog-footprint-white.svg"

import iconDrinkPhone from "../../assets/svg/categories-icons/drink-blue.svg"
import iconDrinkDesktop from "../../assets/svg/categories-icons/drink-white.svg"

import iconHoneycombPhone from "../../assets/svg/categories-icons/honeycomb-blue.svg"
import iconHoneycombDesktop from "../../assets/svg/categories-icons/honeycomb-white.svg"

import iconMakeupPhone from "../../assets/svg/categories-icons/makeup-blue.svg"
import iconMakeupDesktop from "../../assets/svg/categories-icons/makeup-white.svg"

import iconMonitorPhone from "../../assets/svg/categories-icons/monitor-blue.svg"
import iconMonitorDesktop from "../../assets/svg/categories-icons/monitor-white.svg"

import iconNotebookPhone from "../../assets/svg/categories-icons/notebook-blue.svg"
import iconNotebookDesktop from "../../assets/svg/categories-icons/notebook-white.svg"

import iconRamPhone from "../../assets/svg/categories-icons/ram-blue.svg"
import iconRamDesktop from "../../assets/svg/categories-icons/ram-white.svg"

import iconRefrigeratorPhone from "../../assets/svg/categories-icons/refrigerator-blue.svg"
import iconRefrigeratorDesktop from "../../assets/svg/categories-icons/refrigerator-white.svg"

import iconRingBellPhone from "../../assets/svg/categories-icons/ring-bell-blue.svg"
import iconRingBellDesktop from "../../assets/svg/categories-icons/ring-bell-white.svg"

import iconServerPhone from "../../assets/svg/categories-icons/server-blue.svg"
import iconServerDesktop from "../../assets/svg/categories-icons/server-white.svg"

import iconToyPhone from "../../assets/svg/categories-icons/toy-blue.svg"
import iconToyDesktop from "../../assets/svg/categories-icons/toy-white.svg"

import iconTshirtPhone from "../../assets/svg/categories-icons/tshirt-blue.svg"
import iconTshirtDesktop from "../../assets/svg/categories-icons/tshirt-white.svg"

export const navbarIcons = [
	{ phone: iconAirplanePhone, desktop: iconAirplaneDesktop },
	{ phone: iconBasketPhone, desktop: iconBasketDesktop },
	{ phone: iconBateryPhone, desktop: iconBateryDesktop },
	{ phone: iconBedPhone, desktop: iconBedDesktop },
	{ phone: iconBooksPhone, desktop: iconBooksDesktop },
	{ phone: iconBottlePhone, desktop: iconBottleDesktop },
	{ phone: iconCarPhone, desktop: iconCarDesktop },
	{ phone: iconConferencePhone, desktop: iconConferenceDesktop },
	{ phone: iconDogFootprintPhone, desktop: iconDogFootprintDesktop },
	{ phone: iconDrinkPhone, desktop: iconDrinkDesktop },
	{ phone: iconHoneycombPhone, desktop: iconHoneycombDesktop },
	{ phone: iconMakeupPhone, desktop: iconMakeupDesktop },
	{ phone: iconMonitorPhone, desktop: iconMonitorDesktop },
	{ phone: iconNotebookPhone, desktop: iconNotebookDesktop },
	{ phone: iconRamPhone, desktop: iconRamDesktop },
	{ phone: iconRefrigeratorPhone, desktop: iconRefrigeratorDesktop },
	{ phone: iconRingBellPhone, desktop: iconRingBellDesktop },
	{ phone: iconServerPhone, desktop: iconServerDesktop },
	{ phone: iconToyPhone, desktop: iconToyDesktop },
	{ phone: iconTshirtPhone, desktop: iconTshirtDesktop },
]

export const modalBoxStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "312px",
	height: "auto",
	bgcolor: "#ffffff",
	borderRadius: "10px",
	boxShadow: 24,
	disableAutoFocus: "true",
	outline: "none",
	maxHeight: "90vh",
	overflowX: "hidden",
};
