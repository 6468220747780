export const LOG_IN_USER = "LOG_IN_USER";
export const LOG_IN_USER_EXITO = "LOG_IN_USER_EXITO";
export const LOG_IN_USER_ERROR = "LOG_IN_USER_ERROR";

export const LOG_OUT_USER = "LOG_OUT_USER";
export const LOG_OUT_USER_EXITO = "LOG_OUT_USER_EXITO";
export const LOG_OUT_USER_ERROR = "LOG_OUT_USER_ERROR";

export const ELIMINAR_MENSAJE_OLVIDAR_CONTRASENIA = "ELIMINAR_MENSAJE_OLVIDAR_CONTRASENIA";

export const CONTRASENIA_PROVISORIA = "CONTRASENIA_PROVISORIA";
export const BORRAR_CONTRASENIA_PROVISORIA = "BORRAR_CONTRASENIA_PROVISORIA";

export const ABRIR_MODAL_CONTRASENIA = "ABRIR_MODAL_CONTRASENIA";
export const CERRAR_MODAL_CONTRASENIA = "CERRAR_MODAL_CONTRASENIA";

export const RESETEAR_ESTADO_GLOBAL_ERROR = "RESETEAR_ESTADO_GLOBAL_ERROR";

export const RESETEAR_CLAVE_PROVISORIA = "RESETEAR_CLAVE_PROVISORIA";
