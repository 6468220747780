import React from "react";
import { NativeSelect } from '@mantine/core';

const SelectPlantilla = ({ etiqueta, headers, label, handleSelectInput }) => {
	return (
		<section onChange={(e) => handleSelectInput(e, etiqueta)}>
			<label htmlFor={`${etiqueta}`}>{label}:</label>
			<NativeSelect size="xs" id={`${etiqueta}`}>
				<option hidden>Seleccionar</option>
				{headers.map((header) => {
					return (
						<option value={header} key={header}>
							{header}
						</option>
					);
				})}
				<option className="d-none">Dejar campo vacio</option>
			</NativeSelect >
		</section>
	);
};

export default SelectPlantilla;
