import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { eliminarCuenta, obtenerCuentas, resetearEstados } from "../../redux/actions/cuentas/cuentasUsersAction";

import ModalModificarCuenta from "./modales/ModalModificarCuenta";
import ConfirmationModal from "../utilities/Modal/ConfirmationModal";

import iconoBasura from "../../assets/svg/functionalities-icons/trash-can.svg";
import iconoEditar from "../../assets/svg/functionalities-icons/lapiz-editar.svg";

const CardCuentas = ({ permisoAdministrador, cuenta }) => {
	const dispatch = useDispatch();

	const access = useSelector((state) => state.cuentas.pass);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);

	useEffect(() => {
		if (access === false) {
			setShowEditModal(false);
			setShowDeleteModal(false);
		}
	}, [access]);

	const abrirModalModificarCuenta = () => {
		dispatch(resetearEstados());
		setShowEditModal(true);
	};

	const handleDeleteUserAccount = () => {
		setShowDeleteModal(false);
		dispatch(eliminarCuenta(cuenta._id));
		dispatch(obtenerCuentas());
	}

	return (
		<>
			<div className="cuentas-card_cuentas-cont-div">
				<section>
					<h4>{cuenta.username}</h4>
					<div style={{ display: "flex", gap: "10px" }}>
						{permisoAdministrador && <img onClick={() => setShowDeleteModal(true)} src={iconoBasura} type="button" alt="delete" />}
						{permisoAdministrador && <img onClick={abrirModalModificarCuenta} src={iconoEditar} type="button" alt="edit" />}
					</div>
				</section>
				<hr />
				<h4>{cuenta.email}</h4>
			</div>
			{showDeleteModal && (
				<ConfirmationModal
					descriptionText="¿Estas seguro que quieres eliminar esta cuenta?"
					handlerShowConfirmationModal={handleDeleteUserAccount}
					setShowConfirmationModal={setShowDeleteModal}
					titleText="Eliminar cuenta"
				/>
			)}
			{showEditModal && (
				<ModalModificarCuenta
					setMostrarModalModificar={setShowEditModal}
					mostrarModalModificar={showEditModal}
					cuenta={cuenta}
				/>
			)}
		</>
	);
};

export default CardCuentas;
