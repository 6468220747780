import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EliminarMensajeOlvidarContrasenia, recuperarContrasenia } from "../../redux/actions/registrosUsuarios/logInUserAction";

import cryptoJs from "crypto-js";

import { modalBoxStyle } from "../utilities/utilities";
import config from "../../config/config";

import { Modal, Box } from "@mui/material";

import recoverImg from "../../assets/svg/system-images/icono_oldvidar_contrasena.svg";
import closeModalIcon from "../../assets/svg/functionalities-icons/cross_gray.svg";

import "./login.css";

export default function ModalRecoverPassword({ setShowModalRecoverPassword, handleClick }) {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.users);

	const [email, setEmail] = useState();
	const [failure, setFailure] = useState("");

	useEffect(() => {
		setFailure(false);
	}, [email]);

	useEffect(() => {
		if (user.msj && user.msj === "E-mail incorrecto") {
			setFailure(true);
		}
		if (user.msj && user.msj === "Contraseña provisoria enviada a su email") {
			setShowModalRecoverPassword(false);
			setFailure(false);
			handleClick(user.msj);
			dispatch(EliminarMensajeOlvidarContrasenia());
		}
	}, [user]);

	const handleSubmitRecuperar = (e) => {
		e.preventDefault();
		dispatch(recuperarContrasenia(encriptar(email)));
	};

	function encriptar(string) {
		const secretKey = config.secretKey;
		return cryptoJs.AES.encrypt(string, secretKey).toString();
	}

	return (
		<Modal
			open={true}
			onClose={(e) => {
				e.preventDefault();
				setShowModalRecoverPassword(false);
			}}
			style={{ backdropFilter: "blur(4px)" }}
		>
			<Box sx={modalBoxStyle}>
				<div className="login-modal-container">
					<img
						src={closeModalIcon}
						className="modales-cerrar_modal-img"
						alt="close"
						onClick={() => {
							setShowModalRecoverPassword(false);
						}}
					/>
					<img src={recoverImg} alt="recover" />
					<div>
						<p>Cambiar Contraseña</p>
						<p>Introduce tu correo electrónico y te enviaremos un enlace para verificar tu contraseña</p>
					</div>
					{failure && (
						<h4 className="login-recoverPassword-h4">
							{user.msj && user.msj.length > 0 && user.msj}
						</h4>
					)}
					<form onSubmit={handleSubmitRecuperar}>
						<input
							autoComplete="off"
							name="email"
							onChange={(event) => {
								if (event.nativeEvent.data !== " ") setEmail(event.target.value);
							}}
							placeholder="Correo electrónico"
							required
							type="email"
						/>
						<button>Enviar correo de verificación</button>
					</form>
				</div>
			</Box>
		</Modal >
	);
};
