import { START_GET_QUOTATION, GET_QUOTATION_SUCCESS, GET_QUOTATION_ERROR } from "../../types/index";

const initialState = {
	quotationItems: [],
	loading: null,
	error: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case START_GET_QUOTATION:
			return {
				...state,
				loading: true,
			};
		case GET_QUOTATION_ERROR:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_QUOTATION_SUCCESS:
			return {
				...state,
				error: null,
				loading: false,
				quotationItems: action.payload,
			};
		default:
			return state;
	}
}
