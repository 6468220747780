import { SIGN_IN_USER, SIGN_IN_USER_EXITO, SIGN_IN_USER_ERROR, LIMPIAR_ESTADOS_SIGN_IN } from "../../types";

const initialState = {
	msj: null,
	loading: false,
	error: false,
};
export default function reducerSignIn(state = initialState, action) {
	switch (action.type) {
		case SIGN_IN_USER:
			return {
				...state,
				loading: true,
				error: false,
			};
		case SIGN_IN_USER_EXITO:
			return {
				...state,
				msj: action.payload.message,
				loading: false,
				error: false,
			};
		case SIGN_IN_USER_ERROR:
			return {
				...state,
				msj: action.payload.response.data.message,
				loading: false,
				error: true,
			};
		case LIMPIAR_ESTADOS_SIGN_IN:
			return {
				...state,
				msj: null,
				loading: false,
				error: false,
			};
		default:
			return state;
	}
}
