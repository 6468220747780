import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDatosUsers } from "../../redux/actions/registrosUsuarios/logInUserAction";
import { resetearEstados } from "../../redux/actions/cuentas/cuentasUsersAction";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import universlaCookie from "universal-cookie";
import InformacionCuenta from "./InformacionCuenta";
import { AdministrarCuentas } from "./AdministrarCuentas";

import "./configuracionCuentas.css";

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MiCuenta = () => {
	const dispatch = new useDispatch();
	const cookie = new universlaCookie();
	const token = cookie.get("token");
	const user = useSelector((state) => state.users);

	useEffect(() => {
		dispatch(getDatosUsers());
	}, []);

	const [open, setOpen] = useState(false);
	const [mensaje, setMensaje] = useState("");
	const [tipoMensaje, setTipoMensaje] = useState("success");

	useEffect(() => {
		dispatch(resetearEstados());
	}, [open]);

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	return (
		<>
			{token && (
				<div className="cuentas-contenedor">
					<InformacionCuenta setOpen={setOpen} setTipoMensaje={setTipoMensaje} setMensaje={setMensaje} />

					{((user.user.role && user.user.role.access === "admin") || (user.user.role && user.user.role.access === "superUser")) && (
						<AdministrarCuentas />
					)}
				</div>
			)}
			<Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={tipoMensaje} sx={{ width: "100%" }}>
					{mensaje}
				</Alert>
			</Snackbar>
		</>
	);
};
export default MiCuenta;
