import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";

// import logoPlusName from "../../assets/svg/trendit-images/logoPlusName.png";
import logoAxonScopeHorizontal from "../../assets/svg/axonscope/axonscope_horizontal.svg"

export default function QuotationDownload({ quotation, dollarPrice, precioVenta, iva10Venta, iva21Venta, impuestoVenta, priceLists, date }) {
	const pricesStyles = {
		display: "flex",
		flexDirection: "row",
		alignContent: "center",
		justifyContent: "space-between",
		padding: "4.5px 0",
		width: "100%",
	};

	const totalStyle = {
		...pricesStyles,
		borderTop: "1px solid #498aff",
		fontWeight: "bold",
	};

	const formatearNumero = (valor) => {
		return new Intl.NumberFormat("de-DE").format((valor * 1).toFixed(2));
	};

	const transformPrice = (value, exchange) => {
		if (exchange === "ARS" && dollarPrice) {
			value = value / dollarPrice;
			value = value.toFixed(2);
		}
		return new Intl.NumberFormat("de-DE").format((value * 1).toFixed(2));
	};

	return (
		<Document>
			<Page size="A4">
				<View style={{ height: "100vh", marginLeft: "2.5%", padding: "10px 0", width: "95%" }}>
					<View style={{ display: "flex", alignContent: "center", flexDirection: "row", justifyContent: "space-between", padding: "14px" }}>
						<Image src={logoAxonScopeHorizontal} style={{ height: "20px", width: "103px" }} alt="image" />
						<View style={{ fontSize: "10px", display: "flex", flexDirection: "row", alignItems: "center", gap: "15px" }}>
							<Text>Dolar oficial: ${dollarPrice}</Text>
							<Text>{date}</Text>
						</View>
					</View>

					<View style={{ fontSize: "9px" }}>
						<View
							style={{
								borderBottom: "1px solid #498aff",
								backgroundColor: "#b6d0ff",
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								height: "26px",
								justifyContent: "center",
							}}
						>
							<Text style={{ width: "5%", paddingLeft: "3px" }}>Cant.</Text>
							<Text style={{ width: "50%" }}>Detalle</Text>
							<Text style={{ width: "13%" }}>Precio sin IVA</Text>
							<Text style={{ width: "8%" }}>IVA</Text>
							<Text style={{ width: "8%" }}>I.I.</Text>
							<Text style={{ width: "16%" }}>SUBTOTAL</Text>
						</View>
						{quotation.map((item, index) => {
							let styles = {
								borderBottom: "1px solid #498aff",
								display: "flex",
								alignItems: "center",
								flexDirection: "row",
								minHeight: "16px",
								gap: "3px",
							};
							if (index % 2 !== 0) {
								styles = {
									...styles,
									backgroundColor: "#e0ebff",
								};
							}

							return (
								<View key={item._id} style={styles}>
									<Text style={{ width: "5%", paddingLeft: "5px" }}>{item.quantity}</Text>
									<Text style={{ width: "50%" }}>{item.product.informacionProducto.titulo.toUpperCase()}</Text>
									<Text style={{ width: "13%" }}>
										USD{" "}
										{transformPrice(
											item.product.precio.precioContado * 1 *
											(priceLists.find((priceList) => priceList._id == item.selectedPriceList)?.percentage / 100 + 1),
											item.product.precio.tipoMoneda
										)}
									</Text>
									<Text style={{ width: "8%" }}>{formatearNumero(item.product.precio.ivaProducto)}%</Text>
									<Text style={{ width: "8%" }}>{formatearNumero(item.product.precio.impInterProducto)}%</Text>
									<Text style={{ width: "16%" }}>
										USD{" "}
										{transformPrice(
											(item.product.precio.precioContado * 1 +
												item.product.precio.precioContado * 0.01 * item.product.precio.ivaProducto +
												item.product.precio.precioContado * 0.01 * item.product.precio.impInterProducto) *
											(priceLists.find((priceList) => priceList._id == item.selectedPriceList)?.percentage / 100 + 1) *
											item.quantity,
											item.product.precio.tipoMoneda
										)}
									</Text>
								</View>
							);
						})}
					</View>
					<View
						style={{
							display: "flex",
							flexDirection: "column",
							fontSize: "10px",
							margin: "10px 0",
							position: "absolute",
							bottom: "0",
							right: "0",
							width: "30%",
						}}
					>
						<View style={pricesStyles}>
							<Text>Subtotal</Text>
							<Text>{formatearNumero(precioVenta)}</Text>
						</View>
						<View style={pricesStyles}>
							<Text>IVA 10.5%</Text>
							<Text>{formatearNumero(iva10Venta)}</Text>
						</View>
						<View style={pricesStyles}>
							<Text>IVA 21%</Text>
							<Text>{formatearNumero(iva21Venta)}</Text>
						</View>
						<View style={pricesStyles}>
							<Text>Impuesto Interno</Text>
							<Text>{formatearNumero(impuestoVenta)}</Text>
						</View>
						<View style={totalStyle}>
							<Text>Total</Text>
							<Text>{formatearNumero(precioVenta * 1 + iva10Venta * 1 + iva21Venta * 1 + impuestoVenta * 1)}</Text>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
}
