import {
	GET_PRICE_LISTS_ERROR,
	GET_PRICE_LISTS_SUCCESS,
	SET_PRICE_LISTS_MESSAGE,
	START_GET_PRICE_LISTS,
} from "../../types/index";

import clienteAxios from "../../../Axios";

export function deletePriceList(id) {
	return async (dispatch) => {
		try {
			const response = await clienteAxios.delete(`priceList/?id=${id}`);
			dispatch(setPriceListsMessage(response.data.message))
		} catch (e) {
			dispatch(getPriceListsError());
		}
	};
}

export function getPriceLists() {
	return async (dispatch) => {
		dispatch(downloadPriceLists());
		try {
			const respuesta = await clienteAxios.get("priceList");
			dispatch(getPriceListsSuccess(respuesta.data.response));
		} catch (e) {
			dispatch(getPriceListsError());
		}
	};
}

export function updatePriceList(name, percentage, id) {
	return async (dispatch) => {
		try {
			const response = await clienteAxios.put(`priceList/?id=${id}&name=${name}&percentage=${percentage}`);
			dispatch(setPriceListsMessage(response.data.message))
		} catch (e) {
			dispatch(getPriceListsError());
		}
	};
}

export function postPriceList(name, percentage) {
	return async (dispatch) => {
		try {
			const respuesta = await clienteAxios.post(`priceList/?name=${name}&percentage=${percentage}`);
			dispatch(setPriceListsMessage(respuesta.data.message));
		} catch (e) {
			dispatch(getPriceListsError());
		}
	};
}

const setPriceListsMessage = (message) => ({
	type: SET_PRICE_LISTS_MESSAGE,
	payload: message
})

const downloadPriceLists = () => ({
	type: START_GET_PRICE_LISTS,
});

const getPriceListsSuccess = (priceLists) => ({
	type: GET_PRICE_LISTS_SUCCESS,
	payload: priceLists,
});

const getPriceListsError = () => ({
	type: GET_PRICE_LISTS_ERROR,
});
