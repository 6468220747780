import React from "react";
import { Modal, Box } from "@mui/material";
import { makeStyles } from "mui-styles";

import "../utilities.css";
import { modalBoxStyle } from "../utilities";

import Loader from "../Loader";

import closeIcon from "../../../assets/svg/functionalities-icons/cross_gray.svg";
import errorIcon from "../../../assets/svg/information-icons/contrasena_inc.svg";
import successIcon from "../../../assets/svg/information-icons/contrasena_correct.svg";

const useStyles = makeStyles({
	style: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "320px",
		height: "196px",
		background: "#fff",
		padding: "10px",
		borderRadius: "10px",
		boxShadow: 24,
		disableAutoFocus: "true",
		outline: "none",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		gap: "16px",
	}
});

const StatusModal = ({ loading, error, setModalStatusCloseAction, children }) => {
	const classes = useStyles();

	const handleClose = () => {
		setModalStatusCloseAction(false);
	};

	return (
		<Modal open={true} style={{ backdropFilter: "blur(4px)" }} onClose={handleClose}>
			<Box className={classes.style}>
				{loading ? (
					<Loader />
				) : (
					<img src={error ? errorIcon : successIcon} className="modal-modificacion-cuenta-proveedor-icono" alt="estado verificacion" />
				)}
				<h4 className="modal-modal_confirmacion_modificacion-mensaje">{children}</h4>
				{!loading && <img src={closeIcon} className="modales-cerrar_modal-img" alt="estado verificacion" onClick={handleClose} />}
			</Box>
		</Modal>
	);
};

export default StatusModal;
