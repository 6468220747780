import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Modal, Box } from "@mui/material";

import { addNavbarCategory, deleteNavbarCategory, updateNavbarCategory, updateNavbarIndex, updateNavbarSubcategory } from "../../../../redux/actions/utilities/navbarAction";

import iconArrow_gray from "../../../../assets/svg/functionalities-icons/arrow-close_blue.svg";
import iconCloseModal from "../../../../assets/svg/functionalities-icons/cross_gray.svg";
import iconTrash from "../../../../assets/svg/functionalities-icons/trash-can.svg";
import iconEdit from "../../../../assets/svg/functionalities-icons/lapiz-editar.svg";
import iconUndo from "../../../../assets/svg/functionalities-icons/volver-porcentaje-lista.svg";
import iconConfirm from "../../../../assets/svg/functionalities-icons/check_white.svg";
import iconAdd from "../../../../assets/svg/functionalities-icons/plus-card_blue.svg";

import { navbarIcons, modalBoxStyle } from "../../../utilities/utilities";

import ConfirmationModal from "../../../utilities/Modal/ConfirmationModal";

const CategoryModal = ({ setShowAddCategoryModal, existingCategory }) => {
	const dispatch = useDispatch();

	const [categoryObject, setCategoryObject] = useState([])

	const [provisoryCategoryName, setProvisoryCategoryName] = useState("")
	const [categoryName, setCategoryName] = useState("")
	const [editCategoryName, setEditCategoryName] = useState(true)
	const [categoryImage, setCategoryImage] = useState(null)

	const [iconListOpen, setIconListOpen] = useState(false)
	const [isSubcategory, setIsSubcategory] = useState(false)

	const [creation, setCreation] = useState("")
	const [isCreation, setIsCreation] = useState(false)
	const [currentIndex, setCurrentIndex] = useState(null)
	const [currentSubcategory, setCurrentSubcategory] = useState({})

	const [indexToEdit, setIndexToEdit] = useState(false)
	const [nameIndexToEdit, setNameIndexToEdit] = useState(false)

	const [showDeleteCategory, setShowDeleteCategory] = useState(false)
	const [showDeleteIndex, setShowDeleteIndex] = useState(false)

	useEffect(() => {
		if (existingCategory) {
			setCategoryName(existingCategory.name)
			setCategoryImage(existingCategory.images)
			setCategoryObject(existingCategory.subcategories)
			setEditCategoryName(false)
		}
	}, [])

	const handleAddCategory = () => {
		dispatch(addNavbarCategory(categoryName.toLowerCase(), categoryImage, categoryObject))
		setShowAddCategoryModal(false)
	};

	const handleAddSubcategory = () => {
		if (!currentSubcategory.name) setCategoryObject([...categoryObject, { name: creation.trim(), indices: [] }])
		else setCurrentSubcategory({ ...currentSubcategory, indices: [...currentSubcategory.indices, creation.trim()] })
		setIsCreation(false)
		setCreation("")
	};

	const handleConfirmOption = (type) => {
		setEditCategoryName(false)
		if (type === "category") setCategoryName(provisoryCategoryName.trim())
		else if (type === "subcategory") setCurrentSubcategory({ ...currentSubcategory, name: provisoryCategoryName.trim() })
	}

	const handleDeleteCategory = () => {
		if (isSubcategory) {
			const newCategoryObject = categoryObject.filter((object, index) => {
				if (index !== currentIndex) return object
			})
			setCategoryObject(newCategoryObject)

			setIsSubcategory(false)
			setShowDeleteCategory(false)
		} else {
			if (existingCategory) {
				dispatch(deleteNavbarCategory(existingCategory._id))
			}
			setShowAddCategoryModal(false)
		}
	};

	const handleDeleteIndex = () => {
		const actualCurrentSubcategory = currentSubcategory.indices.filter((index) => {
			if (index !== showDeleteIndex) return index
		})
		setCurrentSubcategory({ ...currentSubcategory, indices: actualCurrentSubcategory })
		setShowDeleteIndex(false)
	}

	const handleEdit = (type) => {
		if (type === 'category') dispatch(updateNavbarCategory(existingCategory._id, provisoryCategoryName))
		else if (type === 'subcategory') dispatch(updateNavbarSubcategory(currentSubcategory._id, provisoryCategoryName))
		else dispatch(updateNavbarIndex(indexToEdit._id, nameIndexToEdit))
		setShowAddCategoryModal(false)
	}

	const handleIndexToEdit = (index) => {
		setIndexToEdit(index)
		setNameIndexToEdit(index.name)
	}

	const handleInputChange = (e, type) => {
		if (e.nativeEvent.data === " ") {
			if (e.target.value.length > 1 && e.target.value[e.target.value.length - 2] !== " ") {
				if (type === "category") setProvisoryCategoryName(e.target.value);
				else if (type === "subcategory") setCreation(e.target.value)
				else setNameIndexToEdit(e.target.value)
			}
		} else {
			if (type === "category") setProvisoryCategoryName(e.target.value);
			else if (type === "subcategory") setCreation(e.target.value)
			else setNameIndexToEdit(e.target.value)
		}
	};

	const handleSubcategory = (index) => {
		setIsSubcategory(true)
		setProvisoryCategoryName(categoryObject[index].name)
		setCurrentSubcategory(categoryObject[index])
		setCurrentIndex(index)
	}

	const handleUndoOption = (type) => {
		if (type === "category") setProvisoryCategoryName(categoryName)
		else if (type === "subcategory") setProvisoryCategoryName(currentSubcategory.name)
		setEditCategoryName(false)
	}

	const handleBackToCategory = () => {
		setIsSubcategory(false);

		let newCategoryObject = categoryObject
		newCategoryObject[currentIndex] = currentSubcategory
		setCategoryObject(newCategoryObject)
		setCurrentSubcategory({})
	}

	return (
		<Modal
			open={true}
			onClose={() => {
				setShowAddCategoryModal(false);
			}}
			style={{ backdropFilter: "blur(4px)" }}
		>
			<Box sx={modalBoxStyle}>
				<div className="modales-contenedor_texto-div categoryModal-container">
					<h4 className="modales-titulo_modal">{existingCategory ? "Editar" : "Crear"} categoria</h4>
					<img
						src={iconCloseModal}
						className="modales-cerrar_modal-img"
						alt="close"
						onClick={() => {
							setShowAddCategoryModal(false);
						}}
					/>
					{!isSubcategory && <section className="addCategoryModal-container">
						{editCategoryName ?
							<section>
								<input id="category-input" type="text" value={provisoryCategoryName} onChange={(e) => handleInputChange(e, "category")} maxLength="25" />
								{categoryName && <img alt="undo-button" src={iconUndo} onClick={() => handleUndoOption("category")} />}
								<img alt="confirm-button" src={iconConfirm} className="cuentas-comercial-button_confirm" onClick={() => existingCategory ? handleEdit("category") : handleConfirmOption("category")} />
							</section> :
							<section>
								<h4>{categoryName}</h4>
								<img src={iconEdit} type="button" alt="edit-button" onClick={() => { setEditCategoryName(true); setProvisoryCategoryName(categoryName) }} />
								<img src={iconTrash} type="button" alt="delete-button" onClick={() => setShowDeleteCategory(true)} />
							</section>}
						<hr />
					</section>}
					{!isSubcategory ? <div>
						<div onClick={() => setIconListOpen(!iconListOpen)}>
							<h5>Icono</h5>
							{categoryImage && <img src={categoryImage.phone} alt="category-icon" className="categoryModal-selected-icon" />}
							<img src={iconArrow_gray} alt="arrow-icon" style={iconListOpen ? { rotate: '180deg' } : {}} />
						</div>
						{iconListOpen &&
							<section className="categoryModal-icons">
								{navbarIcons.map(icon => {
									return <img src={icon.phone}
										alt="category-icon"
										className={categoryImage && categoryImage.phone == icon.phone ? "categoryModal-selected-gridIcon" : ""}
										key={icon.phone}
										onClick={() => setCategoryImage(icon)}
									/>
								})}
							</section>}
						<hr />
						{categoryObject.map((subcategory, index) => {
							return <>
								<div onClick={() => handleSubcategory(index)} key={index}>
									<h5>{subcategory.name}</h5>
									<img src={iconArrow_gray} alt="arrow-icon" style={{ rotate: "270deg" }} />
								</div>
								<hr key={index + '-' + index} />
							</>
						})}
						{!isCreation ?
							<div onClick={() => { setIsCreation(true); setCreation("") }}>
								<h5>Crear subcategoria</h5>
								<img src={iconAdd} />
							</div> :
							<div>
								<input id="subcategory-input" value={creation} onChange={(e) => handleInputChange(e, "subcategory")} maxLength="25" />
								<img alt="undo-button" src={iconUndo} onClick={() => setIsCreation(false)} />
								<img alt="confirm-button" src={iconConfirm} className="cuentas-comercial-button_confirm"
									disabled={!creation} onClick={handleAddSubcategory}
								/>
							</div>
						}
						{!existingCategory && <button onClick={() => handleAddCategory()} disabled={!categoryName || !categoryImage}>Crear</button>}
					</div> : <div className="categoryModal-subcategory-container">
						<div onClick={handleBackToCategory}>
							<img src={iconArrow_gray} style={{ rotate: "90deg" }} />
							<h3>{categoryName}</h3>
						</div>
						<section className="addCategoryModal-container" style={{ padding: "0 15px" }}>
							{editCategoryName ?
								<section>
									<input id="subcategory-edit-input" type="text" value={provisoryCategoryName} onChange={(e) => handleInputChange(e, "category")} maxLength="25" />
									<img alt="undo-button" src={iconUndo} onClick={() => handleUndoOption("subcategory")} />
									<img alt="confirm-button" src={iconConfirm} className="cuentas-comercial-button_confirm" onClick={() => existingCategory ? handleEdit("subcategory") : handleConfirmOption("subcategory")} />
								</section> :
								<section>
									<h4>{currentSubcategory.name}</h4>
									<img src={iconEdit} type="button" alt="edit-button" onClick={() => setEditCategoryName(true)} />
									<img src={iconTrash} type="button" alt="delete-button" onClick={() => setShowDeleteCategory(true)} />
								</section>}
							<hr />
						</section>
						<section className="categoryModal-subcategory-indices">
							{currentSubcategory.indices.map((index) => {
								return <>
									<div key={index.name}>
										{indexToEdit === index ?
											<>
												<input value={nameIndexToEdit} onChange={(e) => handleInputChange(e)} />
												<section>
													<img src={iconUndo} onClick={() => setIndexToEdit(false)} />
													<img src={iconConfirm} className="cuentas-comercial-button_confirm" onClick={() => handleEdit('index')} />
												</section>
											</>
											: <>
												<h5>{index.name}</h5>
												<section>
													<img src={iconEdit} onClick={() => handleIndexToEdit(index)} />
													<img src={iconCloseModal} onClick={() => setShowDeleteIndex(index.name)} />
												</section>
											</>}
									</div>
									<hr key={index.name + '-' + index.name} />
								</>
							})}
							{!isCreation ?
								<div onClick={() => { setIsCreation(true); setCreation("") }}>
									<h5 >Crear indice</h5>
									<img src={iconAdd} alt="add-icon" />
								</div> :
								<div>
									<input id="index-input" value={creation} onChange={(e) => handleInputChange(e, "subcategory")} maxLength="25" />
									<img alt="undo-button" src={iconUndo} onClick={() => setIsCreation(false)} />
									<img alt="confirm-button" src={iconConfirm} className="cuentas-comercial-button_confirm"
										disabled={!creation} onClick={handleAddSubcategory} />
								</div>
							}
						</section>
					</div>}
				</div>
				{showDeleteCategory &&
					<ConfirmationModal
						setShowConfirmationModal={setShowDeleteCategory}
						handlerShowConfirmationModal={handleDeleteCategory}
						titleText="Eliminar categoría"
						descriptionText={`¿Estas seguro que quieres eliminar la ${!isSubcategory ? "categoria" : "subcategoria"} 
							"${!isSubcategory ? categoryName.trim().toUpperCase() : currentSubcategory.name.trim().toUpperCase()}"?`}
					/>}
				{showDeleteIndex !== false &&
					<ConfirmationModal
						setShowConfirmationModal={setShowDeleteIndex}
						handlerShowConfirmationModal={handleDeleteIndex}
						titleText="Eliminar indice"
						descriptionText={`¿Estas seguro que quieres eliminar el indice "${showDeleteIndex}"?`}
					/>}
			</Box>
		</Modal>
	);
};

export default CategoryModal;
