import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import MuiAlert from "@mui/material/Alert";
import { Modal, Box } from "@mui/material";
import { verificarContrasenias } from "../../../redux/actions/cuentas/cuentasUsersAction";
import { modificarClaveCuenta } from "../../../redux/actions/registrosUsuarios/logInUserAction"

import cryptoJs from "crypto-js";

import iconoVerActivo from "../../../assets/svg/functionalities-icons/ver-activo.svg";
import iconoVerInactivo from "../../../assets/svg/functionalities-icons/ver-inactivo.svg";
import iconoCerrarModal from "../../../assets/svg/functionalities-icons/cross_gray.svg";

import "./modalesCuentas.css";

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "300px",
	height: "auto",
	bgcolor: "#ffffff",
	borderRadius: "10px",
	boxShadow: 24,
	p: "20px",
};

const ModalCambioPass = ({ setMostrarModalCambioContrasenia, setOpen, setMensaje }) => {
	const dispatch = useDispatch();
	const cuentas = useSelector((state) => state.cuentas);
	const errorCambiosDatos = useSelector((state) => state.cuentas.error);
	const user = useSelector((state) => state.users);

	const [contraseniaAnterior, setContraseniaAnterior] = useState("");
	const [contraseniaNueva, setContraseniaNueva] = useState("");
	const [reingresoContrasenia, setReingresoContrasenia] = useState("");
	const [mensajeErrorIngresoContrasenia, setMensajeErrorIngresoContrasenia] = useState("");
	const [respuestaBack, setRespuestaBack] = useState(false);

	const [verIngresoPass1, setVerIngresoPass1] = useState(false);
	const [verIngresoPass2, setVerIngresoPass2] = useState(false);
	const [verIngresoPass3, setVerIngresoPass3] = useState(false);
	const [severityAlert, setSeverityAlert] = useState();

	useEffect(() => {
		setRespuestaBack(false);
		setMensajeErrorIngresoContrasenia("");
	}, [contraseniaNueva, reingresoContrasenia, contraseniaAnterior]);

	useEffect(() => {
		if (
			(mensajeErrorIngresoContrasenia &&
				(mensajeErrorIngresoContrasenia === "La contraseña actual no es válida" ||
					mensajeErrorIngresoContrasenia === "Por favor ingrese contraseña nueva" ||
					mensajeErrorIngresoContrasenia === "La nueva contraseña debe ser distinta a la anterior" ||
					mensajeErrorIngresoContrasenia === "Las contraseñas no coinciden")) ||
			(cuentas.msj && cuentas.msj === "Formato contraseña invalido")
		) {
			setSeverityAlert("error");
		} else if (cuentas.msj === "Cuenta modificada" || cuentas.msj === "Actualizacion correcta") {
			setMensaje("Actualizacion correcta");
			setMostrarModalCambioContrasenia(false);
			setOpen(true);
		}
	}, [cuentas.msj, mensajeErrorIngresoContrasenia]);

	useEffect(() => {
		if (contraseniaAnterior.length > 6) {
			dispatch(verificarContrasenias(contraseniaAnterior));
		}
	}, [contraseniaAnterior]);

	useEffect(() => {
		if (errorCambiosDatos === true) {
			setRespuestaBack(true);
		}
	}, [errorCambiosDatos]);

	const handleSubmitSignUp = (e) => {
		e.preventDefault();
		if (verificarValidezContrasenias(contraseniaAnterior, contraseniaNueva, reingresoContrasenia, cuentas.pass)) {
			dispatch(modificarClaveCuenta(user.user.email, encriptar(contraseniaNueva)));
		} else {
			setRespuestaBack(false);
		}
	};
	function encriptar(string) {
		const secretKey = "claveSegura";
		return cryptoJs.AES.encrypt(string, secretKey).toString();
	}

	const verificarValidezContrasenias = (passViejo, nuevoPass, reingresoNuevoPass, acceso) => {
		if (!acceso) {
			setRespuestaBack(true);
			setMensajeErrorIngresoContrasenia("La contraseña anterior no es válida");
			setSeverityAlert("error");
			return false;
		} else if (nuevoPass.length === 0) {
			setRespuestaBack(true);
			setMensajeErrorIngresoContrasenia("Por favor ingrese contraseña nueva");
			return false;
		} else if (nuevoPass === passViejo) {
			setRespuestaBack(true);
			setMensajeErrorIngresoContrasenia("La nueva contraseña debe ser distinta a la anterior");
			return false;
		} else if (nuevoPass !== reingresoNuevoPass) {
			setRespuestaBack(true);
			setMensajeErrorIngresoContrasenia("Las contraseñas no coinciden");
			return false;
		} else {
			return true;
		}
	};

	return (
		<Modal
			open={true}
			onClose={(e) => {
				e.preventDefault();
				setMostrarModalCambioContrasenia(false);
			}}
			style={{ backdropFilter: "blur(4px)" }}
			className="shadow"
		>
			<Box sx={style}>
				{respuestaBack && (
					<Alert severity={severityAlert}>
						{mensajeErrorIngresoContrasenia && mensajeErrorIngresoContrasenia}
						{cuentas.msj.length > 0 && cuentas.msj}
					</Alert>
				)}
				{mensajeErrorIngresoContrasenia && <Alert severity={severityAlert}>{mensajeErrorIngresoContrasenia}</Alert>}
				<h4 className="modales-titulo_modal">Cambio de contraseña</h4>
				<img
					src={iconoCerrarModal}
					className="modales-cerrar_modal-img"
					alt="close"
					onClick={() => {
						setMostrarModalCambioContrasenia(false);
					}}
				/>
				<form className="modales-contenedor_texto-div" onSubmit={handleSubmitSignUp} autoComplete="off">
					<Box
						sx={{
							"& .MuiTextField-root": {
								m: 1,
								width: "25ch",
							},
						}}
						noValidate
					>
						<div className="modales-row">
							<input
								className="modales-cambio_pass-contras_input"
								placeholder="Contraseña actual"
								required
								minLength={8}
								maxLength={12}
								name="actual password"
								autoComplete="off"
								type={verIngresoPass1 ? "text" : "password"}
								onChange={(e) => {
									if (e.nativeEvent.data !== " ") setContraseniaAnterior(e.target.value);
								}}
								value={contraseniaAnterior}
							/>
							<img
								onClick={() => setVerIngresoPass1(!verIngresoPass1)}
								type="button"
								className="modal-cambiar_pass-icono_ojo"
								src={verIngresoPass1 ? iconoVerActivo : iconoVerInactivo}
								alt="see password"
							/>
						</div>
						<hr className="modales-hr" />
						<div className="modales-row">
							<input
								className="modales-cambio_pass-contras_input"
								placeholder="Nueva contraseña"
								required
								minLength={8}
								maxLength={12}
								name="new password"
								autoComplete="off"
								type={verIngresoPass2 ? "text" : "password"}
								onChange={(e) => {
									if (e.nativeEvent.data !== " ") setContraseniaNueva(e.target.value);
								}}
								value={contraseniaNueva}
							/>
							<img
								onClick={() => setVerIngresoPass2(!verIngresoPass2)}
								type="button"
								className="modal-cambiar_pass-icono_ojo"
								src={verIngresoPass2 ? iconoVerActivo : iconoVerInactivo}
								alt="see password"
							/>
						</div>
						<hr className="modales-hr" />
						<div className="modales-row">
							<input
								className="modales-cambio_pass-contras_input"
								placeholder="Confirmar contraseña"
								required
								minLength={8}
								maxLength={12}
								name="new password repeat"
								type={verIngresoPass3 ? "text" : "password"}
								autoComplete="off"
								onChange={(e) => {
									if (e.nativeEvent.data !== " ") setReingresoContrasenia(e.target.value);
								}}
								value={reingresoContrasenia}
							/>
							<img
								onClick={() => setVerIngresoPass3(!verIngresoPass3)}
								type="button"
								className="modal-cambiar_pass-icono_ojo"
								src={verIngresoPass3 ? iconoVerActivo : iconoVerInactivo}
								alt="see password"
							/>
						</div>
						<hr className="modales-hr" />
					</Box>
					<button
						className="modales-modal_editar-boton modales-modal_editar-btn_editar"
						disabled={contraseniaAnterior.length < 4 || contraseniaNueva.length < 4 || reingresoContrasenia.length < 4 ? true : false}
					>
						Cambiar contraseña
					</button>
				</form>
			</Box>
		</Modal>
	);
};

export default ModalCambioPass;
