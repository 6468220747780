import {
    GET_COMMERCIAL_CONDITION_ERROR,
    GET_COMMERCIAL_CONDITION_SUCCESS,
    SET_COMMERCIAL_CONDITION_MESSAGE,
    START_GET_COMMERCIAL_CONDITION,
} from "../../types/index";

import clienteAxios from "../../../Axios";

export function deleteCommercialCondition(id) {
    return async (dispatch) => {
        try {
            const response = await clienteAxios.delete(`commercialCondition/?id=${id}`);
            dispatch(setCommercialConditionMessage(response.data.message))
        } catch (e) {
            dispatch(getCommercialConditionsError());
        }
    };
}

export function getCommercialConditions() {
    return async (dispatch) => {
        dispatch(downloadCommercialConditions());
        try {
            const respuesta = await clienteAxios.get("commercialCondition");
            dispatch(getCommercialConditionsSuccess(respuesta.data.response));
        } catch (e) {
            dispatch(getCommercialConditionsError());
        }
    };
}

export function updateCommercialCondition(name, percentage, id) {
    return async (dispatch) => {
        try {
            const response = await clienteAxios.put(`commercialCondition/?id=${id}&name=${name}&percentage=${percentage}`);
            dispatch(setCommercialConditionMessage(response.data.message))
        } catch (e) {
            dispatch(getCommercialConditionsError());
        }
    };
}

export function postCommercialCondition(name, percentage) {
    return async (dispatch) => {
        try {
            const respuesta = await clienteAxios.post(`commercialCondition/?name=${name}&percentage=${percentage}`);
            dispatch(setCommercialConditionMessage(respuesta.data.message));
        } catch (e) {
            dispatch(getCommercialConditionsError());
        }
    };
}

const setCommercialConditionMessage = (message) => ({
    type: SET_COMMERCIAL_CONDITION_MESSAGE,
    payload: message
})

const downloadCommercialConditions = () => ({
    type: START_GET_COMMERCIAL_CONDITION,
});

const getCommercialConditionsSuccess = (commercialConditions) => ({
    type: GET_COMMERCIAL_CONDITION_SUCCESS,
    payload: commercialConditions,
});

const getCommercialConditionsError = () => ({
    type: GET_COMMERCIAL_CONDITION_ERROR,
});