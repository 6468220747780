import React from "react";
import { Line } from "react-chartjs-2";
import "./graficos.css";
export default function GraficoLinea({ dashboard }) {
	const data =
		dashboard && dashboard.data.data === "stock"
			? dashboard.stats[0].total_stock.map((data) => data.total_stock)
			: dashboard.stats[0].min_price.map((data) => data.min_price);
	const info = {
		labels: dashboard && dashboard.data.data === "stock"
			? dashboard.stats[0].total_stock.map((data) => data._id)
			: dashboard.stats[0].min_price.map((data) => data._id),
		datasets: [
			{
				label: dashboard.data.data,
				data,
				backgroundColor: ["#88b594"],
				borderColor: ["#88b594"],
			},
		],
	};

	return (
		<Line
			data={info}
			options={{
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false,
					},
				},
			}}
		/>
	);
}
