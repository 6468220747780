import React, { useState } from "react";
import { useSelector } from "react-redux";

import addIcon from "../../../assets/svg/functionalities-icons/plus_blue.svg";
import infoCircle from "../../../assets/svg/information-icons/info-circle.svg";
import iconOptions from "../../../assets/svg/functionalities-icons/tres-puntos-porcentaje-lista.svg";
import iconArrow_gray from "../../../assets/svg/functionalities-icons/arrow_gray.svg";

import '../commercialization.css'

const PriceList = ({ setShowConfirmationModal, setShowCreationModal }) => {

	const priceLists = useSelector((state) => state.priceList.priceList);

	const [closeSection, setCloseSection] = useState(false)
	const [modalToOpen, setModalToOpen] = useState("");

	const handleUpdatePriceList = (priceList) => {
		setShowCreationModal({
			action: 'Editar',
			type: 'Lista de precio',
			values: priceList
		})
		setModalToOpen("");
	};

	const handleOpenOptions = (priceList) => {
		setModalToOpen(priceList._id);
	};

	const handleLeaveOptions = (className) => {
		if (
			className !== "cuentas-comercial-button_edit" &&
			className !== "cuentas-comercial-button_undo" &&
			className !== "cuentas-comercial-button_options"
		)
			setModalToOpen("");
	};

	return (
		<div className="cuentas-comercial" onClick={(e) => handleLeaveOptions(e.target.className)}>
			<div className="cuentas-info_cuentas-contra_row" onClick={() => { setCloseSection(!closeSection) }}>
				<h2>Listas de precios</h2>
				<img src={iconArrow_gray} style={!closeSection ? { rotate: "180deg" } : {}} />
			</div>
			<hr className="cuentas-info_cuentas-hr" />
			<div className={closeSection ? "d-none" : undefined}>
				<section>
					<img src={infoCircle} alt="info-icon" />
					<p>
						Los porcentajes se aplican a los costos. Solo se puede aplicar una lista de precios a la vez. El porcentaje aplicado no se muestra
						a quien no tenga disponible su vista.
					</p>
				</section>
				<button onClick={() => setShowCreationModal({ action: 'Crear', type: 'Lista de precio' })} className="commercialization-add-button">
					<img src={addIcon} />
				</button>
				{priceLists &&
					priceLists.map((priceList) => {
						return (
							<div className="priceList-list-container" key={priceList._id}>
								<div>
									<section>
										<h5>{priceList.name}</h5>
										<h5>[ {priceList.percentage} % ]</h5>
									</section>
									<img
										src={iconOptions}
										alt="options-icon"
										className="cuentas-comercial-button_options"
										onClick={() => handleOpenOptions(priceList)}
									/>
									{priceList._id === modalToOpen && (
										<div className="priceList-list-optiones_container">
											<button className="cuentas-comercial-button_edit" onClick={() => handleUpdatePriceList(priceList)}>
												Editar
											</button>
											<button className="cuentas-comercial-button_delete"
												onClick={() => setShowConfirmationModal({ type: "lista de precio", name: priceList.name, id: priceList._id })}>
												Eliminar
											</button>
										</div>
									)}
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default PriceList;
