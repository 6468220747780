import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal, Box } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { makeStyles } from "mui-styles";

import { modificarCuentas, obtenerCuentas } from "../../../redux/actions/cuentas/cuentasUsersAction";

import iconoCerrarModal from "../../../assets/svg/functionalities-icons/cross_gray.svg";

import "./modalesCuentas.css";

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
	style: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "320px",
		height: "auto",
		background: "#ffffff",
		borderRadius: "10px",
		boxShadow: 24,
		padding: "20px",
		disableAutoFocus: "true",
		outline: "none",
	},
	["@media (min-width:1298px) "]: {
		style: {
			width: "380px",
		},
	},
});

const ModalModificarCuenta = ({ cuenta, setMostrarModalModificar, mostrarModalModificar }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const cuentas = useSelector((state) => state.cuentas);
	const user = useSelector((state) => state.users);
	const [newUsername, setNewUsername] = useState(cuenta.username);
	const [respuestaCrearCuentaBack, setRespuestaCrearCuentaBack] = useState("");
	const [msjResteoClave, setMsjResteoClave] = useState(false);
	const [severityAlert, setSeverityAlert] = useState("error");

	useEffect(() => {
		setRespuestaCrearCuentaBack(false);
	}, [newUsername]);

	useEffect(() => {
		setRespuestaCrearCuentaBack(false);
		setMsjResteoClave(false);
	}, [mostrarModalModificar]);

	useEffect(() => {
		if (cuentas.msj && cuentas.msj === "Usuario existente") {
			setSeverityAlert("error");
			setRespuestaCrearCuentaBack(true);
		} else if ((cuentas.msj && cuentas.msj === "Sin modificaciones") || cuentas.msj === "Usuario requerido") {
			setSeverityAlert("warning");
			setRespuestaCrearCuentaBack(true);
		} else if (cuentas.msj && cuentas.msj === "Actualizacion correcta") {
			setSeverityAlert("success");
			setRespuestaCrearCuentaBack(true);
		}
	}, [user.msj, cuentas.msj]);

	const handleSubmitSignUp = (e) => {
		e.preventDefault();
		dispatch(modificarCuentas(cuenta._id, cuenta.username, newUsername.trim()));
		setTimeout(() => {
			setRespuestaCrearCuentaBack(false);
			setMsjResteoClave(false);
			dispatch(obtenerCuentas());
		}, 1500);
	};
	return (
		<Modal
			open={true}
			onClose={(e) => {
				e.preventDefault();
				dispatch(obtenerCuentas());
				setMostrarModalModificar(false);
			}}
			style={{ backdropFilter: "blur(4px)" }}
		>
			<Box className={classes.style}>
				{respuestaCrearCuentaBack && <Alert severity={severityAlert}>{cuentas.msj && cuentas.msj.length > 0 && cuentas.msj}</Alert>}
				{msjResteoClave && <Alert severity="success">{user.msj && user.msj.length > 0 && user.msj}</Alert>}
				<h4 className="modales-titulo_modal">Editar</h4>
				<img
					src={iconoCerrarModal}
					className="modales-cerrar_modal-img"
					alt="close"
					onClick={() => {
						setMostrarModalModificar(false);
					}}
				/>
				<form className="modales-contenedor_formulario" onSubmit={handleSubmitSignUp}>
					<div className="modales-row">
						<h4 className="modales-texto_modal">Rol:</h4>
						<h4 className="modales-texto_modal modales-texto_modal-deshabilitado">{cuenta.role.value}</h4>
					</div>
					<Box
						sx={{
							"& .MuiTextField-root": {
								m: 1,
								width: "25ch",
							},
						}}
						noValidate
						autoComplete="off"
					>
						<hr className="modales-hr" />
						<div className="modales-row">
							<h4 className="modales-texto_modal">Usuario:</h4>
							<input
								autoComplete="off"
								className="modales-modal_editar-input"
								name="usuario"
								onChange={(e) => {
									setNewUsername(e.target.value);
								}}
								required
								type="text"
								value={newUsername}
							/>
						</div>
						<hr className="modales-hr" />
						<div className="modales-row">
							<h4 className="modales-texto_modal">Correo:</h4>
							<h4 className="modales-texto_modal modales-texto_modal-deshabilitado">{cuenta.email}</h4>
						</div>
						<hr className="modales-hr" />
					</Box>
					<button className="modales-modal_editar-boton modales-modal_editar-btn_editar" onClick={(e) => handleSubmitSignUp(e)}>
						Editar cuenta
					</button>
				</form>
			</Box>
		</Modal>
	);
};

export default ModalModificarCuenta;
