import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";

import "./navbar.css";

import ArrowOpen from "../../assets/svg/functionalities-icons/arrow_gray.svg";
import ArrowClose from "../../assets/svg/functionalities-icons/arrow-open_white.svg";

export default function MenuItems() {
	const navbarCategories = useSelector((state) => state.navbar.categories);

	const [openCategory, setOpenCategory] = useState(navbarCategories.map(() => false));
	const [openSubcategory, setOpenSubcategory] = useState([]);
	const [categoryObject, setCategoryObject] = useState([]);
	const [currentCategory, setCurrentCategory] = useState("");

	useEffect(() => {
		setOpenSubcategory(categoryObject.map(() => false));
	}, [categoryObject]);

	useEffect(() => {
		setOpenSubcategory(categoryObject.map(() => false));
		setOpenCategory(navbarCategories.map(() => false));
	}, [, navbarCategories]);

	const handleCategoryClick = (category, position) => {
		if (category.name === currentCategory) {
			setCurrentCategory("");
			setCategoryObject([]);
		} else {
			setCurrentCategory(category.name.replace(" ", "_").toLowerCase());
			setCategoryObject(category.subcategories);
		}

		setOpenCategory(
			openCategory.map((data, i) => {
				if (i === position) return !data;
				return false;
			})
		);
	};

	const handleCategoryHover = (category, position) => {
		if (window.innerWidth > 1298) {
			setCurrentCategory(category.name.replace(" ", "_").toLowerCase());
			setCategoryObject(category.subcategories);

			setOpenCategory(
				openCategory.map((data, i) => {
					if (i === position) return true;
					return false;
				})
			);
		}
	};
	const handleClickSubcategory = (position) => {
		setOpenSubcategory(
			openSubcategory.map((data, i) => {
				if (i === position) return !data;
				return false;
			})
		);
	};

	const handleResetNavbarState = () => {
		setCurrentCategory("");
		setCategoryObject([]);
		setOpenCategory(navbarCategories.map(() => false));
	};

	return (
		<div className="menuItems-container">
			<ul>
				{navbarCategories.map((element, index) => (
					<li className={openCategory[index] ? "opacity45" : ""} key={uuidv4()} onMouseEnter={() => handleCategoryHover(element, index)}>
						<section onClick={() => handleCategoryClick(element, index)}>
							<div>
								<img src={element.images && element.images.desktop} alt="pc" />
								<img
									src={element.images && element.images.phone}
									className={openCategory[index] ? "opacity45" : ""}
									alt="phone"
								/>
							</div>
							<h4>{element.name}</h4>
						</section>
					</li>
				))}
			</ul>
			<div className={categoryObject.length !== 0 ? "dropdownContainer" : "d-none"}>
				{categoryObject.map((element, index) => (
					<div className={index !== categoryObject.length - 1 ? "borderBottom" : ""} key={uuidv4()}>
						<div
							className={openSubcategory[index] ? "dropdowbCategoryRowOpen" : ""}
							style={{ cursor: "pointer" }}
							onClick={() => handleClickSubcategory(index)}
						>
							<h6>{element.name}</h6>
							<img src={openSubcategory[index] ? ArrowClose : ArrowOpen} alt="Arrow" />
						</div>
						<section>
							{openSubcategory[index] &&
								element.indices.map((elementProduct) => (
									<Link to={"/" + currentCategory + "-" + element.name.replace(/ /g, "_").toLowerCase() + "-" + elementProduct.name.replace(/ /g, "_").toLowerCase()}
										key={uuidv4()} onClick={handleResetNavbarState}>
										<div>{elementProduct.name}</div>
									</Link>
								))}
						</section>
					</div>
				))}
			</div>
			<div className={categoryObject.length !== 0 ? "pcDropdownMenu" : "d-none"} onMouseLeave={handleResetNavbarState}>
				{categoryObject.map((element) => (
					<div key={uuidv4()}>
						<h6>{element.name}</h6>
						<hr />
						{element.indices.map((item) => (
							<Link
								to={"/" + currentCategory + "-" + element.name.replace(/ /g, "_").toLowerCase() + "-" + item.name.replace(/ /g, "_").toLowerCase()}
								key={uuidv4()} onClick={handleResetNavbarState}
							>
								<h4>{item.name}</h4>
							</Link>
						))}
					</div>
				))}
			</div>
		</div>
	);
}
