export const COMENZAR_CREACION_FILTRO = "COMENZAR_CREACION_FILTRO";
export const COMENZAR_CREACION_FILTRO_EXITO = "COMENZAR_CREACION_FILTRO_EXITO";
export const COMENZAR_CREACION_FILTRO_ERROR = "COMENZAR_CREACION_FILTRO_ERROR";

export const COMENZAR_DESCARGA_FILTRO = "COMENZAR_DESCARGA_FILTRO";
export const COMENZAR_DESCARGA_FILTRO_EXITO = "COMENZAR_DESCARGA_FILTRO_EXITO";
export const COMENZAR_DESCARGA_FILTRO_ERROR = "COMENZAR_DESCARGA_FILTRO_ERROR";

export const COMENZAR_EDICION_FILTRO = "COMENZAR_EDICION_FILTRO";
export const COMENZAR_EDICION_FILTRO_EXITO = "COMENZAR_EDICION_FILTRO_EXITO";
export const COMENZAR_EDICION_FILTRO_ERROR = "COMENZAR_EDICION_FILTRO_ERROR";

export const COMENZAR_ELIMINACION_FILTRO = "COMENZAR_ELIMINACION_FILTRO";
export const COMENZAR_ELIMINACION_FILTRO_EXITO = "COMENZAR_ELIMINACION_FILTRO_EXITO";
export const COMENZAR_ELIMINACION_FILTRO_ERROR = "COMENZAR_ELIMINACION_FILTRO_ERROR";

export const LIMPIAR_FILTROS = "LIMPIAR_FILTROS";
