import { EXCEL_LOAD_START, EXCEL_LOAD_ERROR, GET_EXCELHISTORY_SUCCESS } from "../../types/index";

import clienteAxios from "../../../Axios";

export function excelLoad(indexID, productType, items, parser, fileName) {
	return async (dispatch) => {
		dispatch(startExcelLoading());
		try {
			const promises = [];
			for (let i = 0; i < Math.ceil(items.length / 100); i++) {
				let data = {
					indexID,
					productType,
					items: items.slice(i * 100, (i + 1) * 100),
					parser,
					fileName,
				};
				const promise = clienteAxios.post("excelLoad", data);
				promises.push(promise);
			}
			await Promise.all(promises);

			let data = {
				indexID,
				productType,
				parser,
				fileName,
			};
			await clienteAxios.post("excelHistory", data);
			dispatch(getExcelHistory());
			return true
		} catch (e) {
			dispatch(excelError());
			return true
		}
	};
}

export function getExcelHistory() {
	return async (dispatch) => {
		try {
			const respuesta = await clienteAxios.get("excelHistory");
			dispatch(getExcelHistorySuccess(respuesta.data.response));
		} catch (e) {
			dispatch(excelError());
		}
	};
}

export function deleteExcelHistory(excel) {
	return async (dispatch) => {
		dispatch(startExcelLoading());
		try {
			await clienteAxios.delete(`excelHistory/?indexID=${excel.product.index}&empresa=${excel.company}&id=${excel._id}`);
			dispatch(getExcelHistory())
		} catch (e) {
			dispatch(excelError());
		}
	};
}

const getExcelHistorySuccess = (excelHistory) => ({
	type: GET_EXCELHISTORY_SUCCESS,
	payload: excelHistory,
});

const startExcelLoading = () => ({
	type: EXCEL_LOAD_START,
});

const excelError = () => ({
	type: EXCEL_LOAD_ERROR,
});
