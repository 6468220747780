import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import circle_check from '../../../assets/svg/information-icons/circle_check.svg'
import circle_cross from '../../../assets/svg/information-icons/circle_cross.svg'
import iconArrow_gray from "../../../assets/svg/functionalities-icons/arrow_gray.svg";

import SearchIcon from "@mui/icons-material/Search";
import { getProductIndices } from '../../../redux/actions/cuentas/marketPlayerActions';

export const IndexSearchIndices = ({ handleSelectedIndex }) => {
    const dispatch = useDispatch();
    const productsIndices = useSelector((state) => state.marketPlayer.indices);
    const navbarCategories = useSelector((state) => state.navbar.categories);

    const [closeSection, setCloseSection] = useState(false)

    const [params] = useSearchParams();
    const [marketPlayerID] = useState(params.get("id") || "");

    const [searchFilter, setSearchFilter] = useState("")
    const [actualProductsIndices, setActualProductsIndices] = useState(productsIndices || [])

    const errorQuantity = productsIndices.filter((productIndex) => {
        if (productIndex?.status?.code > 300) return productIndex
        return
    }).length


    useEffect(() => {
        dispatch(getProductIndices(marketPlayerID));
    }, []);

    useEffect(() => {
        if (searchFilter === "") setActualProductsIndices(productsIndices)
        else {
            const newActualProductsIndices = productsIndices.filter((productIndex) => {
                if (productIndex.index.includes(searchFilter)) return productIndex
                return
            })
            setActualProductsIndices(newActualProductsIndices)
        }
    }, [searchFilter, productsIndices])

    return (
        <>
            <div className="cuentas-info_cuentas-row" onClick={() => { setCloseSection(!closeSection) }}>
                <h2>Indices</h2>
                <img alt="arrow-icon" src={iconArrow_gray} style={!closeSection ? { rotate: "180deg" } : {}} />
            </div>
            <hr className="cuentas-info_cuentas-hr" />
            <div className={`indexSearchIndices-container ${closeSection ? "d-none" : undefined} `}>
                <div>
                    <div className="cuentas-cuentas_usuario-search_cont">
                        <input
                            placeholder="Categoria, subcategoria o indice..."
                            autoComplete="off"
                            name="email"
                            onChange={(e) => {
                                setSearchFilter(e.target.value.toLowerCase());
                            }}
                        />
                        <SearchIcon />
                    </div>
                    {errorQuantity ?
                        <h2 style={{ color: 'var(--color-texto-error)' }}><img alt="no-error-icon" src={circle_cross} /> Se {errorQuantity > 1 ? "han" : "ha"} encontrado {errorQuantity} {errorQuantity > 1 ? "errores" : "error"} </h2> :
                        <h2 ><img src={circle_check} alt="error-icon" /> No se ha encontrado ningun error </h2>
                    }
                </div>
                {navbarCategories.map((category) => {
                    return <div key={category._id}>
                        <h3>{category.name}</h3>
                        {category.subcategories.map((subcategory, index) => {
                            return <div key={index}>
                                <h4>{subcategory.name}</h4>
                                <div className="indexSearchIndices-category">
                                    {actualProductsIndices.length > 0 && actualProductsIndices.map((productIndex) => {
                                        const path = productIndex.indexPath.split('-')
                                        if (category.name.toLowerCase().replace(/ /g, "_") === path[0] && subcategory.name.toLowerCase().replace(/ /g, "_") === path[1]) {
                                            return <section key={productIndex._id}
                                                onClick={() => handleSelectedIndex(productIndex)}
                                                className={productIndex?.status?.code > 300 ? "indexSearchIndices-category-error" : ""} >
                                                {path[2].replace(/_/g, " ")}
                                            </section>
                                        }
                                    })}
                                </div>
                            </div>
                        })}
                    </div>
                })}
            </div>
        </>
    )
}