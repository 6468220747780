import {
	LOG_IN_USER,
	LOG_IN_USER_EXITO,
	LOG_IN_USER_ERROR,
	LOG_OUT_USER,
	LOG_OUT_USER_EXITO,
	LOG_OUT_USER_ERROR,
	NAVBAR_HIDE,
	NAVBAR_SHOW,
	RECUPERAR_CONTRASEÑIA_USER,
	RECUPERAR_CONTRASEÑIA_USER_EXITO,
	RECUPERAR_CONTRASEÑIA_USER_ERROR,
	MODIFICAR_CREAR_CLAVE_CUENTA,
	MODIFICAR_CREAR_CLAVE_CUENTA_EXITO,
	MODIFICAR_CREAR_CLAVE_CUENTA_ERROR,
	GET_DATOS_USER,
	GET_DATOS_USER_EXITO,
	GET_DATOS_USER_ERROR,
	ELIMINAR_MENSAJE_OLVIDAR_CONTRASENIA,
	CONTRASENIA_PROVISORIA,
	BORRAR_CONTRASENIA_PROVISORIA,
	RESETEAR_ESTADO_GLOBAL_ERROR,
	RESETEAR_CLAVE_PROVISORIA,
} from "../../types";
import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";
const cookie = new universlaCookie();

export function borrarClaveProvisoria() {
	return async (dispatch) => {
		dispatch(borrarClaveProvisoriaEstado());
	};
}
const borrarClaveProvisoriaEstado = () => ({
	type: BORRAR_CONTRASENIA_PROVISORIA,
});

export function logInUser(userInput, passEncriptado) {
	const user = {
		email: userInput,
		password: passEncriptado,
	};
	return async (dispatch) => {
		dispatch(logIn());
		try {
			const respuesta = await clienteAxios({
				method: "POST",
				url: "signInUser",
				data: user,
			});
			if (respuesta.data.message === "Acceso Provisorio") {
				dispatch(claveProvisoriaExito());
			} else {
				guardarTokenEnCookie(respuesta.data);
				dispatch(signInExito(respuesta.data));
				dispatch(getDatosUsers());
			}
		} catch (error) {
			dispatch(signInError(error));
		}
	};
}
const logIn = () => ({
	type: LOG_IN_USER,
});

const signInExito = (respuesta) => ({
	type: LOG_IN_USER_EXITO,
	payload: respuesta,
});
const signInError = (respuesta) => ({
	type: LOG_IN_USER_ERROR,
	payload: respuesta,
});
const claveProvisoriaExito = () => ({
	type: CONTRASENIA_PROVISORIA,
});

export function getDatosUsers() {
	return async (dispatch) => {
		dispatch(getDatosUser());
		try {
			const respuesta = await clienteAxios({
				method: "GET",
				url: "user",
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(getDatosUserExito(respuesta.data));
		} catch (error) {
			dispatch(getDatosUserError(error));
			removeTokenCookie(error);
		}
	};
}

function removeTokenCookie(error) {
	if (error.response.status === 401) {
		cookie.remove("token");
		localStorage.removeItem("user");
	}
}

export function resetearEstadoErrorUser() {
	return async (dispatch) => {
		dispatch(borrarEstadoError());
	};
}

const borrarEstadoError = () => ({
	type: RESETEAR_ESTADO_GLOBAL_ERROR,
});

export function resetearClaveProvisoria() {
	return async (dispatch) => {
		dispatch(resetearClaveProv());
	};
}

const resetearClaveProv = () => ({
	type: RESETEAR_CLAVE_PROVISORIA,
});

const getDatosUser = () => ({
	type: GET_DATOS_USER,
});
const getDatosUserExito = (respuesta) => ({
	type: GET_DATOS_USER_EXITO,
	payload: respuesta,
});
const getDatosUserError = (respuesta) => ({
	type: GET_DATOS_USER_ERROR,
	payload: respuesta,
});

export function recuperarContrasenia(emailEncriptado) {
	return async (dispatch) => {
		dispatch(recuperacionContrasenia());
		try {
			const respuesta = await clienteAxios({
				method: "post",
				url: "cambioContrasenia",
				data: { email: emailEncriptado },
			});
			dispatch(recuperacionContraseniaExito(respuesta.data));
		} catch (error) {
			dispatch(recuperacionContraseniaError(error));
		}
	};
}

const recuperacionContrasenia = (respuesta) => ({
	type: RECUPERAR_CONTRASEÑIA_USER,
	payload: respuesta,
});
const recuperacionContraseniaExito = (respuesta) => ({
	type: RECUPERAR_CONTRASEÑIA_USER_EXITO,
	payload: respuesta,
});
const recuperacionContraseniaError = (respuesta) => ({
	type: RECUPERAR_CONTRASEÑIA_USER_ERROR,
	payload: respuesta,
});

export function modificarClaveCuenta(email, newPassword) {
	return async (dispatch) => {
		dispatch(modificarClave());
		try {
			const respuesta = await clienteAxios({
				method: "PUT",
				url: "changeUserPassword",
				data: {
					email: email,
					newPassword: newPassword,
				},
			});
			guardarTokenEnCookie(respuesta.data);
			dispatch(signInExito(respuesta.data));
			dispatch(getDatosUsers());
			dispatch(modificarClaveExito(respuesta.data));
			dispatch(borrarClaveProvisoriaEstado());
		} catch (e) {
			dispatch(modificarClaveError());
		}
	};
}
const guardarTokenEnCookie = (respuesta) => {
	localStorage.setItem("LogInStatus", true);
	cookie.set("token", respuesta.token);
	localStorage.setItem("user", respuesta.user);
};
const modificarClave = () => ({
	type: MODIFICAR_CREAR_CLAVE_CUENTA,
	payload: true,
});

const modificarClaveExito = (cuentas) => ({
	type: MODIFICAR_CREAR_CLAVE_CUENTA_EXITO,
	payload: cuentas,
});

const modificarClaveError = () => ({
	type: MODIFICAR_CREAR_CLAVE_CUENTA_ERROR,
});

export function logOutUser() {
	return (dispatch) => {
		dispatch(logOut());
		try {
			dispatch(logOutExito({ msj: "Usuario Deslogueado" }));
		} catch (error) {
			dispatch(logOutError(error));
		}
	};
}
const logOut = () => ({
	type: LOG_OUT_USER,
});

const logOutExito = (respuesta) => ({
	type: LOG_OUT_USER_EXITO,
	payload: respuesta,
});
const logOutError = (respuesta) => ({
	type: LOG_OUT_USER_ERROR,
	payload: respuesta,
});

export function ocultarNadbar() {
	return (dispatch) => {
		dispatch(ocultarNadbarSignIn());
	};
}
export function mostrarNadbar() {
	return (dispatch) => {
		dispatch(mostrarNadbarSignIn());
	};
}

const ocultarNadbarSignIn = () => ({
	type: NAVBAR_HIDE,
	payload: true,
});
const mostrarNadbarSignIn = () => ({
	type: NAVBAR_SHOW,
	payload: false,
});

export function EliminarMensajeOlvidarContrasenia() {
	return (dispatch) => {
		dispatch(EliminarMensajeOlvidarContraseniaAction());
	};
}

const EliminarMensajeOlvidarContraseniaAction = () => ({
	type: ELIMINAR_MENSAJE_OLVIDAR_CONTRASENIA,
});
