import {
	MODIFICAR_MI_CUENTA_BASE_DE_DATOS,
	MODIFICAR_MI_CUENTA_BASE_DE_DATOS_EXITO,
	MODIFICAR_MI_CUENTA_BASE_DE_DATOS_ERROR,
	RESETEAR_ESTADO_REDUX_MI_CUENTA,
} from "../../types/index";
import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";
const cookie = new universlaCookie();

export function modificarMiCuenta(userId, username, newUsername) {
	return async (dispatch) => {
		dispatch(modificarCuenta());
		try {
			const respuesta = await clienteAxios({
				method: "PUT",
				url: "user",
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: {
					userId: userId,
					username: username,
					newUsername: newUsername,
				},
			});
			dispatch(modificarCuentaExito(respuesta.data));
		} catch (e) {
			dispatch(modificarCuentaError(e));
		}
	};
}

const modificarCuenta = () => ({
	type: MODIFICAR_MI_CUENTA_BASE_DE_DATOS,
});

const modificarCuentaExito = (cuentas) => ({
	type: MODIFICAR_MI_CUENTA_BASE_DE_DATOS_EXITO,
	payload: cuentas,
});

const modificarCuentaError = (error) => ({
	type: MODIFICAR_MI_CUENTA_BASE_DE_DATOS_ERROR,
	payload: error,
});

export function resetearEstadosMiCuenta() {
	return (dispatch) => {
		dispatch(resetearEstadoRedux());
	};
}
const resetearEstadoRedux = () => ({
	type: RESETEAR_ESTADO_REDUX_MI_CUENTA,
});
