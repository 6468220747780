import {
	GET_PRICE_LISTS_ERROR,
	GET_PRICE_LISTS_SUCCESS,
	SET_PRICE_LISTS_MESSAGE,
	START_GET_PRICE_LISTS,
} from "../../types/index";

const initialState = {
	priceList: [],
	loading: false,
	error: false,
	message: "",
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_PRICE_LISTS_ERROR:
			return {
				...state,
				error: true,
				loading: false,
			};
		case GET_PRICE_LISTS_SUCCESS:
			return {
				...state,
				error: null,
				loading: false,
				message: "",
				priceList: action.payload,
			};
		case SET_PRICE_LISTS_MESSAGE:
			return {
				...state,
				message: action.payload,
			}
		case START_GET_PRICE_LISTS:
			return {
				...state,
				loading: true,
			};
		default:
			return state;
	}
}
