import { COMENZAR_DESCARGA_DOLLAR, COMENZAR_DESCARGA_DOLLAR_EXITO, COMENZAR_DESCARGA_DOLLAR_ERROR } from "../../types/index";

import clienteAxios from "../../../Axios";

export function obtenerDollars() {
	return async (dispatch) => {
		dispatch(descargarDollar());
		try {
			const response = await clienteAxios.get("dollar");
			dispatch(descargarDollarExito(response.data.response));
		} catch (e) {
			dispatch(descargarDollarError());
		}
	};
}

const descargarDollar = () => ({
	type: COMENZAR_DESCARGA_DOLLAR,
});

const descargarDollarExito = (dollar) => ({
	type: COMENZAR_DESCARGA_DOLLAR_EXITO,
	payload: dollar,
});

const descargarDollarError = () => ({
	type: COMENZAR_DESCARGA_DOLLAR_ERROR,
});
