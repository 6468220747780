import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal, Box } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { signUpUser, limpiarEstadosSignUpUser } from "../../../redux/actions/registrosUsuarios/signInUserAction";
import { obtenerCuentas } from "../../../redux/actions/cuentas/cuentasUsersAction";

import cryptoJs from "crypto-js";

import iconoUsuario from "../../../assets/svg/others-icons/usuario.svg";
import iconoAdmin from "../../../assets/svg/others-icons/admin.svg";
import iconoUsuarioSelect from "../../../assets/svg/others-icons/usuario-select.svg";
import iconoAdminSelect from "../../../assets/svg/others-icons/admin-select.svg";
import iconoCerrarModal from "../../../assets/svg/functionalities-icons/cross_gray.svg";
import config from "../../../config/config";

import "./modalesCuentas.css";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "300px",
	height: "auto",
	bgcolor: "#ffffff",
	borderRadius: "10px",
	boxShadow: 24,
	p: 0,
	disableAutoFocus: "true",
	outline: "none",
};

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ModalCrearCuenta = ({
	mostrarVentaCrearCuenta,
	errorReingresoPassword,
	setMostrarVentanaCrearCuenta,
	setOpen,
	setMensajeAdvertencia,
}) => {
	const dispatch = useDispatch();
	const sigInUser = useSelector((state) => state.sigIn);
	const user = useSelector((state) => state.users);
	const [rol, setRol] = useState("user");
	const [claseSeleccionRol1, setClaseSeleccionRol1] = useState("modales-crear_cuenta-rol");
	const [claseSeleccionRol2, setClaseSeleccionRol2] = useState("modales-crear_cuenta-rol_sel");

	const [errorDetectado, setErrorDetectado] = useState(false);
	const [emailSignUp, setEmailSignUp] = useState();
	const [usernameSignUp, setUsernameSingUp] = useState();
	const [severityAlert, setSeverityAlert] = useState();

	const [imagenAdmin, setImagenAdmin] = useState(iconoAdmin);
	const [imagenUsuario, setImagenUsuario] = useState(iconoUsuarioSelect);

	useEffect(() => {
		dispatch(limpiarEstadosSignUpUser());
		setErrorDetectado(false);
	}, [emailSignUp, usernameSignUp, rol, mostrarVentaCrearCuenta]);

	useEffect(() => {
		if (sigInUser.msj && (sigInUser.msj === "Formato email incorrecto" || sigInUser.msj === "Email existente")) {
			setSeverityAlert("error");
			setErrorDetectado(true);
			setTimeout(() => {
				setErrorDetectado(false);
			}, 2000);
		} else if (sigInUser.msj && sigInUser.msj === "Usuario creado") {
			dispatch(obtenerCuentas());
			setMensajeAdvertencia("Cuenta creada correctamente");
			setOpen(true);
			setMostrarVentanaCrearCuenta(false);
		}
	}, [sigInUser]);

	useEffect(() => {
		if (user.user.role.access === "admin") {
			setRol("user");
		}
	}, [user.user]);

	function encriptar(string) {
		const secretKey = config.secretKey;
		return cryptoJs.AES.encrypt(string, secretKey).toString();
	}
	function cambiarClase1() {
		setClaseSeleccionRol1("modales-crear_cuenta-rol_sel");
		setClaseSeleccionRol2("modales-crear_cuenta-rol");
	}
	function cambiarClase2() {
		setClaseSeleccionRol2("modales-crear_cuenta-rol_sel");
		setClaseSeleccionRol1("modales-crear_cuenta-rol");
	}

	function adminSeleccionado() {
		setImagenAdmin(iconoAdminSelect);
		setImagenUsuario(iconoUsuario);
	}

	function usuarioSeleccionado() {
		setImagenAdmin(iconoAdmin);
		setImagenUsuario(iconoUsuarioSelect);
	}

	const handleSubmitSignUp = (e) => {
		e.preventDefault();
		dispatch(signUpUser(encriptar(emailSignUp.toLowerCase()), encriptar(usernameSignUp), encriptar(rol)));
		setTimeout(() => {
			dispatch(obtenerCuentas());
		}, 1000);
	};

	return (
		<Modal
			open={true}
			onClose={(e) => {
				e.preventDefault();
				setMostrarVentanaCrearCuenta(false);
			}}
			style={{ backdropFilter: "blur(4px)" }}
			className="shadow"
		>
			<Box sx={style}>
				{errorDetectado && !errorReingresoPassword && (
					<Alert severity={severityAlert}>{sigInUser.msj && sigInUser.msj.length > 0 && sigInUser.msj}</Alert>
				)}
				<div className="modales-contenedor_texto-div">
					<h4 className="modales-titulo_modal">Crear</h4>
					<img
						src={iconoCerrarModal}
						className="modales-cerrar_modal-img"
						alt="close"
						onClick={() => {
							setMostrarVentanaCrearCuenta(false);
						}}
					/>
					<form className="modales-contenedor_formulario" onSubmit={handleSubmitSignUp}>
						{user.user.role.access === "superUser" && (
							<div className="modales-row-iconos">
								<div className="modales-iconos_cont-div">
									<div
										className={claseSeleccionRol1}
										type="button"
										onClick={() => {
											setRol("admin");
											cambiarClase1();
										}}
									>
										<div
											onClick={() => {
												adminSeleccionado();
											}}
										>
											<img className="modales-crear_cuenta-icono" src={imagenAdmin} alt="create" />
										</div>
										<label className="modales-crear_cuenta-lable_icono" htmlFor="flexRadioDefault1">
											Admin
										</label>
									</div>
								</div>
								<div className="modales-iconos_cont-div">
									<div
										className={claseSeleccionRol2}
										type="button"
										onClick={() => {
											setRol("user");
											cambiarClase2();
										}}
									>
										<div
											onClick={() => {
												usuarioSeleccionado();
											}}
										>
											<img className="modales-crear_cuenta-icono" src={imagenUsuario} alt="create" />
										</div>
										<label className="modales-crear_cuenta-lable_icono" htmlFor="flexRadioDefault1">
											User
										</label>
									</div>
								</div>
							</div>
						)}
						<Box
							sx={{
								"& .MuiTextField-root": {
									m: 1,
									width: "25ch",
								},
							}}
							noValidate
							autoComplete="off"
						>
							<input
								className="modales-crear_cuenta-input"
								placeholder="Email:"
								type="email"
								autoComplete="off"
								required
								onChange={(e) => {
									setEmailSignUp(e.target.value);
								}}
							/>
							<hr className="modales-hr" />
							<input
								className="modales-crear_cuenta-input"
								placeholder="Usuario:"
								autoComplete="off"
								type="text"
								required
								onChange={(e) => {
									setUsernameSingUp(e.target.value);
								}}
							/>
							<hr className="modales-hr" />
						</Box>
						<button className="modales-modal_editar-boton modales-modal_editar-btn_editar">Crear cuenta</button>
					</form>
				</div>
			</Box>
		</Modal>
	);
};

export default ModalCrearCuenta;
