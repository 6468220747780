import React from "react";
import { Pie } from "react-chartjs-2";
import "./graficos.css";

export default function GraficoTorta({ dashboard }) {
	const data =
		dashboard && dashboard.data.data === "stock"
			? dashboard.stats[0].total_stock.map((data) => data.total_stock)
			: dashboard.stats[0].min_price.map((data) => data.min_price);

	const info = {
		labels: dashboard && dashboard.data.data === "stock"
			? dashboard.stats[0].total_stock.map((data) => data._id)
			: dashboard.stats[0].min_price.map((data) => data._id),
		datasets: [
			{
				label: dashboard.data.data,
				data,
				backgroundColor: ["#7791ea", "#fd9769", "#bbc8f5", "#fecbb4"],
			},
		],
	};
	return (
		<Pie
			data={info}
			options={{
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						position: "bottom",
					},
				},
			}}
		/>
	);
}
