import {
	MODIFICAR_MI_CUENTA_BASE_DE_DATOS,
	MODIFICAR_MI_CUENTA_BASE_DE_DATOS_EXITO,
	MODIFICAR_MI_CUENTA_BASE_DE_DATOS_ERROR,
	RESETEAR_ESTADO_REDUX_MI_CUENTA,
} from "../../types/index";

const initialState = {
	cuentas: {},
	msj: "",
	pass: false,
	loading: false,
	error: false,
};
export default function reducerCuentas(state = initialState, action) {
	switch (action.type) {
		case MODIFICAR_MI_CUENTA_BASE_DE_DATOS:
			return {
				...state,
				loading: true,
				error: false,
			};
		case RESETEAR_ESTADO_REDUX_MI_CUENTA:
			return {
				...state,
				msj: "",
				loading: false,
				error: false,
			};
		case MODIFICAR_MI_CUENTA_BASE_DE_DATOS_ERROR:
			return {
				...state,
				msj: action.payload.response.data.msj,
				loading: false,
				error: true,
			};
		case MODIFICAR_MI_CUENTA_BASE_DE_DATOS_EXITO:
			return {
				...state,
				msj: action.payload.msj,
				loading: false,
				error: null,
			};
		default:
			return state;
	}
}
