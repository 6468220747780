import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deleteExcelHistory, getExcelHistory } from "../../../redux/actions/utilities/excelLoadAction";

import SearchIcon from "@mui/icons-material/Search";

import iconTrash from "../../../assets/svg/functionalities-icons/trash-can.svg";
import iconArrow_gray from "../../../assets/svg/functionalities-icons/arrow_gray.svg";

import SelectCategory from '../../utilities/SelectCategory'

import "../cargaPlanillas.css";
import ConfirmationModal from "../../utilities/Modal/ConfirmationModal";

const SpreadsheetHistory = () => {
	const dispatch = useDispatch();
	const excelHistory = useSelector((state) => state.excel.excelHistory);

	const [closeSection, setCloseSection] = useState(false)

	const [searchFilter, setSearchFilter] = useState("")
	const [showConfirmationModal, setShowConfirmationModal] = useState(false)
	const [excelToDelete, setExcelToDelete] = useState(undefined)

	const [openSelect, setOpenSelect] = useState(false);
	const [nameSelectCategory, setNameSelectCategory] = useState("Todas las categorias");
	const [indexSelectCategory, setIndexSelectCategory] = useState(null);

	useEffect(() => {
		dispatch(getExcelHistory());
	}, []);

	const handleCategorySelect = (elementProduct) => {
		if (elementProduct.tipoProducto == "allCategories") {
			setIndexSelectCategory(null);
		} else {
			setIndexSelectCategory(elementProduct.tipoProducto);
		}
		setNameSelectCategory(elementProduct.name);
		setOpenSelect(false);
	};

	const deleteExcelFromHistory = (excel) => {
		dispatch(deleteExcelHistory(excel));
	};

	return (
		<div className="cuentas-contenedor spreadSheetHistory-container">
			<div className="cuentas-info_cuentas-row" onClick={() => { setCloseSection(!closeSection) }}>
				<h2>Historial de planillas</h2>
				<img src={iconArrow_gray} style={!closeSection ? { rotate: "180deg" } : {}} />
			</div>
			<hr className="cuentas-info_cuentas-hr" />
			<div className={closeSection ? "d-none" : undefined}>
				<div className="spreadSheetHistory-filters">
					<div className="cuentas-cuentas_usuario-search_cont" style={{ width: "300px" }}>
						<input
							placeholder="Actor de mercado o indice..."
							autoComplete="off"
							name="email"
							onChange={(e) => {
								setSearchFilter(e.target.value.toLowerCase());
							}}
						/>
						<SearchIcon />
					</div>
					<div>
						<span className="cuentas-cuentas_usuario-search_cont spreadSheetHistory-filter"
							onClick={() => {
								setOpenSelect(!openSelect);
							}}
						>
							{nameSelectCategory}
							<img src={iconArrow_gray} style={!openSelect ? { rotate: "180deg" } : {}} alt="open" />
						</span>
						<SelectCategory allCategories={true} openSelect={openSelect} handleCategorySelect={handleCategorySelect} othersCategory={true} />
					</div>
				</div>
				<div className="spreadSheetHistory-history">
					{excelHistory.map((excel) => {
						if (!searchFilter && !indexSelectCategory)
							return (
								<section key={excel._id}>
									<div>
										<h5>{new Date(excel.timestamp).toLocaleDateString()}</h5>
										<h4>
											{excel.company} - {excel.product.type}
										</h4>
									</div>
									<img
										src={iconTrash}
										type="button"
										alt="delete"
										onClick={() => {
											setExcelToDelete(excel);
											setShowConfirmationModal(true);
										}}
									/>
								</section>
							);
						else if (((excel.company.toLowerCase().includes(searchFilter) || excel.product.type.toLowerCase().includes(searchFilter)))
							&& (!indexSelectCategory || indexSelectCategory == excel.product.index))
							return (
								<section key={excel._id}>
									<div>
										<h5>{new Date(excel.timestamp).toLocaleDateString()}</h5>
										<h4>
											{excel.company} - {excel.product.type}
										</h4>
									</div>
									<img
										src={iconTrash}
										type="button"
										alt="delete"
										onClick={() => {
											setExcelToDelete(excel);
											setShowConfirmationModal(true);
										}}
									/>
								</section>
							);
					})}
				</div>
			</div>
			{showConfirmationModal && <ConfirmationModal
				setShowConfirmationModal={setShowConfirmationModal}
				handlerShowConfirmationModal={() => {
					deleteExcelFromHistory(excelToDelete);
					setShowConfirmationModal(false);
				}}
				titleText="Eliminar planilla"
				descriptionText={`¿Está seguro que desea eliminar esta planilla de "${excelToDelete.company}"?`}
			/>}
		</div>
	);
};

export default SpreadsheetHistory;
