import { Navigate, Outlet } from "react-router"
import universlaCookie from "universal-cookie";

const PrivateRoutes = () => {
	const cookie = new universlaCookie();
    const token = cookie.get("token");
    return(
        token ? <Outlet/> : <Navigate to="/"/>
    )
}

export default PrivateRoutes