import React, { useState } from "react";

import { Modal, Box } from "@mui/material";
import { modalBoxStyle } from "../../utilities/utilities";

import Checkbox from "@mui/material/Checkbox";
import useMediaQuery from "@mui/material/useMediaQuery";
import iconoMenos from "../../../assets/svg/functionalities-icons/minus_black.svg";
import iconoMas from "../../../assets/svg/functionalities-icons/plus_black.svg";
import iconoCerrarModal from "../../../assets/svg/functionalities-icons/cross_gray.svg";

import "./filtrosItems.css";

export default function FiltroTarjeta({ filtro, cambiarFiltros }) {
	const [expandido, setExpandido] = useState(false);

	const matches = useMediaQuery("(min-width:744px)");

	const cargarChack = (e) => {
		let nuevoCheck = filtro.labels.map((filtroCheck) => {
			if (filtroCheck.id === e.target.id) {
				if (filtroCheck.check) {
					return { ...filtroCheck, check: false };
				} else {
					return { ...filtroCheck, check: true };
				}
			}
			return filtroCheck;
		});
		cambiarFiltros({ ...filtro, labels: nuevoCheck });
	};

	return (
		<div className="filtros-tarjeta-filtro">
			<div type="button" onClick={() => setExpandido(!expandido)}>
				<h5>{filtro.name}</h5>
				<img src={expandido ? iconoMenos : iconoMas} alt="card head" />
			</div>
			{expandido && (
				<section>
					{filtro.labels.map((check, position) => {
						return (
							<div key={position}>
								<Checkbox checked={check.check} id={check.id} onClick={cargarChack} />
								<p>{check.value}</p>
							</div>
						);
					})}
				</section>
			)}
			<Modal className="filtros-tarjeta-opciones-modal" open={expandido && !matches} onClose={() => setExpandido(false)}>
				<Box sx={modalBoxStyle}>
					<img src={iconoCerrarModal} className="modales-cerrar_modal-img" alt="close" onClick={() => setExpandido(false)} />
					{filtro.labels.map((check, position) => {
						return (
							<section key={position}>
								<Checkbox checked={check.check} id={check.id} onClick={cargarChack} />
								<p>{check.value}</p>
							</section>
						);
					})}
				</Box>
			</Modal>
		</div>
	);
}
