import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { hideNavBar } from "../../redux/actions/utilities/navbarAction";
import { logInUser } from "../../redux/actions/registrosUsuarios/logInUserAction";

import cryptoJs from "crypto-js";
import universlaCookie from "universal-cookie";
import config from "../../config/config";

// import ilustracionLogin from "../../assets/svg/trendit-images/logo_trendit.svg";
import logoAxonScopeVertical from "../../assets/svg/axonscope/axonscope_vertical.svg";
// import ilustracionReforestar from "../../assets/svg/trendit-images/logo_reforestar.svg";
// import ilustracionCertificado from "../../assets/svg/trendit-images/logo_certificado.svg";

import ModalModificarContrasenia from "./ModalModificarContrasenia";
import ModalRecoverPassword from "./ModalRecoverPassword"

import "./login.css";

const Login = () => {
	const dispatch = useDispatch();
	const navegation = useNavigate();
	const cookie = new universlaCookie();

	const error = useSelector((state) => state.users.error);
	const claveProvisoria = useSelector((state) => state.users.claveProvisoria);

	const [showModalRecoverPassword, setShowModalRecoverPassword] = useState(false);

	const [emailInput, setEmailInput] = useState(cookie.get("user") || "");
	const [passwordSignIn, setPasswordSignIn] = useState(cookie.get("password") || "");
	const [messageLogin, setMessageLogin] = useState(false);

	const [loginMessage, setLoginMessage] = useState(undefined);

	useEffect(() => {
		dispatch(hideNavBar());
	}, []);

	useEffect(() => {
		if (error) setMessageLogin(true);
	}, [error]);

	const handleSubmitSignIn = (e) => {
		e.preventDefault();
		dispatch(logInUser(encriptar(emailInput.toLowerCase()), encriptar(passwordSignIn)));
		setTimeout(() => {
			handleLogInStatus()
		}, 400);
	};

	const handleLogInStatus = () => {
		if (localStorage.getItem("LogInStatus") === "true") navegation("/Dashboard");
	}

	function encriptar(string) {
		const secretKey = config.secretKey;
		return cryptoJs.AES.encrypt(string, secretKey).toString();
	}

	const handleClick = (message) => {
		setLoginMessage(message);
	};

	const handleSpace = (e) => {
		if (e.nativeEvent.data !== " ") setPasswordSignIn(e.target.value);
		setMessageLogin(false);
	};

	const handleEmailInput = (event) => {
		if (event.nativeEvent.data !== " ") setEmailInput(event.target.value);
		setMessageLogin(false);
	}

	return (
		<div className="cuentas-contenedor login-container">
			<div className="login-contenedor-inputs">
				<h3 className="login-titulo">¡Bienvenido!</h3>
				<div className="login-contenedor_imagen">
					<img className="login-imagen" src={logoAxonScopeVertical} alt="login" />
					{/* <div className="login-logos_menores">
						<img src={ilustracionCertificado} alt="certificado" />
						<div className="linea-separadora-signIn" />
						<img src={ilustracionReforestar} alt="reforestar" />
					</div> */}
				</div>
				<div className="linea-separadora-signIn" />
				<div className="login-formulario-contenedor">
					<h3 className="login-titulo">¡Bienvenido!</h3>
					{loginMessage && <h5>{loginMessage}</h5>}
					<form onSubmit={handleSubmitSignIn} autoComplete="off">
						{messageLogin && <p>Email o contraseña incorrecta</p>}
						<input
							autoComplete="username"
							name="input username"
							onChange={(e) => { handleEmailInput(e) }}
							placeholder="Email *"
							required
							value={emailInput}
						/>
						<input
							autoComplete="current-password"
							name="input password"
							minLength={8}
							maxLength={12}
							onChange={(e) => handleSpace(e)}
							placeholder="Contraseña *"
							required
							type="password"
							value={passwordSignIn}
						/>
						<div className="login-lable_contenedor">
							<h4 type="button" onClick={() => setShowModalRecoverPassword(true)}>
								¿Olvidaste tu contraseña?
							</h4>
						</div>
						<button>INICIAR SESIÓN</button>
					</form>
				</div>
			</div>
			{showModalRecoverPassword && <ModalRecoverPassword setShowModalRecoverPassword={setShowModalRecoverPassword} handleClick={handleClick} />}
			{claveProvisoria && <ModalModificarContrasenia emailInput={emailInput} />}
		</div>
	);
};
export default Login;