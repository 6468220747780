import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Accordion } from '@mantine/core';

import "./productRecommendation.css";
import "../recommendations.css"

export default function ProductRecommendation({ actualPercentage }) {
	const dollar = useSelector((state) => state.dollar.dollar);
	const userRol = useSelector((state) => state.users.user.role);
	const currency = useSelector((state) => state.products.currency);
	const isSupplier = useSelector((state) => state.products.isSupplier); // TODO -> usar el reducer de products directamente
	const suggested = useSelector((state) => state.products.suggested);

	const [dollarPrice, setDollarPrice] = useState(dollar);
	const [openSection, setOpenSection] = useState(false)

	useEffect(() => {
		setDollarPrice(dollar);
	}, [dollar])

	const precioConIva = (precioSinIva, iva) => {
		return precioSinIva + precioSinIva * (iva / 100);
	};

	const formatearNumero = (valor) => {
		if (dollarPrice && currency && suggested && suggested.precio.tipoMoneda) {
			if (currency === "ARS" && suggested.precio.tipoMoneda === "USD") {
				valor = valor * dollarPrice?.ventaBNA;
				valor = valor.toFixed(2);
			}
			if (currency === "USD" && suggested && suggested.precio.tipoMoneda === "ARS") {
				valor = valor / dollarPrice?.ventaBNA;
				valor = valor.toFixed(2);
			}
		}

		return new Intl.NumberFormat("de-DE").format((valor * 1).toFixed(2));
	};

	return (
		<Accordion variant="contained" className="recommendation-container" defaultValue="productRecommendations-container">
			<Accordion.Item key={1} value="productRecommendations-container">
				<Accordion.Control>
					<div className="productRecommendation-container-circle"
						style={isSupplier ? { backgroundColor: "var(--color-mediano-competencia)" } : { backgroundColor: "var(--color-mediano-proveedor)" }}>
					</div>
					<span>{isSupplier ? "Principal competencia" : "Proveedor recomendado"}</span>
				</Accordion.Control>
				<Accordion.Panel>
					<div className="productRecommendation-container">
						{suggested ? (
							<div className={openSection ? "d-none" : ""}>
								<h3>{suggested.informacionProducto.titulo.toUpperCase()}</h3>
								<div>
									<h5>{isSupplier ? "Precio contado" : userRol !== "user" ? "Precio" : "Precio de venta"}</h5>
									<h6>
										{currency}{" "}
										{userRol === "user"
											? formatearNumero(
												suggested.precio.precioContado * 1 +
												suggested.precio.precioContado * 0.01 * actualPercentage
											)
											: formatearNumero(suggested.precio.precioContado * 1)}
									</h6>
								</div>
								<div>
									<h5>{isSupplier ? "" : "IVA"}</h5>
									<h6>{isSupplier ? "" : suggested.precio.ivaProducto.toFixed(2) + "%"}</h6>
								</div>
								<div>
									<h5>{isSupplier ? "Precio lista" : userRol !== "user" ? "Precio con IVA" : "Precio venta con IVA"}</h5>
									<h6>
										{currency + " "}
										{isSupplier
											? formatearNumero(suggested.precio.precioLista)
											: userRol !== "user"
												? formatearNumero(
													precioConIva(
														suggested.precio.precioContado,
														suggested.precio.ivaProducto
													).toFixed(2)
												)
												: formatearNumero(
													precioConIva(
														suggested.precio.precioContado * 1 +
														suggested.precio.precioContado * 0.01 * actualPercentage,
														suggested.precio.ivaProducto
													).toFixed(2)
												)}
									</h6>
								</div>
								<div>
									{userRol !== "user" && <h4>{suggested.empresa.nombre}</h4>}
									{suggested.informacionProducto.marca && <h4>{suggested.informacionProducto.marca}</h4>}
									{suggested.informacionProducto.partNumber && <h4>{suggested.informacionProducto.partNumber?.toUpperCase()}</h4>}
								</div>
							</div>
						) : (
							<div className={openSection ? "d-none" : ""}>
								<span>Sin resultados</span>
							</div>
						)}
					</div>
				</Accordion.Panel>
			</Accordion.Item>
		</Accordion>
	);
}
