import {
	COMENZAR_CREACION_FILTRO,
	COMENZAR_CREACION_FILTRO_EXITO,
	COMENZAR_CREACION_FILTRO_ERROR,
	COMENZAR_DESCARGA_FILTRO,
	COMENZAR_DESCARGA_FILTRO_EXITO,
	COMENZAR_DESCARGA_FILTRO_ERROR,
	COMENZAR_EDICION_FILTRO,
	COMENZAR_EDICION_FILTRO_EXITO,
	COMENZAR_EDICION_FILTRO_ERROR,
	LIMPIAR_FILTROS,
} from "../../types";

const initialState = {
	filtros: [],
	idFiltroCreado: null,
	loading: null,
	error: null,
};

export default function reduceFiltros(state = initialState, action) {
	switch (action.type) {
		case LIMPIAR_FILTROS:
			return {
				...state,
				filtros: [],
			};
		case COMENZAR_CREACION_FILTRO:
		case COMENZAR_EDICION_FILTRO:
		case COMENZAR_DESCARGA_FILTRO:
			return {
				...state,
				loading: true,
			};
		case COMENZAR_CREACION_FILTRO_ERROR:
		case COMENZAR_EDICION_FILTRO_ERROR:
			return {
				...state,
				loading: false,
				error: true,
			};
		case COMENZAR_CREACION_FILTRO_EXITO:
			return {
				...state,
				error: null,
				loading: false,
				idFiltroCreado: action.payload,
			};
		case COMENZAR_DESCARGA_FILTRO_ERROR:
			return {
				...state,
				loading: false,
				error: true,
				filtros: [],
			};
		case COMENZAR_DESCARGA_FILTRO_EXITO:
			return {
				...state,
				error: null,
				loading: false,
				filtros: action.payload,
			};
		case COMENZAR_EDICION_FILTRO_EXITO:
			return {
				...state,
				error: null,
				loading: false,
			};
		default:
			return state;
	}
}
