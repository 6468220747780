import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addMarketPlayer, getMarketPlayers } from "../../../redux/actions/cuentas/marketPlayerActions";

import { Modal, Box } from "@mui/material";

import StatusModal from "../../utilities/Modal/StatusModal";

import iconoCerrarModal from "../../../assets/svg/functionalities-icons/cross_gray.svg";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "300px",
	height: "auto",
	bgcolor: "#ffffff",
	borderRadius: "10px",
	boxShadow: 24,
	disableAutoFocus: "true",
	outline: "none",
};

export default function ModalAddMarketPlayer({ isSupplier, setAddMarketPlayerModal }) {
	const dispatch = useDispatch();

	const marketPlayerMessage = useSelector((state) => state.marketPlayer.message);
	const loading = useSelector((state) => state.marketPlayer.loading);
	const error = useSelector((state) => state.marketPlayer.error);

	const [input, setInput] = useState("");

	const [showStatusModal, setShowStatusModal] = useState(null);
	const [msjConfirmacion, setMsjConfirmacion] = useState("");

	const handleAddMarketPlayer = () => {
		dispatch(addMarketPlayer(isSupplier, input));
		setShowStatusModal(true);
	};

	useEffect(() => {
		if (loading) {
			setMsjConfirmacion("Verificando información...");
		} else if (marketPlayerMessage) {
			setMsjConfirmacion(marketPlayerMessage);
		}
	}, [loading, marketPlayerMessage]);

	return (
		<Modal
			open={true}
			onClose={() => {
				setAddMarketPlayerModal(false);
			}}
			style={{ backdropFilter: "blur(4px)" }}
		>
			<Box sx={style}>
				<div className="modales-contenedor_texto-div addMarketPlayerModal-container">
					<h4 className="modales-titulo_modal">Crear</h4>
					<img
						src={iconoCerrarModal}
						className="modales-cerrar_modal-img"
						alt="delete"
						onClick={() => {
							setAddMarketPlayerModal(false);
						}}
					/>
					<section>
						<h5 className="modales-texto_modal">Nombre: </h5>
						<input type="text" value={input} onChange={(e) => setInput(e.target.value)} />
					</section>
					<button className="modales-modal_editar-boton modales-modal_editar-btn_editar" onClick={handleAddMarketPlayer}>
						Crear {isSupplier ? "proveedor" : "competencia"}
					</button>
				</div>
				{(marketPlayerMessage || loading) && showStatusModal && (
					<StatusModal loading={loading} error={error} modalStatusCloseAction={setShowStatusModal}>
						{msjConfirmacion}
					</StatusModal>
				)}
			</Box>
		</Modal>
	);
}
