import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNavbarCategoryMessage } from "../../../redux/actions/utilities/navbarAction";

import addIcon from "../../../assets/svg/functionalities-icons/plus_blue.svg";
import iconArrow_gray from "../../../assets/svg/functionalities-icons/arrow_gray.svg";

import CategoryModal from "./modales/CategoryModal";
import StatusModal from "../../utilities/Modal/StatusModal";

import "./categories.css";

const Categories = () => {
	const dispatch = new useDispatch();

	const navbarCategories = useSelector((state) => state.navbar);

	const [closeSection, setCloseSection] = useState(false)

	const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
	const [showStatusModal, setShowStatusModal] = useState(false);

	const [existingCategory, setExistingCategory] = useState(null)

	const handleAddNewCategory = () => {
		setExistingCategory(null)
		setShowAddCategoryModal(true);
	};

	const handleEditCategory = (category) => {
		setExistingCategory(category)
		setShowAddCategoryModal(true);
	}

	useEffect(() => {
		dispatch(setNavbarCategoryMessage(""))
		setShowStatusModal(false)
	}, [])

	useEffect(() => {
		if (navbarCategories.message !== "") setShowStatusModal(true)
		else setShowStatusModal(false)
	}, [navbarCategories])

	return (
		<div className="cuentas-contenedor">
			<div className="cuentas-info_cuentas-row" onClick={() => { setCloseSection(!closeSection) }}>
				<h2>Categorizacion de items</h2>
				<img src={iconArrow_gray} style={!closeSection ? { rotate: "180deg" } : {}} />
			</div>
			<hr className="cuentas-info_cuentas-hr" />
			<div className={`categories-container${closeSection ? " d-none" : ""}`}>
				{navbarCategories.categories.map((category) => {
					return <div type="button" onClick={() => handleEditCategory(category)} key={category._id}>
						<h4>{category.name}</h4>
						<img src={category.images.phone} />
					</div>
				})}
				<div type="button" onClick={handleAddNewCategory}>
					<img alt="new" src={addIcon} />
				</div>
			</div>
			{showAddCategoryModal && <CategoryModal setShowAddCategoryModal={setShowAddCategoryModal} existingCategory={existingCategory} />}
			{showStatusModal && (
				<StatusModal loading={navbarCategories.loading} error={navbarCategories.error} setModalStatusCloseAction={setShowStatusModal}>
					{navbarCategories.message}
				</StatusModal>
			)}
		</div>
	);
};

export default Categories;
