import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchIcon from "@mui/icons-material/Search";

import { actionMarketPlayerRestart, getMarketPlayers } from "../../redux/actions/cuentas/marketPlayerActions";
import { getDatosUsers } from "../../redux/actions/registrosUsuarios/logInUserAction";

import ModalAddMarketPlayer from "./modales/ModalAddMarketPlayer";
import CardMarketPlayer from "./CardMarketPlayer";

import iconoMenu from "../../assets/svg/functionalities-icons/menu.svg";
import iconAddNew from "../../assets/svg/functionalities-icons/plus_blue.svg";
import iconArrow_gray from "../../assets/svg/functionalities-icons/arrow_gray.svg";

import "./marketPlayers.css";
import StatusModal from "../utilities/Modal/StatusModal";

const MarketPlayers = () => {
	const dispatch = useDispatch();

	const userAccess = useSelector((state) => state.users.user.role.access)
	const marketPlayers = useSelector((state) => state.marketPlayer.marketPlayers);
	const stateMarketPlayer = useSelector((state) => state.marketPlayer)
	const user = useSelector((state) => state.users);

	const [closeSection, setCloseSection] = useState(true)
	const [closeSection2, setCloseSection2] = useState(true)
	const [accessToAdministration, setAccessToAdministration] = useState(false)
	const [cuentaBusqueda, setCuentaBusqueda] = useState("");
	const [addMarketPlayerModal, setAddMarketPlayerModal] = useState(false);
	const [addMPisSupplier, setAddMPisSupplier] = useState(false);

	const [showStatusModal, setShowStatusModal] = useState(false)

	useEffect(() => {
		dispatch(actionMarketPlayerRestart())
		dispatch(getMarketPlayers());
		if (user.user) {
			dispatch(getDatosUsers());
		}
		setShowStatusModal(false)
	}, []);

	useEffect(() => {
		if (stateMarketPlayer.message !== "") {
			setShowStatusModal(true)
			setAddMarketPlayerModal(false)
		}
	}, [stateMarketPlayer.loading, stateMarketPlayer.error])

	useEffect(() => {
		if (!showStatusModal &&
			stateMarketPlayer.error &&
			(stateMarketPlayer.message === "No se pudo cargar el actor de mercado"
				|| stateMarketPlayer.message === "Ya existe un actor de mercado con este nombre")) {
			setAddMarketPlayerModal(true)
		}
	}, [showStatusModal, stateMarketPlayer.error])

	const handleNoSumbit = (event) => {
		event.preventDefault();
	};

	const handleAddMarketPlayerModal = (isSupplier) => {
		setAddMarketPlayerModal(true);
		setAddMPisSupplier(isSupplier);
	};

	return (
		<div className="cuentas-contenedor">
			<div className="cuentas-info_cuentas-contra_row">
				<h2>Actores de mercado</h2>
				{userAccess !== "user" && !accessToAdministration && <img
					src={iconoMenu}
					alt="menu"
					onClick={() => setAccessToAdministration(true)}
					className="cuentas-info_cuentas-img_menu"
				/>}
			</div>
			<div className="cuentas-info_cuentas-contra_row" style={{ justifyContent: "start", gap: "20px" }}>
				<div className="cuentas-info_cuentas-options">
					<form autoComplete="off" onSubmit={handleNoSumbit}>
						<div className="cuentas-cuentas_usuario-search_cont">
							<input
								autoComplete="off"
								name="email or user"
								onChange={(e) => {
									setCuentaBusqueda(e.target.value.toLowerCase());
								}}
								placeholder="Nombre..."
							/>
							<SearchIcon />
						</div>
					</form>
				</div>
				<div className="cuentas-admin_cuentas-admin_display_grande">
					{userAccess !== "user" && !accessToAdministration && <button
						className="cuentas-admin_cuentas-admin_cuentas"
						onClick={() => setAccessToAdministration(true)}
					>
						Administrar actores de mercado
					</button>}
				</div>
			</div>
			<div className="cuentas-info_cuentas-contra_row" onClick={() => { setCloseSection(!closeSection) }}>
				<h2>Proveedores</h2>
				<img src={iconArrow_gray} style={!closeSection ? { rotate: "180deg" } : {}} />
			</div>
			<hr />
			<div className={closeSection ? "cuentas-admin_cuentas-contenedor_cards" : "d-none"} >
				{marketPlayers &&
					marketPlayers.length > 0 &&
					marketPlayers.map((marketPlayer) => {
						if (
							(marketPlayer.company.name.toLowerCase().includes(cuentaBusqueda.toLowerCase()) ||
								(marketPlayer.credentials.username &&
									marketPlayer.credentials.username.toLowerCase().includes(cuentaBusqueda.toLowerCase()))) &&
							marketPlayer.company.isSupplier
						) {
							return (
								<CardMarketPlayer
									permisoAdministrador={accessToAdministration}
									key={marketPlayer._id}
									marketPlayer={marketPlayer}
									index={marketPlayer._id}
								/>
							);
						}
					})}
				{accessToAdministration && (
					<div className="cardMarketPlayer-container supplier-addNew-container" onClick={() => handleAddMarketPlayerModal(true)}>
						<img src={iconAddNew} />
					</div>
				)}
			</div>
			<div className="cuentas-info_cuentas-contra_row" onClick={() => { setCloseSection2(!closeSection2) }} style={{ marginTop: "40px" }}>
				<h2>Competencias</h2>
				<img src={iconArrow_gray} style={!closeSection2 ? { rotate: "180deg" } : {}} />
			</div>
			<hr />
			<div className={closeSection2 ? "cuentas-admin_cuentas-contenedor_cards" : "d-none"}>
				{marketPlayers &&
					marketPlayers.length > 0 &&
					marketPlayers.map((marketPlayer) => {
						if (
							(marketPlayer.company.name.toLowerCase().includes(cuentaBusqueda.toLowerCase()) ||
								(marketPlayer.credentials.username &&
									marketPlayer.credentials.username.toLowerCase().includes(cuentaBusqueda.toLowerCase()))) &&
							!marketPlayer.company.isSupplier
						) {
							return (
								<CardMarketPlayer
									permisoAdministrador={accessToAdministration}
									key={marketPlayer._id}
									marketPlayer={marketPlayer}
									index={marketPlayer._id}
								/>
							);
						}
					})}
				{accessToAdministration && (
					<div className="cardMarketPlayer-container supplier-addNew-container" onClick={() => handleAddMarketPlayerModal(false)}>
						<img src={iconAddNew} />
					</div>
				)}
			</div>
			{addMarketPlayerModal && <ModalAddMarketPlayer isSupplier={addMPisSupplier} setAddMarketPlayerModal={setAddMarketPlayerModal} />}
			{showStatusModal && (
				<StatusModal loading={stateMarketPlayer.loading} error={stateMarketPlayer.error} setModalStatusCloseAction={setShowStatusModal}>
					{stateMarketPlayer.loading
						? "Verificando informacion..."
						: stateMarketPlayer.message}
				</StatusModal>
			)}
		</div>
	);
};

export default MarketPlayers;
