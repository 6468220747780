import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Accordion } from '@mantine/core';

import "./dollarCard.css";
import "../recommendations.css"

export const DollarCard = () => {
	const dollar = useSelector((state) => state.dollar.dollar);

	const [dollarPrice, setDollarPrice] = useState(dollar);

	useEffect(() => {
		setDollarPrice(dollar);
	}, [dollar])

	return (
		dollarPrice && (
			<Accordion variant="contained" className="recommendation-container" defaultValue="dollarCard-container">
				<Accordion.Item key={1} value="dollarCard-container">
					<Accordion.Control>
						<div className="dollarCard-circle"></div>
						<span>Valores Dolar</span>
					</Accordion.Control>
					<Accordion.Panel>
						<div className="dollarCard">
							<section >
								<h5>Dolar BNA</h5>
								<div>
									<p>Compra</p>
									<h5>${dollarPrice?.compraBNA}</h5>
								</div>
								<div>
									<p>Venta</p>
									<h5>${dollarPrice?.ventaBNA}</h5>
								</div>
							</section>
							<section>
								<h5>Dolar Bolsa</h5>
								<div>
									<p>Compra</p>
									<h5>${dollarPrice?.compraBolsa}</h5>
								</div>
								<div>
									<p>Venta</p>
									<h5>${dollarPrice?.ventaBolsa}</h5>
								</div>
							</section>
						</div>
					</Accordion.Panel>
				</Accordion.Item>
			</Accordion>
		)
	);
};
