import React, { useState, useEffect } from "react";
import { Routes, Route, HashRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { v4 as uuidv4 } from "uuid";

import { resetearEstadoErrorUser } from "../redux/actions/registrosUsuarios/logInUserAction";
import PrivateNoAdminRoutes from "../redux/types/utilities/PrivateNoAdminRoutes.js";
import PrivateRoutes from "../redux/types/utilities/PrivateRoutes.js";

import Home from "../components/home/Home.js";
import Navbar from "../components/navbar/Navbar";
import Login from "../components/login/Login";
import MiCuenta from "../components/configuracionCuenta/MiCuenta";
import NotFound from "../components/notFound/notFound.js";
import SelectedProduct from "../components/products/selectedProduct/SelectedProduct.js";
import MarketPlayers from "../components/marketPlayers/MarketPlayers.js";
import CargaPlanillas from "../components/cargaPlanillas/CargaPlanillas.js";
import Quotation from "../components/quotation/Quotation";
import Products from "../components/products/Products";
import Categorization from "../components/categorization/Categorization.js";
import { IndexSearch } from "../components/marketPlayers/indexSearch/IndexSearch.js";
import { Commercialization } from "../components/commercialization/Commercialization.js";

export default function App() {
	const navbar = useSelector((state) => state.navbar);
	const errorUser = useSelector((state) => state.users);
	const navbarCategories = useSelector((state) => state.navbar.categories);

	const [logInStatus, setLogInStatus] = useState(localStorage.getItem("LogInStatus") === "true");

	const dispatch = useDispatch();

	useEffect(() => {
		if (errorUser.error) {
			setLogInStatus(false);
			localStorage.removeItem("user");
			localStorage.setItem("LogInStatus", false);
			dispatch(resetearEstadoErrorUser());
		}
	}, [errorUser.error]);

	useEffect(() => {
		if (errorUser.login) {
			setLogInStatus(true);
		}
	}, [errorUser.login]);

	return (
		<HashRouter>
			<div>
				{logInStatus && navbar.show && <Navbar />}
				<Routes>
					<Route path="/" element={<Login />} />
					<Route element={<PrivateRoutes />}>
						<Route path="/Dashboard" element={<Home />} />
						{navbarCategories?.map((category) => {
							return category.subcategories.map((subcategory) => {
								return subcategory.indices.map((index) => {
									const completeIndex = `${category.name.toLowerCase()}-${subcategory.name.toLowerCase()}-${index.name.replace(/ /g, "_").toLowerCase()}`;
									return (<Route key={uuidv4()} path={`/${completeIndex}`} element={<Products key={uuidv4()} indexID={index._id} indexPath={completeIndex} />} />);
								});
							});
						})}
						<Route path="/allProducts" element={<Products key={uuidv4()} indexID="allProducts" indexPath="allProducts" />} />
						<Route path="/Cuentas" element={<MiCuenta />} />
						<Route path="/Cotizaciones" element={<Quotation />} />
						<Route path="/Producto" element={<SelectedProduct />} />
						<Route element={<PrivateNoAdminRoutes />}>
							<Route path="/CargaPlanillas" element={<CargaPlanillas />} />
							<Route path="/ActoresDeMercado" element={<MarketPlayers />} />
							<Route path="/Categorizacion" element={<Categorization />} />
							<Route path="/BuscadorIndex" element={<IndexSearch />} />
							<Route path="/Comercializacion" element={<Commercialization />} />
						</Route>
					</Route>
					<Route path="/*" element={<NotFound />} />
				</Routes>
			</div>
		</HashRouter>
	);
}
