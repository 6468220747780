import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import cuentasUsersReducer from "./reducers/cuentas/cuentasUsersReducer";
import commercialConditionReducer from "./reducers/utilities/commercialConditionReducer";
import dashboardReducer from "./reducers/dashboard/dashboardReducer";
import dollarReducer from "./reducers/utilities/dollarReducer";
import excelReducer from "./reducers/utilities/excelLoadReducer";
import filtrosReducer from "./reducers/productos/filtrosReducer";
import loginReducer from "./reducers/registrosUsuarios/logInUserReducer";
import miCuentaUserReducer from "./reducers/cuentas/miCuentaUserReducer";
import marketPlayerReducer from "./reducers/cuentas/marketPlayerReducer";
import navbarReducer from "./reducers/utilities/navbarReducer";
import priceListReducer from "./reducers/utilities/priceListReducer";
import quotationReducer from "./reducers/utilities/quotationReducer";
import siginReducer from "./reducers/registrosUsuarios/signInUserReducer";
import productsReducer from "./reducers/utilities/productsReducer";
import productReducer from "./reducers/utilities/productReducer";

const reducer = combineReducers({
	commercialCondition: commercialConditionReducer,
	cuentas: cuentasUsersReducer,
	dollar: dollarReducer,
	excel: excelReducer,
	filtros: filtrosReducer,
	miCuenta: miCuentaUserReducer,
	marketPlayer: marketPlayerReducer,
	navbar: navbarReducer,
	priceList: priceListReducer,
	product: productReducer,
	products: productsReducer,
	quotation: quotationReducer,
	sigIn: siginReducer,
	dashboard: dashboardReducer,
	users: loginReducer,
});

const store = createStore(
	reducer,
	compose(
		applyMiddleware(thunk),
		typeof window === "object" && typeof window._REDUX_DEVTOOLS_EXTENSION_ !== "undefined" ? window._REDUX_DEVTOOLS_EXTENSION_() : (f) => f
		// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
	)
);

export default store;
