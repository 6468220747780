import {
    GET_COMMERCIAL_CONDITION_ERROR,
    GET_COMMERCIAL_CONDITION_SUCCESS,
    SET_COMMERCIAL_CONDITION_MESSAGE,
    START_GET_COMMERCIAL_CONDITION,
} from "../../types/index";

const initialState = {
    commercialConditions: [],
    loading: false,
    error: false,
    message: "",
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_COMMERCIAL_CONDITION_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_COMMERCIAL_CONDITION_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                message: "",
                commercialConditions: action.payload,
            };
        case SET_COMMERCIAL_CONDITION_MESSAGE:
            return {
                ...state,
                message: action.payload,
            }
        case START_GET_COMMERCIAL_CONDITION:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
}
