import {
	ACTION_PRODUCT_START,
	ACTION_PRODUCT_ERROR,
	GET_PRODUCT_SUCCESS,
} from "../../types/index";

import clienteAxios from "../../../Axios";
import universalCookie from "universal-cookie";

const cookie = new universalCookie();

export function getProduct(productID) {
	return async (dispatch) => {
		dispatch(actionProductStart());
		try {
			const response = await clienteAxios({
				method: "GET",
				url: `product?id=${productID}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(getProductSuccess(response.data.response));
		} catch (e) {
			dispatch(actionProductError());
		}
	};
}

const actionProductStart = () => ({
	type: ACTION_PRODUCT_START,
});

const actionProductError = () => ({
	type: ACTION_PRODUCT_ERROR,
});

const getProductSuccess = (payload) => ({
	type: GET_PRODUCT_SUCCESS,
	payload: payload,
});

