import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";
import { Menu, MenuItem } from "@szhsin/react-menu";
import ListItemButton from "@mui/material/ListItemButton";
import { makeStyles } from "mui-styles";
import MuiAlert from "@mui/material/Alert";

import { postQuotation } from "../../../redux/actions/utilities/quotationAction";

import noImage from "../../../assets/svg/system-images/producto-sin-imagen.svg";
import addQuotationItem from "../../../assets/svg/functionalities-icons/add-quotation-item.svg";
import copyItem from "../../../assets/svg/functionalities-icons/copy-item.svg";

import "./productCard.css";

const useStyles = makeStyles({
	blockcontainer: {
		display: "flex",
		justifyContent: "flex-end",
		margin: "0 30px 0 0",
		padding: 0,
	},
});

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ProductCard({ item, selectedPricelist }) {
	const dispatch = useDispatch();

	const dollar = useSelector((state) => state.dollar.dollar);
	const userRol = useSelector((state) => state.users.user.role);
	const currency = useSelector((state) => state.products.currency);

	const [producto, setProducto] = useState(item);
	const [messageAlert, setMessageAlert] = useState("");
	const [dollarPrice, setDollarPrice] = useState(dollar?.ventaBNA);

	const classes = useStyles();

	useEffect(() => {
		let precioProducto = parseFloat(producto.precio.precioContado);
		let impuestoInterno = 0;

		if (producto.precio.impInterProducto && producto.precio.impInterProducto !== 0) {
			impuestoInterno = parseFloat(precioProducto * producto.precio.impInterProducto * 0.01);
		}

		const productoDesactualizado = Date.parse(new Date()) - Date.parse(producto.timestamp) - 24 * 60 * 60 * 1000 > 0;
		const precioConIva = parseFloat((precioProducto + impuestoInterno + (precioProducto * producto.precio.ivaProducto) / 100).toFixed(2));

		setProducto({ ...producto, productoDesactualizado, precioProducto, precioConIva });
	}, [item]);

	useEffect(() => {
		setDollarPrice(dollar?.ventaBNA);
	}, [dollar])

	useEffect(() => {
	}, [currency])

	const formatearNumero = (valor) => {
		if (dollarPrice && producto && producto.precio.tipoMoneda) {
			if (currency === "ARS" && producto.precio.tipoMoneda === "USD" && dollarPrice) {
				valor = valor * dollarPrice;
				valor = valor.toFixed(2);
			}
			else if (currency === "USD" && producto.precio.tipoMoneda === "ARS" && dollarPrice) {
				valor = valor / dollarPrice;
				valor = valor.toFixed(2);
			}
		}
		return new Intl.NumberFormat("de-DE").format((valor * 1).toFixed(2));
	};

	const handlePostQuotation = () => {
		dispatch(postQuotation(producto, selectedPricelist._id));
		setMessageAlert("Item añadido a cotizacion");
		setTimeout(() => {
			setMessageAlert("");
		}, 2000);
	};

	const handleCopyInfo = () => {
		const textarea = document.createElement("textarea");
		textarea.value =
			producto.informacionProducto.partNumber?.toUpperCase() + " - " + producto.informacionProducto.titulo.toString().toUpperCase();
		document.body.appendChild(textarea);
		textarea.select();
		document.execCommand("copy");
		document.body.removeChild(textarea);
		setMessageAlert("Titulo item añadido a portapepeles");
		setTimeout(() => {
			setMessageAlert("");
		}, 2000);
	};

	return (
		<div className="productCard-container" >
			{messageAlert !== "" && (
				<div style={{ position: "absolute", width: "fit-content" }}>
					<Alert severity="success">{messageAlert}</Alert>
				</div>
			)}
			{producto.stock.tieneStock ? (
				<div className="productos-tarjeta_producto-stock-solapa-proveedor" >
					<div className="productos-tarjeta_producto-stock-solapa" >
						<div
							className="productos-tarjeta_producto-stock-solapa-titulo"
							style={producto.productoDesactualizado > 0 ? { border: "2px solid var(--color-texto-error)", background: "var(--color-texto-error)" } : {}}
						>
							<span>En stock</span>
						</div>
						<div style={producto.productoDesactualizado > 0 ? { border: "2px solid var(--color-texto-error)" } : {}}>
							<span style={{ fontSize: "11px" }}>{producto.stock.cantStockTotal}</span>
						</div>
					</div>
					<div type="button" className="productos-tarjeta_producto-stock-solapa-ubicaciones-btn">
						{producto.stock.ubicacionStock[0].ubicacion && (
							<Menu
								menuButton={
									<ListItemButton className={classes.blockcontainer}>
										<span className="productos-tarjeta_producto-stock-solapa-ubicaciones-btn-p">Stock disponible por zona</span>
									</ListItemButton>
								}
							>
								{producto.stock.ubicacionStock.map((ubicacionDatos, i) => {
									return (
										<MenuItem key={i}>
											<span className="m-0">
												{ubicacionDatos.ubicacion}: {ubicacionDatos.cantStock}
											</span>
										</MenuItem>
									);
								})}
							</Menu>
						)}
					</div>
					<div className="productos-tarjeta_producto-stock-solapa-ubicaciones">
						{producto.stock.ubicacionStock[0].ubicacion &&
							producto.stock.ubicacionStock[0].ubicacion !== "" &&
							producto.stock.ubicacionStock.map((ubicacionDatos, i) => {
								return (
									<Tooltip title={ubicacionDatos.infoUbicacion || "Title"} key={i} followCursor>
										<div className="d-flex" type="button">
											<span className="productos-tarjeta_producto-stock-solapa-ubicaciones-btn-p me-1">
												{ubicacionDatos.ubicacion}: {ubicacionDatos.cantStock} |
											</span>
										</div>
									</Tooltip>
								);
							})}
					</div>
				</div>
			) : (
				<div className="productos-tarjeta_producto-sin_stock-solapa">
					<span>Sin stock</span>
				</div>
			)}
			<div className="productCard-options">
				<img src={addQuotationItem} onClick={handlePostQuotation} title="Agregar item a lista de cotizacion" />
				<img src={copyItem} onClick={handleCopyInfo} title="Copiar informacion de producto" />
			</div>
			<Link
				to={`/Producto?id=${item._id}`}
				className="productCard-info"
				key={item._id}
				style={producto.productoDesactualizado > 0 ? { border: "1px solid var(--color-texto-error)" } : {}}
			>
				<div>
					<div style={{ marginTop: "30px" }}>
						<h6>Categoría {" "} <span style={{ textTransform: "uppercase" }}>{producto.informacionProducto.tipoProducto[0]?.name.replace(/_/g, " ")}</span></h6>
						<h6>NP <span>{producto.informacionProducto.partNumber?.toUpperCase()}</span></h6>
					</div>
					<img
						src={
							Array.isArray(producto.informacionProducto.imagenes) && producto.informacionProducto.imagenes[0]
								? producto.informacionProducto.imagenes[0]
								: noImage
						}
						style={{ width: "100px", height: "100px" }}
						alt="img. prod."
					/>
				</div>
				<div>
					<h3>
						Marca: <span>{producto.informacionProducto.marca}</span>
					</h3>
					<h3>
						{producto.empresa.esProveedor ? "Proveedor" : "Competencia"} <span>{producto.empresa.nombre}</span>
					</h3>
				</div>
				<div>
					{producto.productoDesactualizado > 0 && (
						<div>
							<span>Producto desactualizado. Ultima actualizacion {producto.timestamp.split("T")[0]}</span>
						</div>
					)}
					<h2>{producto.informacionProducto.titulo.toString().toUpperCase()}</h2>
					{producto.precio.precioContado === 0 ?
						<div className="productCard-noPrice">
							<h4>Sin precio</h4>
						</div>
						: <div style={{ marginBottom: "-10px" }}>
							<div>
								<h4>{producto.empresa.esProveedor ? (userRol === "user" ? "Precio de venta" : "Precio sin impuestos") : "Precio de contado"}</h4>
								<span>
									{currency}{" "}
									{userRol === "user"
										? formatearNumero(
											producto.precio.precioContado * 1 + producto.precio.precioContado * 0.01 * selectedPricelist.percentage
										)
										: formatearNumero(producto.precio.precioContado * 1)}
								</span>
							</div>
							<div>
								<h4>{`IVA (${producto.precio.ivaProducto.toFixed(2)}%)`}</h4>
								<span>{currency} {formatearNumero(producto.precio.precioContado * (producto.precio.ivaProducto * 0.01))}</span>
							</div>

							{producto.precio.impInterProducto > 0 && <div>
								<h4>{`Impuesto interno (${producto.precio.impInterProducto.toFixed(2)}%)`}</h4>
								<span>{currency} {formatearNumero(producto.precio.precioContado * (producto.precio.impInterProducto * 0.01))}</span>
							</div>}

							<div>
								<h4>{userRol === "user" ? "Precio de venta con IVA" : "Precio final"}</h4>
								<span>
									{currency}{" "}
									{userRol === "user"
										? formatearNumero(producto.precioConIva * 1 + producto.precioConIva * 0.01 * selectedPricelist.percentage)
										: formatearNumero(producto.precioConIva * 1)}
								</span>
							</div>
						</div>}
				</div>
				{userRol !== "user" && producto.precio.precioContado !== 0 && (
					<section style={producto.productoDesactualizado > 0 ? { width: "330px" } : { width: "332px" }} className={currency === "USD" ? "" : "product-ARS-currency-theme"}>
						<div>
							<h6>Precio de venta: </h6>
							<span>
								{currency}{" "}
								{formatearNumero(
									producto.precio.precioContado * 1 + producto.precio.precioContado * 0.01 * selectedPricelist.percentage
								)}
							</span>
						</div>
						<div>
							<h6>Precio de venta final: </h6>
							<span>
								{currency}{" "}
								{formatearNumero(producto.precioConIva * 1 + producto.precioConIva * 0.01 * selectedPricelist.percentage)}
							</span>
						</div>
					</section>
				)}
			</Link>
		</div>
	);
}
