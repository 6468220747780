import { ACTION_DASHBOARD_ERROR, ACTION_DASHBOARD_START, GET_DASHBOARD_SUCCESS } from "../../types/index";
import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";
const cookie = new universlaCookie();

export function addDashboard(data, type, variable, text, selectCategory) {
	return async (dispatch) => {
		dispatch(actionDashboardStart());
		try {
			await clienteAxios({
				method: "POST",
				url: "dashboard",
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: {
					data,
					type,
					variable,
					text,
					selectCategory,
				},
			});
			dispatch(getDashboard());
		} catch {
			dispatch(actionDashboardError());
		}
	};
}

export function deleteDashboard(dashboardId) {
	return async (dispatch) => {
		dispatch(actionDashboardStart());
		try {
			await clienteAxios.delete(`dashboard/?dashboardId=${dashboardId}`);
			dispatch(getDashboard());
		} catch {
			dispatch(actionDashboardError());
		}
	};
}

export function editDashboard(dataEdit, typeEdit, variable, text, selectCategory, dashboardId) {
	return async (dispatch) => {
		dispatch(actionDashboardStart());
		try {
			await clienteAxios({
				method: "PUT",
				url: "dashboard",
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: {
					dataEdit,
					typeEdit,
					variable,
					text,
					selectCategory,
					dashboardId,
				},
			});
			dispatch(getDashboard());
		} catch {
			dispatch(actionDashboardError());
		}
	};
}

export function getDashboard() {
	return async (dispatch) => {
		dispatch(actionDashboardStart());
		try {
			const respuesta = await clienteAxios.get("dashboard");
			dispatch(getDashboardSuccess(respuesta.data.response));
		} catch (e) {
			dispatch(actionDashboardError());
		}
	};
}

const actionDashboardError = () => ({
	type: ACTION_DASHBOARD_ERROR,
});

const actionDashboardStart = () => ({
	type: ACTION_DASHBOARD_START,
});

const getDashboardSuccess = (response) => ({
	type: GET_DASHBOARD_SUCCESS,
	payload: response,
});

// export function getStaticsFromTheCompanys(indexProduct, textToSearch, type) {
// 	return async (dispatch) => {
// 		dispatch(getStaticsFromTheCompanysStart());
// 		try {
// 			const respuesta = await clienteAxios({
// 				method: "POST",
// 				url: "getStatisticsProductByCompany",
// 				headers: {
// 					"x-acces-token": cookie.get("token"),
// 				},
// 				data: {
// 					indexProducto: indexProduct,
// 					textToSearch: textToSearch,
// 				},
// 			});
// 			dispatch(getStaticsFromTheCompanysSuccessful(respuesta.data, type));
// 		} catch (error) {
// 			dispatch(getStaticsFromTheCompanysError(error));
// 		}
// 	};
// }
