import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { v4 as uuidv4 } from "uuid";

import ArrowOpenCategory from "../../assets/svg/functionalities-icons/arrow-close_blue.svg";
import ArrowClose from "../../assets/svg/functionalities-icons/arrow-open_white.svg";

import iconArrow_gray from "../../assets/svg/functionalities-icons/arrow_gray.svg";

import "./utilities.css";

const SelectCategory = ({ allCategories = false, openSelect, handleCategorySelect, othersCategory = false }) => {

	const navbarCategories = useSelector((state) => state.navbar.categories);

	const [catagories, setCatagories] = useState([""]);
	const [openCatagories, setOpenCatagories] = useState([]);

	const [subCatagories, setOpenSubcategory] = useState([""]);
	const [openSubCatagories, setOpenSubCatagories] = useState([]);

	const [products, setProducts] = useState([]);

	useEffect(() => {
		setCatagories(navbarCategories.map((category) => { return category.name }));
		setOpenCatagories(navbarCategories.map(() => { return false }))
	}, [, navbarCategories]);

	const handleClickCategory = (position) => {
		setOpenCatagories(
			openCatagories.map((data, i) => {
				if (i === position) return !data;
				return false;
			})
		);
		setOpenSubcategory(navbarCategories[position].subcategories.map((subcategory) => { return subcategory }))
		setOpenSubCatagories(navbarCategories[position].subcategories.map(() => { return false }))
	};

	const handleClickSubCategory = (position) => {
		setOpenSubCatagories(
			openSubCatagories.map((data, i) => {
				if (i === position) return !data;
				return false;
			})
		);
		setProducts(subCatagories[position].indices)
	};

	return (
		<div className={catagories.length !== 0 && openSelect ? "selectCategory-container" : "d-none"}>
			{allCategories && (
				<div className="selectCategory-container-row borderBottom" onClick={() => handleCategorySelect({ name: "Todas las categorias", tipoProducto: "allCategories" })}>
					<h6>TODAS LAS CATEGORIAS</h6>
				</div>
			)}
			{catagories.map((element, index) => (
				<div className="borderBottom" key={uuidv4()}>
					<div
						className={openCatagories[index] ? "selectCategory-container-row dropdowbCategoryRowOpen" : "selectCategory-container-row"}
						onClick={() => handleClickCategory(index)}
					>
						<h6>{element.toUpperCase()}</h6>
						<img src={openCatagories[index] ? ArrowClose : ArrowOpenCategory} alt="flecha" />
					</div>
					<div className="selectCategory-dropdown-category">
						{openCatagories[index] &&
							subCatagories.map((elementProduct, subIndex) => (
								<div key={uuidv4()}>
									<div
										className="selectCategory-dropdown-subcategory"
										style={{ display: "flex", padding: "15px 20px 15px 40px", justifyContent: "space-between" }}
										onClick={() => {
											handleClickSubCategory(subIndex);
										}}
									>
										<h3>{elementProduct.name}</h3>
										<img src={iconArrow_gray} style={openSubCatagories[index] ? { rotate: "180deg" } : {}} alt="flecha" />
									</div>

									<div className="selectCategory-dropdown-category">
										{openCatagories[index] &&
											openSubCatagories[subIndex] &&
											products.map((elementProduct) => (
												<div
													key={uuidv4()}
													className="selectCategory-dropdown-subcategory"
													style={{ display: "flex", padding: "15px 40px" }}
													onClick={() => handleCategorySelect({
														indexID: elementProduct._id,
														tipoProducto: catagories[index].replace(/ /g, "_").toLowerCase() + "-" +
															subCatagories[subIndex].name.replace(/ /g, "_").toLowerCase() + "-" +
															elementProduct.name.replace(/ /g, "_").toLowerCase(),
														name: elementProduct.name
													})}
												>
													{elementProduct.name}
												</div>
											))}
									</div>
								</div>
							))}
					</div>
				</div>
			))}
			{othersCategory && (
				<div className="selectCategory-container-row" onClick={() => handleCategorySelect({ name: "OTRO", tipoProducto: "cargaGeneral", indexID: null })}>
					<h6>OTROS</h6>
				</div>
			)}
		</div>
	);
};

export default SelectCategory;
