import { START_GET_QUOTATION, GET_QUOTATION_SUCCESS, GET_QUOTATION_ERROR } from "../../types/index";

import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";

const cookie = new universlaCookie();

export function deleteCompleteQuotation() {
	return async (dispatch) => {
		try {
			await clienteAxios.delete("quotationList", {
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(getQuotation());
		} catch (e) {
			dispatch(getQuotationError());
		}
	};
}

export function deleteQuotation(id) {
	return async (dispatch) => {
		try {
			await clienteAxios.delete(`quotation/?id=${id}`);
			dispatch(getQuotation());
		} catch (e) {
			dispatch(getQuotationError());
		}
	};
}

export function getQuotation() {
	return async (dispatch) => {
		dispatch(downloadQuotation());
		try {
			const response = await clienteAxios.get("quotation", {
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(getQuotationSuccess(response.data.response));
		} catch (e) {
			dispatch(getQuotationError());
		}
	};
}

export function postQuotation(product, selectedPriceList) {
	return async (dispatch) => {
		try {
			await clienteAxios.post(
				"quotation",
				{ product, selectedPriceList },
				{
					headers: {
						"x-acces-token": cookie.get("token"),
					},
				}
			);
		} catch (e) {
			dispatch(getQuotationError());
		}
	};
}

export function updatePriceList(idQuotation, updateData) {
	return async (dispatch) => {
		try {
			await clienteAxios.put("quotation", {
				idQuotation: idQuotation,
				...updateData
			}
			);
			getQuotation();
		} catch (e) {
			dispatch(getQuotationError());
		}
	};
}

const downloadQuotation = () => ({
	type: START_GET_QUOTATION,
});

const getQuotationSuccess = (quotation) => ({
	type: GET_QUOTATION_SUCCESS,
	payload: quotation,
});

const getQuotationError = () => ({
	type: GET_QUOTATION_ERROR,
});
