import { 
	ACTION_NAVBAR_CATEGORIES_ERROR, 
	ACTION_NAVBAR_CATEGORIES_START, 
	GET_NAVBAR_CATEGORIES_SUCCESS, 
	NAVBAR_HIDE, 
	NAVBAR_SHOW,
	SET_NAVBAR_CATEGORIES_MESSAGE
} from "../../types";

const initialState = {
	categories: [],
	error: false,
	loading: false,
	message: "",
	show: true,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case NAVBAR_HIDE:
			return {
				...state,
				show: false,
			};
		case NAVBAR_SHOW:
			return {
				...state,
				show: true,
			};
		case ACTION_NAVBAR_CATEGORIES_ERROR:
			return {
				...state,
				error: true,
				loading: false,
			};
		case ACTION_NAVBAR_CATEGORIES_START:
			return {
				...state,
				error: false,
				loading: true,
				message: "Cargando..."
			};
		case GET_NAVBAR_CATEGORIES_SUCCESS:
			return{
				...state,
				categories: action.payload,
				error: false,
				loading: false,
			}
		case SET_NAVBAR_CATEGORIES_MESSAGE:
			return{
				...state,
				message: action.payload
			}
		default:
			return state;
	}
}
