import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { obtenerCuentas } from "../../redux/actions/cuentas/cuentasUsersAction";
import { limpiarEstadosSignUpUser } from "../../redux/actions/registrosUsuarios/signInUserAction";

import SearchIcon from "@mui/icons-material/Search";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import ModalCrearCuenta from "./modales/ModalCrearCuenta";
import CardCuentas from "./CardCuentas";

import iconoNuevaCuenta from "../../assets/svg/functionalities-icons/plus_blue.svg";
import iconoMenu from "../../assets/svg/functionalities-icons/menu.svg";
import iconArrow_gray from "../../assets/svg/functionalities-icons/arrow_gray.svg";

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AdministrarCuentas = () => {
	const dispatch = useDispatch();

	const cuentas = useSelector((state) => state.cuentas.cuentas);
	const userAccess = useSelector((state) => state.users.user.role.access)
	const user = useSelector((state) => state.users);

	const [closeSection, setCloseSection] = useState(false)
	const [errorReingresoPassword, setErrorReingresoPassword] = useState(false);
	const [mostrarVentaCrearCuenta, setMostrarVentanaCrearCuenta] = useState(false);
	const [cuentaBusqueda, setCuentaBusqueda] = useState("");

	const [accessToAdministration, setAccessToAdministration] = useState(false)

	const [open, setOpen] = useState(false);
	const [mensajeAdvertencia, setMensajeAdvertencia] = useState("");

	useEffect(() => {
		dispatch(obtenerCuentas());
	}, []);

	useEffect(() => {
		dispatch(limpiarEstadosSignUpUser());
	}, [mostrarVentaCrearCuenta]);

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	return (
		<>
			<div className="cuentas-info_cuentas-contra_row" onClick={() => { setCloseSection(!closeSection) }}>
				<h2 >Cuentas de usuario</h2>
				<img src={iconArrow_gray} style={!closeSection ? { rotate: "180deg" } : {}} />
			</div>
			<hr className="cuentas-info_cuentas-hr" />
			<div className={closeSection ? "d-none" : undefined}>
				<div className="cuentas-info_cuentas-options">
					<form autoComplete="off">
						<div className="cuentas-cuentas_usuario-search_cont">
							<input
								placeholder="Email, username o nombre..."
								autoComplete="off"
								name="email"
								onChange={(e) => {
									setCuentaBusqueda(e.target.value.toLowerCase());
								}}
							/>
							<SearchIcon />
						</div>
						{userAccess !== "user" && !accessToAdministration && <img
							src={iconoMenu}
							alt="menu"
							onClick={() => setAccessToAdministration(true)}
							className="cuentas-info_cuentas-img_menu"
						/>}
					</form>
					<div className="cuentas-admin_cuentas-admin_display_grande">
						{userAccess !== "user" && !accessToAdministration && <button
							className="cuentas-admin_cuentas-admin_cuentas"
							onClick={() => setAccessToAdministration(true)}
						>
							Administrar cuentas
						</button>}
					</div>
				</div>
				<div className="cuentas-admin_cuentas-contenedor_cards">
					{cuentas &&
						cuentas.length > 0 &&
						cuentas
							.filter((cuenta) => cuenta.email !== user.user.email)
							.map((cuenta, index) => {
								if (
									cuenta.email.toLowerCase().includes(cuentaBusqueda) ||
									cuenta.nombre.toLowerCase().includes(cuentaBusqueda) ||
									cuenta.username.toLowerCase().includes(cuentaBusqueda)
								) {
									return <CardCuentas permisoAdministrador={accessToAdministration} key={index} cuenta={cuenta} index={cuenta._id} />;
								}
							})}
					{accessToAdministration && (
						<div
							className="cuentas-info_cuentas-agregar_div"
							onClick={() => {
								setMostrarVentanaCrearCuenta(true);
								setErrorReingresoPassword(false);
							}}
							type="button"
						>
							<img src={iconoNuevaCuenta} alt="add" />
						</div>
					)}
					{mostrarVentaCrearCuenta && (
						<ModalCrearCuenta
							mostrarVentaCrearCuenta={mostrarVentaCrearCuenta}
							errorReingresoPassword={errorReingresoPassword}
							setMostrarVentanaCrearCuenta={setMostrarVentanaCrearCuenta}
							setOpen={setOpen}
							setMensajeAdvertencia={setMensajeAdvertencia}
						/>
					)}
				</div>
			</div>
			<Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
					{mensajeAdvertencia}
				</Alert>
			</Snackbar>
		</>
	);
};
