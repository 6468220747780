import {
	COMENZAR_DESCARGA_CUENTAS_BASE_DE_DATOS,
	COMENZAR_DESCARGA_CUENTAS_BASE_DE_DATOS_EXITO,
	COMENZAR_DESCARGA_CUENTAS_BASE_DE_DATOS_ERROR,
	ELIMINAR_CUENTA_BASE_DE_DATOS,
	ELIMINAR_CUENTA_BASE_DE_DATOS_ERROR,
	ELIMINAR_CUENTA_BASE_DE_DATOS_EXITO,
	MODIFICAR_CUENTA_BASE_DE_DATOS,
	MODIFICAR_CUENTA_BASE_DE_DATOS_EXITO,
	MODIFICAR_CUENTA_BASE_DE_DATOS_ERROR,
	VERIFICAR_CONTRASEÑIA_SECCION_CUENTAS,
	VERIFICAR_CONTRASEÑIA_SECCION_CUENTAS_EXITO,
	VERIFICAR_CONTRASEÑIA_SECCION_CUENTAS_ERROR,
	LOG_OUT_PASS_ADMINISTRADOR,
	LOG_OUT_PASS_ADMINISTRADOR_EXITO,
	LOG_OUT_PASS_ADMINISTRADOR_ERROR,
	RESETEAR_ESTADO_REDUX_CUENTAS,
} from "../../types/index";

const initialState = {
	cuentas: {},
	msj: "",
	pass: false,
	loading: false,
	error: false,
};
export default function reducerCuentas(state = initialState, action) {
	switch (action.type) {
		case RESETEAR_ESTADO_REDUX_CUENTAS:
			return {
				...state,
				msj: "",
				loading: false,
				error: false,
			};
		case COMENZAR_DESCARGA_CUENTAS_BASE_DE_DATOS:
		case ELIMINAR_CUENTA_BASE_DE_DATOS:
		case MODIFICAR_CUENTA_BASE_DE_DATOS:
		case VERIFICAR_CONTRASEÑIA_SECCION_CUENTAS:
		case LOG_OUT_PASS_ADMINISTRADOR:
			return {
				...state,
				msj: "",
				loading: true,
				error: false,
			};
		case MODIFICAR_CUENTA_BASE_DE_DATOS_ERROR:
			return {
				...state,
				msj: action.payload.response.data.msj,
				loading: false,
				error: true,
			};
		case COMENZAR_DESCARGA_CUENTAS_BASE_DE_DATOS_ERROR:
		case ELIMINAR_CUENTA_BASE_DE_DATOS_ERROR:
		case VERIFICAR_CONTRASEÑIA_SECCION_CUENTAS_ERROR:
		case LOG_OUT_PASS_ADMINISTRADOR_ERROR:
			return {
				...state,
				loading: false,
				error: true,
			};
		case COMENZAR_DESCARGA_CUENTAS_BASE_DE_DATOS_EXITO:
			return {
				...state,
				cuentas: action.payload.cuentas,
				loading: false,
				error: false,
			};
		case ELIMINAR_CUENTA_BASE_DE_DATOS_EXITO:
			return {
				...state,
				msj: action.payload.msj,
				loading: false,
				error: null,
			};
		case MODIFICAR_CUENTA_BASE_DE_DATOS_EXITO:
			return {
				...state,
				msj: action.payload.msj,
				loading: false,
				error: null,
			};
		case VERIFICAR_CONTRASEÑIA_SECCION_CUENTAS_EXITO:
			return {
				...state,
				msj: action.payload.msj,
				pass: action.payload.pass,
				loading: false,
			};
		case LOG_OUT_PASS_ADMINISTRADOR_EXITO:
			return {
				...state,
				pass: action.payload.pass,
				loading: false,
			};
		default:
			return state;
	}
}
