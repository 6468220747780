import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
	deleteQuotation,
	deleteCompleteQuotation,
	getQuotation,
	updatePriceList,
} from "../../redux/actions/utilities/quotationAction";
import { getPriceLists } from "../../redux/actions/utilities/priceListAction";
import { obtenerDollars } from "../../redux/actions/utilities/dolarAction";

import { PDFDownloadLink } from "@react-pdf/renderer";
import QuotationDownload from "./QuotationDownload";

import iconoDeshacer from "../../assets/svg/functionalities-icons/volver-porcentaje-lista.svg";
import iconTrashCan from "../../assets/svg/functionalities-icons/trash-can.svg";
import iconEdit from "../../assets/svg/functionalities-icons/lapiz-editar.svg";
import iconoConfirmar from "../../assets/svg/functionalities-icons/check_white.svg";
import iconArrow_gray from "../../assets/svg/functionalities-icons/arrow_gray.svg";

import "./quotation.css";

export default function Quotation() {
	const dispatch = useDispatch();

	const dollar = useSelector((state) => state.dollar.dollar);
	const quotation = useSelector((state) => state.quotation.quotationItems);
	const priceLists = useSelector((state) => state.priceList.priceList);
	const userRol = useSelector((state) => state.users.user.role);

	const [closeSection, setCloseSection] = useState(false)

	const [dollarPrice, setDollarPrice] = useState(dollar?.ventaBNA);
	const [quotationEdit, setQuotationEdit] = useState("");
	const [quantityToUpdate, setQuantityToUpdate] = useState(0);

	useEffect(() => {
		dispatch(getQuotation());
		dispatch(getPriceLists());
		dispatch(obtenerDollars());
	}, []);

	useEffect(() => {
		setDollarPrice(dollar?.ventaBNA);
	}, [dollar])

	const handleDeleteQuotationItem = (id) => {
		dispatch(deleteQuotation(id));
	};

	const handleEditQuotationItem = (id, cantidad) => {
		setQuotationEdit(id);
		setQuantityToUpdate(cantidad);
	};

	const handleUndoUpdate = () => {
		setQuotationEdit("");
	};

	const handleQuantityChange = (e) => {
		e.preventDefault();
		if (e.target.value < 1) {
			setQuantityToUpdate(1);
		} else {
			setQuantityToUpdate(parseInt(e.target.value));
		}
	};

	const handleUpdateQuantity = (itemID) => {
		const updateData = {
			newQuantity: quantityToUpdate
		}
		dispatch(updatePriceList(itemID, updateData));
		setQuotationEdit("");
		dispatch(getQuotation());
	};

	const handleDeleteCompleteQuotation = () => {
		dispatch(deleteCompleteQuotation());
	};

	const handlePriceListChange = (idPriceList, idItem) => {
		const updateData = {
			newPriceList: idPriceList
		}
		dispatch(updatePriceList(idItem, updateData));
		dispatch(getQuotation());
	};

	const formatearNumero = (valor) => {
		return new Intl.NumberFormat("de-DE").format((valor * 1).toFixed(2));
	};

	const transformPrice = (value, exchange) => {
		if (exchange === "ARS" && dollarPrice) {
			value = value / dollarPrice;
			value = value.toFixed(2);
		} else {
			return new Intl.NumberFormat("de-DE").format((value * 1).toFixed(2));
		}

		return new Intl.NumberFormat("de-DE").format((value * 1).toFixed(2));
	};

	const getItemPriceList = (item) => {
		return priceLists.find(priceList => priceList._id === item.selectedPriceList);
	}

	let precioCosto = quotation
		.map((item) => {
			if (item.product.precio.tipoMoneda === "ARS" && dollarPrice) {
				return (item.product.precio.precioContado / dollarPrice) * item.quantity;
			} else {
				return item.product.precio.precioContado * item.quantity;
			}
		})
		.reduce((a, b) => a + b, 0)
		.toFixed(2);

	let iva10Costo = quotation
		.map((item) => {
			if (item.product.precio.ivaProducto * 1 < 20) {
				if (item.product.precio.tipoMoneda === "ARS" && dollarPrice) {
					return (
						((item.product.precio.precioContado * (item.product.precio.ivaProducto / 100)) /
							dollarPrice) *
						item.quantity
					);
				} else {
					return item.product.precio.precioContado * (item.product.precio.ivaProducto / 100) * item.quantity;
				}
			}
			return 0;
		})
		.reduce((a, b) => a + b, 0)
		.toFixed(2);

	let iva21Costo = quotation
		.map((item) => {
			if (item.product.precio.ivaProducto * 1 > 20) {
				if (item.product.precio.tipoMoneda === "ARS" && dollarPrice) {
					return (
						((item.product.precio.precioContado * (item.product.precio.ivaProducto / 100)) /
							dollarPrice) *
						item.quantity
					);
				} else {
					return item.product.precio.precioContado * (item.product.precio.ivaProducto / 100) * item.quantity;
				}
			}
			return 0;
		})
		.reduce((a, b) => a + b, 0)
		.toFixed(2);

	let impuestoCosto = quotation
		.map((item) => {
			if (item.product.precio.tipoMoneda === "ARS" && dollarPrice) {
				return (
					((item.product.precio.precioContado * (item.product.precio.impInterProducto / 100)) /
						dollarPrice) *
					item.quantity
				);
			} else {
				return item.product.precio.precioContado * (item.product.precio.impInterProducto / 100) * item.quantity;
			}
		})
		.reduce((a, b) => a + b, 0)
		.toFixed(2);

	let precioVenta = quotation
		.map((item) => {
			const selectedPriceList = getItemPriceList(item);
			if (item.product.precio.tipoMoneda === "ARS" && dollarPrice) {
				return (
					((item.product.precio.precioContado * (selectedPriceList?.percentage / 100 + 1)) /
						dollarPrice) *
					item.quantity
				);
			} else {
				return (
					item.product.precio.precioContado * (selectedPriceList?.percentage / 100 + 1) * item.quantity
				);
			}
		})
		.reduce((a, b) => a + b, 0)
		.toFixed(2);

	let iva10Venta = quotation
		.map((item) => {
			const selectedPriceList = getItemPriceList(item);
			if (item.product.precio.ivaProducto * 1 < 20) {
				if (item.product.precio.tipoMoneda === "ARS" && dollarPrice) {
					return (
						((item.product.precio.precioContado *
							(item.product.precio.ivaProducto / 100) *
							(selectedPriceList?.percentage / 100 + 1)) /
							dollarPrice) *
						item.quantity
					);
				} else {
					return (
						item.product.precio.precioContado *
						(item.product.precio.ivaProducto / 100) *
						(selectedPriceList?.percentage / 100 + 1) *
						item.quantity
					);
				}
			}
			return 0;
		})
		.reduce((a, b) => a + b, 0)
		.toFixed(2);


	let iva21Venta = quotation
		.map((item) => {
			const selectedPriceList = getItemPriceList(item);
			if (item.product.precio.ivaProducto * 1 > 20) {
				if (item.product.precio.tipoMoneda === "ARS" && dollarPrice) {
					return (
						((item.product.precio.precioContado *
							(item.product.precio.ivaProducto / 100) *
							(selectedPriceList?.percentage / 100 + 1)) /
							dollarPrice) *
						item.quantity
					);
				} else {
					return (
						item.product.precio.precioContado *
						(item.product.precio.ivaProducto / 100) *
						(selectedPriceList?.percentage / 100 + 1) *
						item.quantity
					);
				}
			}
			return 0;
		})
		.reduce((a, b) => a + b, 0)
		.toFixed(2);

	let impuestoVenta = quotation
		.map((item) => {
			const selectedPriceList = getItemPriceList(item);
			if (item.product.precio.tipoMoneda === "ARS" && dollarPrice) {
				return (
					((item.product.precio.precioContado *
						(item.product.precio.impInterProducto / 100) *
						(selectedPriceList?.percentage / 100 + 1)) /
						dollarPrice) *
					item.quantity
				);
			} else {
				return (
					item.product.precio.precioContado *
					(item.product.precio.impInterProducto / 100) *
					(selectedPriceList?.percentage / 100 + 1) *
					item.quantity
				);
			}
		})
		.reduce((a, b) => a + b, 0)
		.toFixed(2);

	return (
		<div className="cuentas-contenedor quotation-container">
			<div className="cuentas-info_cuentas-row" onClick={() => { setCloseSection(!closeSection) }}>
				<h2>Lista de cotización</h2>
				<img src={iconArrow_gray} style={!closeSection ? { rotate: "180deg" } : {}} />
			</div>
			<hr className="cuentas-info_cuentas-hr" />
			<div className={closeSection ? "d-none" : undefined}>
				<div>
					{quotation &&
						quotation.map((item) => {
							const selectedPriceList = getItemPriceList(item);
							return (
								<div key={item._id}>
									<div className="quotation-item-container">
										{Date.parse(new Date()) - Date.parse(item.product.timestamp) - 24 * 60 * 60 * 1000 > 0 ? (
											<h4>Producto desactualizado</h4>
										) : undefined}
										<div>
											<div>
												<div className="quotation-item-container-change">
													<select
														value={selectedPriceList?.percentage}
														onChange={(e) => handlePriceListChange(e.target.value, item._id)}
													>
														<option hidden>{selectedPriceList?.name}</option>
														{priceLists.map((priceList, index) => {
															return (
																<option value={priceList._id} key={index}>
																	{priceList.name}
																</option>
															);
														})}
													</select>
													{Date.parse(new Date()) - Date.parse(item.product.timestamp) - 24 * 60 * 60 * 1000 > 0 ? (
														<h4>Producto desactualizado</h4>
													) : undefined}
												</div>
												<Link to={`/Producto?id=${item.product._id}`}>
													<h2>{item.product.informacionProducto.titulo.toUpperCase()}</h2>
												</Link>
											</div>
											{item._id === quotationEdit ? (
												<section className="quotation-edit-options">
													<img
														alt="undo-button"
														src={iconoDeshacer}
														className="cuentas-comercial-button_undo"
														onClick={handleUndoUpdate} />
													<img
														alt="confirm-button"
														src={iconoConfirmar}
														className="cuentas-comercial-button_confirm"
														onClick={() => handleUpdateQuantity(item._id)}
													/>
												</section>
											) : (
												<section className="quotation-edit-options">
													<img
														alt="edit-button"
														src={iconEdit}
														className="cuentas-comercial-button_undo"
														onClick={() => handleEditQuotationItem(item._id, item.quantity)} />
													<img
														alt="confirm-button"
														src={iconTrashCan}
														className="cuentas-comercial-button_undo"
														onClick={() => handleDeleteQuotationItem(item._id)}
													/>
												</section>
											)}
										</div>
										<div>
											<h3>PN: {item.product.informacionProducto.partNumber}</h3>
											{userRol && userRol.access !== "user" ? <h4>Proveedor: {item.product.empresa.nombre}</h4> : <h4></h4>}
										</div>
										<hr />
										<div>
											<section>
												<h5>Stock</h5>
												<h6>{item.product.stock.cantStockTotal}</h6>
											</section>
											<section>
												<h5>Cant.</h5>
												{item._id !== quotationEdit ? (
													<h6>{item.quantity}</h6>
												) : (
													<input type="number" value={quantityToUpdate} onChange={handleQuantityChange} min={1} />
												)}
											</section>
											<section>
												<h5>{userRol && userRol.access === "user" ? "Precio de venta" : "Precio"}</h5>
												<h6>
													USD{" "}
													{userRol && userRol.access !== "user"
														? transformPrice(item.product.precio.precioContado, item.product.precio.tipoMoneda)
														: transformPrice(
															item.product.precio.precioContado *
															1 *
															(selectedPriceList?.percentage / 100 + 1),
															item.product.precio.tipoMoneda
														)}
												</h6>
											</section>
											<section>
												<h5>IVA</h5>
												<h6>{formatearNumero(item.product.precio.ivaProducto)}%</h6>
											</section>
											<section>
												<h5>I.I.</h5>
												<h6>{formatearNumero(item.product.precio.impInterProducto)}%</h6>
											</section>
											<section>
												<h5>{userRol && userRol.access === "user" ? "Subtotal de venta" : "Subtotal"}</h5>
												<h6>
													USD{" "}
													{userRol && userRol.access !== "user"
														? transformPrice(
															(item.product.precio.precioContado * 1 +
																item.product.precio.precioContado * 0.01 * item.product.precio.ivaProducto +
																item.product.precio.precioContado * 0.01 * item.product.precio.impInterProducto) *
															item.quantity,
															item.product.precio.tipoMoneda
														)
														: transformPrice(
															(item.product.precio.precioContado * 1 +
																item.product.precio.precioContado * 0.01 * item.product.precio.ivaProducto +
																item.product.precio.precioContado * 0.01 * item.product.precio.impInterProducto) *
															(selectedPriceList?.percentage / 100 + 1) *
															item.quantity,
															item.product.precio.tipoMoneda
														)}
												</h6>
											</section>
										</div>
									</div>
									{userRol && userRol.access !== "user" && (
										<section className="quotation-item-salePrice">
											<div>
												<h6>Subtotal de venta: </h6>
												<span>
													{item.product.precio.tipoMoneda}{" "}
													{transformPrice(
														item.product.precio.precioContado *
														1 *
														(selectedPriceList?.percentage / 100 + 1) *
														item.quantity,
														item.product.precio.tipoMoneda
													)}
												</span>
											</div>
											<div>
												<h6>Subtotal de venta con IVA: </h6>
												<span>
													{item.product.precio.tipoMoneda}{" "}
													{transformPrice(
														(item.product.precio.precioContado * 1 +
															item.product.precio.precioContado * 0.01 * item.product.precio.ivaProducto +
															item.product.precio.precioContado * 0.01 * item.product.precio.impInterProducto) *
														(selectedPriceList?.percentage / 100 + 1) *
														item.quantity,
														item.product.precio.tipoMoneda
													)}
												</span>
											</div>
										</section>
									)}
								</div>
							);
						})}
				</div>
				<div className="quotation-options-container">
					{userRol && userRol.access !== "user" && (
						<section>
							<h5>Costo</h5>
							<hr />
							<div>
								<h3>Precio</h3>
								<h3>USD {formatearNumero(precioCosto)}</h3>
							</div>
							<div>
								<h3>Iva 10.5%</h3>
								<h3>USD {formatearNumero(iva10Costo)}</h3>
							</div>
							<div>
								<h3>Iva 21.0%</h3>
								<h3>USD {formatearNumero(iva21Costo)}</h3>
							</div>
							<div>
								<h3>Imp. interno</h3>
								<h3>USD {formatearNumero(impuestoCosto)}</h3>
							</div>
							<div>
								<h4>Total</h4>
								<h4>USD {formatearNumero(precioCosto * 1 + iva10Costo * 1 + iva21Costo * 1 + impuestoCosto * 1)}</h4>
							</div>
						</section>
					)}
					<section className="quotation-options-last-section">
						<h5>Venta</h5>
						<hr />
						<div>
							<h3>Precio de venta</h3>
							<h3>USD {formatearNumero(precioVenta)}</h3>
						</div>
						<div>
							<h3>Iva 10.5%</h3>
							<h3>USD {formatearNumero(iva10Venta)}</h3>
						</div>
						<div>
							<h3>Iva 21.0%</h3>
							<h3>USD {formatearNumero(iva21Venta)}</h3>
						</div>
						<div>
							<h3>Imp. interno</h3>
							<h3>USD {formatearNumero(impuestoVenta)}</h3>
						</div>
						<div>
							<h4>Total de venta</h4>
							<h4>USD {formatearNumero(precioVenta * 1 + iva10Venta * 1 + iva21Venta * 1 + impuestoVenta * 1)}</h4>
						</div>
						<button onClick={() => handleDeleteCompleteQuotation()}>Eliminar lista</button>
						<PDFDownloadLink
							document={
								<QuotationDownload
									quotation={quotation}
									dollarPrice={dollarPrice}
									precioVenta={precioVenta}
									iva10Venta={iva10Venta}
									iva21Venta={iva21Venta}
									impuestoVenta={impuestoVenta}
									priceLists={priceLists}
									date={new Date().toLocaleDateString("es-ES")}
								/>
							}
							fileName="cotizacion.pdf"
						>
							<button style={userRol && userRol.access === "user" ? { marginBottom: "10px" } : {}}>Descargar lista</button>
						</PDFDownloadLink>
					</section>
				</div>
			</div>
		</div>
	);
}
