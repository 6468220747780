import React from 'react'

import { Modal, Box } from "@mui/material";

import iconCloseModal from "../../../assets/svg/functionalities-icons/cross_gray.svg";

import { modalBoxStyle } from "../utilities";

export const EmptyModal = ({ descriptionText, setShowEmptyModal, titleText }) => {

    const handleDescriptionDetail = () => {
        var text = ""
        if (descriptionText.includes('Exception:')) text = descriptionText.split('Exception:')[1]
        return text
    }

    return (
        <Modal
            open={true}
            onClose={() => {
                setShowEmptyModal(false);
            }}
            style={{ backdropFilter: "blur(4px)" }}
        >
            <Box sx={modalBoxStyle} style={{ width: "auto", maxWidth: "90%" }}>
                <div className="modales-contenedor_texto-div">
                    <h4 className="modales-titulo_modal">{titleText}</h4>
                    <img
                        src={iconCloseModal}
                        className="modales-cerrar_modal-img"
                        alt="close"
                        onClick={() => {
                            setShowEmptyModal(false);
                        }}
                    />
                    <h6>{handleDescriptionDetail()}</h6>
                    <h5 className="modales-texto_modal">{descriptionText}</h5>
                </div>
            </Box>
        </Modal>
    )
}
