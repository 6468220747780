import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal, Box } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import inconoModificar from "../../assets/svg/system-images/icono_cambiar_contrasenia.svg";
import iconoCerrarModal from "../../assets/svg/functionalities-icons/cross_gray.svg";

import { modificarClaveCuenta } from "../../redux/actions/registrosUsuarios/logInUserAction";
import { resetearClaveProvisoria } from "../../redux/actions/registrosUsuarios/logInUserAction";

import cryptoJs from "crypto-js";
import config from "../../config/config";
import Loader from "../utilities/Loader";

import "./login.css";

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "300px",
	height: "auto",
	bgcolor: "#ffffff",
	borderRadius: "10px",
	boxShadow: 24,
	disableAutoFocus: "true",
	outline: "none",
};

const ModalModificarContrasenia = ({ emailInput }) => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.users);
	const login = useSelector((state) => state.users.login);
	const [nuevaContrasenia, setNuevaContrasenia] = useState();
	const [nuevaContraseniaRepetida, setNuevaContraseniaRepetida] = useState();
	const [mensajeContraseniasDistintas, setMensajeContraseniasDistintas] = useState(false);
	const [severityAlert, setSeverityAlert] = useState();

	useEffect(() => {
		setMensajeContraseniasDistintas(false);
	}, [nuevaContrasenia, nuevaContraseniaRepetida]);

	useEffect(() => {
		if (nuevaContrasenia !== nuevaContraseniaRepetida || (user.msj && user.msj === "Formato de contraseña inválido")) setSeverityAlert("error");
	}, [user.msj, nuevaContrasenia, nuevaContraseniaRepetida]);

	const handleSubmitSignIn = (e) => {
		e.preventDefault();
		if (varificarIgualdadContrasenias(nuevaContrasenia, nuevaContraseniaRepetida)) {
			dispatch(modificarClaveCuenta(emailInput, encriptar(nuevaContrasenia)));
		} else {
			setMensajeContraseniasDistintas(true);
		}
	};

	const handleClose = () => {
		dispatch(resetearClaveProvisoria());
	};

	function encriptar(string) {
		const secretKey = config.secretKey;
		return cryptoJs.AES.encrypt(string, secretKey).toString();
	}

	const varificarIgualdadContrasenias = (clave1, clave2) => {
		if (clave1 !== clave2) return false;
		else return true;
	};
	return (
		<Modal open={true} onClose={handleClose} >
			<Box sx={style}>
				<div className="login-modal-container">
					<img src={iconoCerrarModal} className="modales-cerrar_modal-img" alt="close" onClick={() => handleClose()} />
					<img src={inconoModificar} alt="update" />
					<div>
						<h4>Ingresá tu contraseña nueva</h4>
						<form onSubmit={handleSubmitSignIn} autoComplete="off">
							<input
								autoComplete="off"
								minLength={8}
								maxLength={12}
								name="new password"
								onChange={(e) => {
									setNuevaContrasenia(e.target.value);
								}}
								placeholder="Nueva contraseña"
								required
								type="password"
							/>
							<input
								autoComplete="off"
								minLength={8}
								maxLength={12}
								name="repeat password"
								onChange={(e) => {
									setNuevaContraseniaRepetida(e.target.value);
								}}
								placeholder="Repetir contraseña"
								required
								type="password"
							/>
							<button>Aceptar</button>
						</form>
					</div>
				</div>
				{login && <Loader />}
				{mensajeContraseniasDistintas && (
					<Alert severity={severityAlert} className=" my-3 px-5 mx-4">
						Las contraseñas no coinciden
					</Alert>
				)}
			</Box>
		</Modal>
	);
};

export default ModalModificarContrasenia;
