import { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Group } from '@mantine/core';

import universlaCookie from "universal-cookie";

import { Modal, Box } from "@mui/material";

import { logOutUser } from "../../../redux/actions/registrosUsuarios/logInUserAction";

import accountsIcon from '../../../assets/svg/menu-icons/cuenta.svg'
import homeIcon from '../../../assets/svg/menu-icons/inicio-icono.svg'
import excelLoadIcon from '../../../assets/svg/menu-icons/excel-load.svg'
import filtersIcon from '../../../assets/svg/menu-icons/filtroicono.svg'
import marketPlayetsIcon from '../../../assets/svg/menu-icons/proveedoresicono.svg'
import quotationIcon from '../../../assets/svg/menu-icons/quotation-icon.svg'
import closeSessionIcon from "../../../assets/svg/menu-icons/cerrar-sesion.svg";
import closeMenuIcon from "../../../assets/svg/menu-icons/flecha-triangular.svg";
import configurationIcon from "../../../assets/svg/menu-icons/configuracion.svg";

import './navbarMenu.css';

const cookie = new universlaCookie();

export function NavbarMenu({ handleCloseNavbarMenu }) {
    const navegation = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.users);

    const [active, setActive] = useState(location.pathname);


    const data = user.user.role && user.user.role.access !== "user" ? [
        { link: '/Dashboard', label: 'Inicio', icon: homeIcon },
        { link: '/CargaPlanillas', label: 'Carga de planillas', icon: excelLoadIcon },
        { link: '/Cuentas', label: 'Cuentas', icon: accountsIcon },
        { link: '/Cotizaciones', label: 'Lista de cotización', icon: quotationIcon },
        { link: '/ActoresDeMercado', label: 'Actores de mercado', icon: marketPlayetsIcon },
        { link: '/Categorizacion', label: 'Categorización', icon: filtersIcon },
        { link: '/Comercializacion', label: 'Comercialización', icon: configurationIcon },
    ] : [
        { link: '/Dashboard', label: 'Inicio', icon: homeIcon },
        { link: '/Cuentas', label: 'Cuentas', icon: accountsIcon },
        { link: '/Cotizaciones', label: 'Lista de cotizacion', icon: quotationIcon }
    ];

    const handleLogOut = (event) => {
        event.preventDefault();
        cookie.remove("token");
        sessionStorage.removeItem("claveProvisoria");
        localStorage.removeItem("user");
        localStorage.setItem("LogInStatus", false);
        dispatch(logOutUser());
        navegation("/");
    }

    const handleClickAwayClose = (event, reason) => {
        if (event.clientX < 300) {
            return;
        }
        handleCloseNavbarMenu()
    };

    return (
        <Box >
            <Modal open={true}
                style={{ backdropFilter: "blur(4px)" }}
                onClick={handleClickAwayClose}
            >
                <nav className="navbarMenu-container" >
                    <div className="navbarMenu-navbarMain">
                        <div className="navbarMenu-header" >
                            <img src={closeMenuIcon} onClick={handleCloseNavbarMenu} />
                            <h6 onClick={handleCloseNavbarMenu}>Cerrar menú</h6>
                            {/* <Code fw={700}>v3.1.2</Code> */}
                        </div>
                        {data && data.map((item) => (
                            <a
                                className="navbarMenu-link"
                                data-active={item.link === active || undefined}
                                to={item.link}
                                key={item.label}
                                onClick={(event) => {
                                    event.preventDefault();
                                    setActive(item.label);
                                    navegation(item.link)
                                    handleCloseNavbarMenu()
                                }}
                            >
                                {/* <item.icon className="navbarMenu-linkIcon" troke={1.5} /> */}
                                <img src={item.icon} />
                                <span>{item.label}</span>
                            </a>
                        ))}
                    </div>

                    <div className="navbarMenu-footer" >
                        <a href="#" className="navbarMenu-link" onClick={(event) => handleLogOut(event)}>
                            {/* <IconLogout className={classes.linkIcon} stroke={1.5} /> */}
                            <img src={closeSessionIcon} />
                            <span>Cerrar sesión</span>
                        </a>
                    </div>
                </nav>
            </Modal>
        </Box >
    );
}