import { Modal, Box } from "@mui/material";
import { makeStyles } from "mui-styles";
import iconoCerrarModal from "../../../assets/svg/functionalities-icons/cross_gray.svg";

const useStyles = makeStyles({
	style: {
		disableAutoFocus: "true",
		outline: "none",
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "98%",
		background: "white",
		borderRadius: "20px",
		overflow: "scroll",
		maxHeight: "100%",
		display: "block",
		boxShadow: 24,
		padding: "15px",
		"&::-webkit-scrollbar": {
			width: "0.4em",
			height: "50%",
		},
		"&::-webkit-scrollbar-track": {
			boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
			webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "rgba(0,0,0,.1)",
			outline: "1px solid slategrey",
		},
	},
	["@media (min-width:744px) "]: {
		style: {
			width: "80%",
			padding: "35px",
		},
	},
});

export default function ModalGrafico({ open, cerrarModal, children }) {
	const classes = useStyles();

	const handleClose = () => {
		cerrarModal();
	};

	return (
		<Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
			<Box className={classes.style}>
				<img src={iconoCerrarModal} className="modales-cerrar_modal_home-img" alt="close" onClick={handleClose} />
				<div className="modal-grafico-div ">{children}</div>
			</Box>
		</Modal>
	);
}
