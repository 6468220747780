import {
	COMENZAR_DESCARGA_DOLLAR,
	COMENZAR_DESCARGA_DOLLAR_EXITO,
	COMENZAR_DESCARGA_DOLLAR_ERROR,
} from "../../types/index";

const initialState = {
	dollar: null,
	loading: null,
	error: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case COMENZAR_DESCARGA_DOLLAR:
			return {
				...state,
				loading: true,
			};
		case COMENZAR_DESCARGA_DOLLAR_ERROR:
			return {
				...state,
				loading: false,
				error: true,
			};
		case COMENZAR_DESCARGA_DOLLAR_EXITO:
			return {
				...state,
				error: null,
				loading: false,
				dollar: action.payload,
			};
		default:
			return state;
	}
}
