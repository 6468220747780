import {
	COMENZAR_DESCARGA_CUENTAS_BASE_DE_DATOS,
	COMENZAR_DESCARGA_CUENTAS_BASE_DE_DATOS_EXITO,
	COMENZAR_DESCARGA_CUENTAS_BASE_DE_DATOS_ERROR,
	ELIMINAR_CUENTA_BASE_DE_DATOS,
	ELIMINAR_CUENTA_BASE_DE_DATOS_EXITO,
	ELIMINAR_CUENTA_BASE_DE_DATOS_ERROR,
	MODIFICAR_CUENTA_BASE_DE_DATOS,
	MODIFICAR_CUENTA_BASE_DE_DATOS_EXITO,
	MODIFICAR_CUENTA_BASE_DE_DATOS_ERROR,
	VERIFICAR_CONTRASEÑIA_SECCION_CUENTAS,
	VERIFICAR_CONTRASEÑIA_SECCION_CUENTAS_EXITO,
	VERIFICAR_CONTRASEÑIA_SECCION_CUENTAS_ERROR,
	LOG_OUT_PASS_ADMINISTRADOR,
	LOG_OUT_PASS_ADMINISTRADOR_EXITO,
	LOG_OUT_PASS_ADMINISTRADOR_ERROR,
	RESETEAR_ESTADO_REDUX_CUENTAS,
} from "../../types/index";
import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";
const cookie = new universlaCookie();

export function obtenerCuentas() {
	return async (dispatch) => {
		dispatch(descargarCuentas());
		try {
			const respuesta = await clienteAxios({
				method: "GET",
				url: "users",
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(descargarCuentasExito(respuesta.data));
		} catch (e) {
			dispatch(descargarCuentasError());
		}
	};
}

const descargarCuentas = () => ({
	type: COMENZAR_DESCARGA_CUENTAS_BASE_DE_DATOS,
	payload: true,
});

const descargarCuentasExito = (cuentas) => ({
	type: COMENZAR_DESCARGA_CUENTAS_BASE_DE_DATOS_EXITO,
	payload: cuentas,
});

const descargarCuentasError = () => ({
	type: COMENZAR_DESCARGA_CUENTAS_BASE_DE_DATOS_ERROR,
});

export function verificarContrasenias(pass) {
	return async (dispatch) => {
		dispatch(verificarContrasenia());
		try {
			const respuesta = await clienteAxios({
				method: "POST",
				url: "validateUserPassword",
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: {
					password: pass,
				},
			});
			dispatch(verificarContraseniaExito(respuesta.data));
		} catch (e) {
			dispatch(verificarContraseniaError());
		}
	};
}

const verificarContrasenia = () => ({
	type: VERIFICAR_CONTRASEÑIA_SECCION_CUENTAS,
});

const verificarContraseniaExito = (respuesta) => ({
	type: VERIFICAR_CONTRASEÑIA_SECCION_CUENTAS_EXITO,
	payload: respuesta,
});

const verificarContraseniaError = (respuesta) => ({
	type: VERIFICAR_CONTRASEÑIA_SECCION_CUENTAS_ERROR,
	payload: respuesta,
});

export function eliminarCuenta(id) {
	return async (dispatch) => {
		dispatch(eliminarCuentas());
		try {
			const respuesta = await clienteAxios({
				method: "DELETE",
				url: "user",
				headers: {
					"x-acces-token": cookie.get("token"),
					id: id,
				},
			});
			dispatch(eliminarCuentaExito(respuesta.data));
		} catch (e) {
			dispatch(eliminarCuentaError());
		}
	};
}

const eliminarCuentas = () => ({
	type: ELIMINAR_CUENTA_BASE_DE_DATOS,
	payload: true,
});

const eliminarCuentaExito = (cuentas) => ({
	type: ELIMINAR_CUENTA_BASE_DE_DATOS_EXITO,
	payload: cuentas,
});

const eliminarCuentaError = () => ({
	type: ELIMINAR_CUENTA_BASE_DE_DATOS_ERROR,
});

export function modificarCuentas(userId, username, newUsername) {
	return async (dispatch) => {
		dispatch(modificarCuenta());
		try {
			const respuesta = await clienteAxios({
				method: "PUT",
				url: "user",
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: {
					userId: userId,
					username: username,
					newUsername: newUsername,
				},
			});
			dispatch(modificarCuentaExito(respuesta.data));
		} catch (e) {
			dispatch(modificarCuentaError(e));
		}
	};
}

const modificarCuenta = () => ({
	type: MODIFICAR_CUENTA_BASE_DE_DATOS,
	payload: true,
});

const modificarCuentaExito = (cuentas) => ({
	type: MODIFICAR_CUENTA_BASE_DE_DATOS_EXITO,
	payload: cuentas,
});

const modificarCuentaError = (error) => ({
	type: MODIFICAR_CUENTA_BASE_DE_DATOS_ERROR,
	payload: error,
});

export function logOutPassAdministrador() {
	return (dispatch) => {
		dispatch(logOutPass());
		try {
			dispatch(logOutPassExito({ pass: false }));
		} catch (error) {
			dispatch(logOutPassError(error));
		}
	};
}
const logOutPass = () => ({
	type: LOG_OUT_PASS_ADMINISTRADOR,
});

const logOutPassExito = (respuesta) => ({
	type: LOG_OUT_PASS_ADMINISTRADOR_EXITO,
	payload: respuesta,
});
const logOutPassError = (respuesta) => ({
	type: LOG_OUT_PASS_ADMINISTRADOR_ERROR,
	payload: respuesta,
});

export function resetearEstados() {
	return (dispatch) => {
		dispatch(resetearEstadoRedux());
	};
}
const resetearEstadoRedux = () => ({
	type: RESETEAR_ESTADO_REDUX_CUENTAS,
});
