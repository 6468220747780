import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMarketPlayers, updateMarketPlayer } from "../../../redux/actions/cuentas/marketPlayerActions";

import { Modal, Box } from "@mui/material";
import { makeStyles } from "mui-styles";
import MuiAlert from "@mui/material/Alert";

import cryptoJs from "crypto-js";

import config from "../../../config/config";
import StatusModal from "../../utilities/Modal/StatusModal";

import iconoCerrarModal from "../../../assets/svg/functionalities-icons/cross_gray.svg";

import "./modalModificarCuenta.css";

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
	style: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "320px",
		minHeight: "297px",
		background: "#ffffff",
		borderRadius: "10px",
		boxShadow: 24,
		padding: "20px",
		disableAutoFocus: "true",
		outline: "none",
	},
	"@media (min-width:1298px) ": {
		style: {
			width: "380px",
		},
	},
});

const ModalEditMarketPlayer = ({ setShowEditModal, marketPlayer, handleEnableMarketPlayer }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const marketPlayerMessage = useSelector((state) => state.marketPlayer.message);
	const loading = useSelector((state) => state.marketPlayer.loading);
	const error = useSelector((state) => state.marketPlayer.error);

	const [mostrarModalConfirmacion, setMostrarModalConfirmacion] = useState(false);
	const [msjConfirmacion, setMsjConfirmacion] = useState("");

	const [nuevoPass, setNuevoPass] = useState("");
	const [reingresoNuevoPass, setReingresoNuevoPass] = useState();
	const [username, setUsername] = useState(marketPlayer.credentials.username);

	const [msjCambioClave, setMsjCambioClave] = useState(false);
	const [modalStatusCloseAction, setModalStatusCloseAction] = useState(false);

	useEffect(() => {
		if (modalStatusCloseAction) {
			setMostrarModalConfirmacion(false);
			if (!error) {
				dispatch(getMarketPlayers());
				setShowEditModal(false);
			}
			setModalStatusCloseAction(!modalStatusCloseAction);
		}
	}, [modalStatusCloseAction]);

	useEffect(() => {
		if (loading) {
			setMsjConfirmacion("Verificando información...");
		}
	}, [loading]);

	useEffect(() => {
		setMsjCambioClave(false);
	}, [username, nuevoPass, reingresoNuevoPass]);

	const handleSubmitSignUp = (e, marketPlayerID) => {
		e.preventDefault();
		if (verificarValidezContrasenias(nuevoPass, reingresoNuevoPass)) {
			dispatch(updateMarketPlayer(marketPlayerID, username, encriptar(nuevoPass)));
		}
	};

	const verificarValidezContrasenias = (pass1, pass2) => {
		if (pass1 !== pass2) {
			setMsjCambioClave(true);
			return false;
		} else {
			return true;
		}
	};

	function encriptar(string) {
		const secretKey = config.secretKey;
		return cryptoJs.AES.encrypt(string, secretKey).toString(); //TODO -> Creo que se tinene que ir lo cubre ssl
	}

	return (
		<Modal
			open={true}
			onClose={(e) => {
				e.preventDefault();
				setShowEditModal(false);
			}}
			style={{ backdropFilter: "blur(4px)" }}
			className={mostrarModalConfirmacion || loading ? "modales-modal_editar_proveedor-ocultar" : ""}
		>
			<Box className={classes.style}>
				<div>
					{msjCambioClave && <Alert severity="error">Las contraseñas no coinciden</Alert>}
					<form className="modales-contenedor_formulario" autoComplete="off" onSubmit={(e) => handleSubmitSignUp(e, marketPlayer._id)}>
						<Box
							sx={{
								"& .MuiTextField-root": {
									m: 1,
									width: "25ch",
								},
							}}
							noValidate
							autoComplete="off"
						>
							<h4 className="modales-titulo_modal">Editar {marketPlayer.company.name}</h4>
							<img
								src={iconoCerrarModal}
								className="modales-cerrar_modal-img"
								alt="close"
								onClick={() => {
									setShowEditModal(false);
								}}
							/>
							<div className="modales-proveedores_cont_datos">
								<div className="modales-row">
									<h4 className="modales-proveedores_campo_usuario">Usuario:</h4>
									<input
										className="modales-proveedores_editar-input"
										required
										value={username}
										autoComplete="off"
										type="text"
										onChange={(e) => {
											setUsername(e.target.value);
										}}
									/>
								</div>
								<div className="modales-row">
									<h4 className="modales-texto_modal-campo_pass">Nuevo Password :</h4>
									<input
										className="modales-modal_editar_proveedores-input_password"
										required
										type="text"
										value={nuevoPass}
										autoComplete="off"
										onChange={(e) => {
											if (e.nativeEvent.data !== " ") {
												setNuevoPass(e.target.value);
											}
										}}
										onCopy={(e) => {
											e.preventDefault();
										}}
										onCut={(e) => {
											e.preventDefault();
										}}
									/>
								</div>
								<div className="modales-row">
									<h4 className="modales-texto_modal-campo_pass">Repita Password :</h4>
									<input
										className="modales-modal_editar_proveedores-input_password"
										required
										value={reingresoNuevoPass}
										type="text"
										autoComplete="off"
										onChange={(e) => {
											if (e.nativeEvent.data !== " ") {
												setReingresoNuevoPass(e.target.value);
											}
										}}
										onCopy={(e) => {
											e.preventDefault();
										}}
										onCut={(e) => {
											e.preventDefault();
										}}
									/>
								</div>
							</div>
						</Box>
						<button className="modal-marketPlayer-edit-isEnabled" onClick={handleEnableMarketPlayer}>
							{marketPlayer.isEnabled ? "Deshabilitar" : "Habilitar"}
						</button>
						<button className="modales-modal_editar-boton modales-modal_editar-btn_editar" type="submit">
							Editar cuenta
						</button>
					</form>
				</div>
				{(marketPlayerMessage.message || loading) && (
					<StatusModal loading={loading} error={error} setModalStatusCloseAction={setModalStatusCloseAction}>
						{msjConfirmacion}
					</StatusModal>
				)}
			</Box>
		</Modal>
	);
};

export default ModalEditMarketPlayer;
