export const COMENZAR_DESCARGA_CUENTAS_BASE_DE_DATOS = "COMENZAR_DESCARGA_CUENTAS_BASE_DE_DATOS";
export const COMENZAR_DESCARGA_CUENTAS_BASE_DE_DATOS_EXITO = "COMENZAR_DESCARGA_CUENTAS_BASE_DE_DATOS_EXITO";
export const COMENZAR_DESCARGA_CUENTAS_BASE_DE_DATOS_ERROR = "COMENZAR_DESCARGA_CUENTAS_BASE_DE_DATOS_ERROR";

export const ELIMINAR_CUENTA_BASE_DE_DATOS = "ELIMINAR_CUENTA_BASE_DE_DATOS";
export const ELIMINAR_CUENTA_BASE_DE_DATOS_EXITO = "ELIMINAR_CUENTA_BASE_DE_DATOS_EXITO";
export const ELIMINAR_CUENTA_BASE_DE_DATOS_ERROR = "ELIMINAR_CUENTA_BASE_DE_DATOS_ERROR";

export const MODIFICAR_CUENTA_BASE_DE_DATOS = "MODIFICAR_CUENTA_BASE_DE_DATOS";
export const MODIFICAR_CUENTA_BASE_DE_DATOS_EXITO = "MODIFICAR_CUENTA_BASE_DE_DATOS_EXITO";
export const MODIFICAR_CUENTA_BASE_DE_DATOS_ERROR = "MODIFICAR_CUENTA_BASE_DE_DATOS_ERROR";

export const MODIFICAR_CREAR_CLAVE_CUENTA = "MODIFICAR_CREAR_CLAVE_CUENTA";
export const MODIFICAR_CREAR_CLAVE_CUENTA_EXITO = "MODIFICAR_CREAR_CLAVE_CUENTA_EXITO";
export const MODIFICAR_CREAR_CLAVE_CUENTA_ERROR = "MODIFICAR_CREAR_CLAVE_CUENTA_ERROR";

export const RECUPERAR_CONTRASEÑIA_USER = "RECUPERAR_CONTRASEÑIA_USER";
export const RECUPERAR_CONTRASEÑIA_USER_EXITO = "RECUPERAR_CONTRASEÑIA_USER_EXITO";
export const RECUPERAR_CONTRASEÑIA_USER_ERROR = "RECUPERAR_CONTRASEÑIA_USER_ERROR";

export const GET_DATOS_USER = "GET_DATOS_USER";
export const GET_DATOS_USER_EXITO = "GET_DATOS_USER_EXITO";
export const GET_DATOS_USER_ERROR = "GET_DATOS_USER_ERROR";

export const VERIFICAR_CONTRASEÑIA_SECCION_CUENTAS = "ERIFICAR_CONTRASEÑIA_SECCION_CUENTAS";
export const VERIFICAR_CONTRASEÑIA_SECCION_CUENTAS_EXITO = "VERIFICAR_CONTRASEÑIA_SECCION_CUENTAS_EXITO";
export const VERIFICAR_CONTRASEÑIA_SECCION_CUENTAS_ERROR = "VERIFICAR_CONTRASEÑIA_SECCION_CUENTAS_ERROR";

export const LOG_OUT_PASS_ADMINISTRADOR = "LOG_OUT_PASS_ADMINISTRADOR";
export const LOG_OUT_PASS_ADMINISTRADOR_EXITO = "LOG_OUT_PASS_ADMINISTRADOR_EXITO";
export const LOG_OUT_PASS_ADMINISTRADOR_ERROR = "LOG_OUT_PASS_ADMINISTRADOR_ERROR";

export const RESETEAR_ESTADO_REDUX_CUENTAS = "RESETEAR_ESTADO_REDUX_CUENTAS";
