import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';

import App from "./routes/App";
import store from "./redux/store";

import "./index.css";

const root = createRoot(document.getElementById("root"));

root.render(
	<Provider store={store}>
		<MantineProvider >
			<App />
		</MantineProvider>
	</Provider>,
);